<template>
  <div class="pa-12">
    <v-row v-if="!isLoading" class="mt-6">
      <v-col cols="12" md="8"
        ><h1>VP's final rating approval for department</h1>

        <div v-if="templateName">
          <h3 class="mt-4">Viewing {{ templateName }}</h3>
        </div>

        <!-- <ul>
          <li>
            H1 2022 Performance Review VP Guide
            <a href="https://doc.clickup.com/d/h/dftja-2347/eb0a04593fa987f"
              >https://docs.google.com/document/d/1pzztLfXui1KCyurWPP0rzypGlYjW1VENLM1rL7QkTSs</a
            >
          </li>
          <li>
            Quick guide for Non-ops evaluation
            <a href="https://doc.clickup.com/d/h/dftja-2367/29e90510b481368"
              >https://doc.clickup.com/d/h/dftja-2367/29e90510b481368</a
            >
          </li>
        </ul> -->
      </v-col>
      <v-col v-if="isVPMode" cols="12" md="4" class="">
        <v-btn
          color="af_blue"
          class="white--text float-right"
          @click="approveAll"
          :loading="isApproving"
          >Approve All Ratings</v-btn
        >
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="af_blue"
    ></v-progress-linear>

    <v-row v-if="!isLoading && answers.length > 0">
      <v-col cols="12" md="12">
        <PerformanceManagementDistribution
          :ratings="answersByPersonId"
          :visibleList="orderedSentList"
          :boardId="boardId"
          :templateId="templateId"
          :showHelpText="true"
          display="VPRating"
          :vpMapping="vpMapping"
          class="my-6"
        /> </v-col
    ></v-row>

    <v-row v-if="!isLoading" no-gutters class="my-6">
      <v-col cols="12" md="4" class="d-flex align-center">
        <v-text-field
          v-model="filterInput"
          dense
          label="Filter by employee name"
          class="my-6 ml-2"
          hide-details
          append-icon="mdi-filter"
          @click:append="filter()"
          v-on:keyup.enter="filter()"
        />
      </v-col>
    </v-row>

    <v-row v-if="!isLoading" class="mb-4">
      <v-col cols="12">
        <v-card class="card-shadow px-4 pb-4">
          <v-simple-table class="mt-0 pt-0" dense>
            <template v-slot:default>
              <thead style="height: 80px">
                <th>Name</th>
                <th>Department</th>
                <th style="height: 140px; min-width: 200px">Executive</th>
                <th v-if="!isManagerAccess">Manager</th>
                <th style="height: 140px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        >Evaluating Manager
                        <v-icon color="grey lighten-1" small>
                          mdi-help
                        </v-icon></span
                      >
                    </template>
                    <span>
                      Based on HR policy, the evaluating manager can be
                      different from the current manager
                    </span>
                  </v-tooltip>
                </th>
                <th>Self-Review</th>
                <!-- <th>Manager's Rating</th> -->
                <!-- <th>VP Rating</th> -->
                <th>Final Rating</th>
                <th v-if="isVPMode"></th>
                <th>Approved by</th>
              </thead>
              <tbody v-if="changedManagerFetched">
                <tr v-for="personId in filteredOrderedList" :key="personId">
                  <td class="text-truncate">
                    <p class="body-1 ma-0 pa-0">
                      {{ employeesByPersonId[personId].name }},
                      {{ employeesByPersonId[personId].role }}
                    </p>
                  </td>

                  <td class="text-center" style="width: 200px">
                    <v-chip
                      class="white--text ma-0"
                      dense
                      small
                      :color="
                        getDepartmentColor(
                          employeesByPersonId[personId].employeeData.department
                        )
                      "
                    >
                      {{
                        employeesByPersonId[personId].employeeData.department
                      }}
                    </v-chip>
                  </td>
                  <td>
                    <v-combobox
                      v-if="!isManagerAccess"
                      dense
                      solo
                      elevation="0"
                      class="ma-0"
                      :items="vps"
                      hide-details
                      v-model="vpMapping[personId]"
                      @change="onVPchange($event, personId)"
                      style="min-width: 200px"
                      disabled
                    >
                      <template v-slot:selection="data">
                        <p class="body-1 ma-0 pa-0">
                          {{ getName(data.item) }}
                        </p>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="`${getName(item)}, ${getRole(item)}`"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="
                              `${getDepartment(item)} ${getLocation(item)}`
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                    <p class="body-1 ma-0" v-else>
                      <span v-if="employeesByPersonId[vpMapping[personId]]">{{
                        employeesByPersonId[vpMapping[personId]].name
                      }}</span>
                    </p>
                  </td>

                  <td>
                    <span
                      v-if="
                        employeesByPersonId[
                          employeesByPersonId[personId].managers[0]
                        ]
                      "
                    >
                      <p v-if="isManagerAccess" class="body-1 ma-0">
                        <span v-if="changedManagers[personId]">
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}
                          <v-icon small>mdi-chevron-right</v-icon>
                          {{ changedManagers[personId].name }}
                        </span>
                        <span v-else>
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}</span
                        >
                      </p>
                    </span>
                  </td>

                  <td class="text-center">
                    <p
                      v-if="isVPMode && answersByPersonId[personId]"
                      class="body-1 ma-0"
                    >
                      Completed
                    </p>
                    <v-btn
                      v-if="answersByPersonId[personId] && !isVPMode"
                      class="ma-0 blue--text text-capitalize"
                      small
                      text
                      dense
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}/?vpm=1`"
                      target="_blank"
                    >
                      See Full Response
                    </v-btn>
                    <p v-if="!answersByPersonId[personId]" class="body-1 ma-0">
                      Pending
                    </p>
                  </td>
                  <!-- <td class="text-center">
                    <p v-if="answersByPersonId[personId]" class="body-1 ma-0">
                      {{ answersByPersonId[personId][0].finalRating }}
                    </p>
                  </td> -->
                  <!-- <td class="text-center">
                    <p v-if="answersByPersonId[personId]" class="body-1 ma-0">
                      {{ answersByPersonId[personId][0].VPRating }}
                    </p>
                  </td> -->
                  <td class="text-center">
                    <p v-if="answersByPersonId[personId]" class="body-1 ma-0">
                      {{ getFinalRating(answersByPersonId[personId]) }}
                    </p>
                  </td>
                  <td class="text-center">
                    <!-- v-if="isVPMode && answersByPersonId[personId]" -->
                    <v-btn
                      v-if="editable"
                      small
                      color="af_green"
                      dense
                      class="ma-0 white--text text-capitalize"
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}?vpm=1`"
                      >Edit</v-btn
                    >
                    <v-btn
                      v-if="!editable"
                      small
                      color="af_green"
                      dense
                      class="ma-0 white--text text-capitalize"
                      :to="`/PerformanceReviewReadOnly/${boardId}/${templateId}/${personId}?vpm=1`"
                      >See full response</v-btn
                    >
                  </td>
                  <td class="text-center">
                    <p
                      v-if="
                        answersByPersonId[personId] &&
                        answersByPersonId[personId][0].VPApproved
                      "
                    >
                      {{ answersByPersonId[personId][0].approvedBy }}
                    </p>
                    <!-- <v-icon
                      v-if="
                        answersByPersonId[personId] &&
                        answersByPersonId[personId][0].VPApproved
                      "
                      large
                      color="af_green"
                      >mdi-check-decagram-outline</v-icon
                    > -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { OutlineColors } from "@/lib/Colors";
import PerformanceManagementDistribution from "@/components/Calibration/PerformanceManagementDistribution";
// import { AccessCheck } from "@/lib/Access";
// import firebase from "firebase/app";
// import "firebase/functions";

export default {
  name: "PerformanceReviewManagerReviewTable",
  components: {
    PerformanceManagementDistribution,
  },
  data: () => ({
    // Use this array to sort the order of display
    roleSortOrder: [
      "noManager",
      "ceo",
      "cto",
      "coo",
      "cpo",
      "chro",
      "cfo",
      "chief",
      "vp",
      "director",
      "senior",
      "sr",
    ],
    vps: [],
    templateName: "",
    filterInput: "",
    filterQuery: "",
    searchQuery: "",
    departments: [], // array of uniq department values
    selectedEmployees: [], // array of personIds that will receive performance review
    showTeamList: [], // Array of manager's personId that indicates if the team table should be expanded or collapsed
    sent: [], // Array of personIds that indicates who received the performance review

    receivers: null, // The full recevier doc from Firestore
    answers: [],
    answersByPersonId: {},

    vpMapping: {}, // Mapping of employees to VPs
    isVPMode: false,

    // For employees that should have a different evaluation manager compared to default.
    changedManagers: {},
    changedManagerFetched: false,
    csvExporting: false,
    isLoading: true,
    isApproving: false,
    editable: false, // Set this to true to allow people to edit.
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    board() {
      return this._.find(this.$store.state.board.boards, {
        boardId: this.boardId,
      });
    },
    templateId() {
      return this.$route.params.templateId;
    },
    isManagerAccess() {
      // This is always going to be true for this module
      return true;
    },
    orderedSentList() {
      return (
        this._.chain(this.sent)
          .sortBy((personId) => {
            return this.employeesByPersonId[personId]?.employeeData?.department;
          })
          .sortBy((personId) => {
            return this.employeesByPersonId[personId]?.managers[0];
          })
          // .filter((personId) => {
          //   // This is hard coded for Weee
          //   // We should make this dynamic
          //   if (
          //     ![
          //       "prt_WgPLRQ8iUJlWpADANenDKnc"
          //     ].includes(this.templateId)
          //   ) {
          //     return true;
          //   }

          //   const startDate =
          //     this.employeesByPersonId[personId].employeeData.startDate;
          //   if (startDate) {
          //     const mStartDate = this.$moment(startDate, "MM/DD/YYYY");
          //     const comparedDate = this.$moment("10/01/2021", "MM/DD/YYYY");
          //     if (!mStartDate.isBefore(comparedDate)) {
          //       return false;
          //     }
          //   }

          //   return true;
          // })
          .filter((personId) => {
            // console.log(personId);
            // return true;
            if (!this.employeesByPersonId[personId]) return true;
            if (!this.searchQuery && !this.isManagerAccess) return true;

            // I should not be able to see my own assessment.
            if (this.myPersonId(this.boardId) === personId) {
              return false;
            }

            const targets = [
              this.employeesByPersonId[personId].employeeData.department,
            ];

            const managersId = this.employeesByPersonId[personId].managers[0];

            if (managersId) {
              targets.push(this.employeesByPersonId[managersId].name);
            }

            // IF this is VP mode, we show the list of everyone under this VP.
            if (this.isManagerAccess && this.isVPMode) {
              // console.log("isManager and VP mode");
              // console.log(this.searchQuery);

              if (!this.searchQuery || this.searchQuery.length === 0)
                return false;

              // Check if the current personId is a report of the VP.
              // If yes, the VP should be able to see this person's assessment
              // I am a manager. I need to check if I am access my own team
              let myTeam = [];
              const listAllSubordinates = (target) => {
                if (!target) return;
                myTeam.push(target.personId);
                this._.map(target.subordinates, (_pid) => {
                  listAllSubordinates(this.employeesByPersonId[_pid]);
                });
              };

              listAllSubordinates(this.employeesByPersonId[this.searchQuery]);
              // Remove myself. Since I should not be allow to see my own performance
              const k = myTeam.indexOf(this.searchQuery);
              if (k > -1) myTeam.splice(k, 1);

              if (myTeam.includes(personId)) {
                return true;
              }

              // searchQuery is my personId in this case
              // Check if the current person is in the vpMapping.
              // If so, we should show everyone that's under this VP.
              const vpPersonId = this.vpMapping[personId];
              if (vpPersonId === this.searchQuery) {
                return true;
              }

              return this.searchQuery === managersId;
            }

            if (this.changedManagers && this.changedManagers[personId]) {
              // Check if the manager should be overriden.
              const evaluatingManagerPersonId =
                this.changedManagers[personId].personId;
              return (
                evaluatingManagerPersonId === this.myPersonId(this.boardId)
              );
            }

            return this._.some(targets, (t) => {
              if (!t) return true;
              return t.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
          })
          .compact()
          .value()
      );
    },
    filteredOrderedList() {
      // Return the ordered list items that are filtered
      return this._.filter(this.orderedSentList, (pid) => {
        return this.employeesByPersonId[pid]?.name
          .toLowerCase()
          .includes(this.filterQuery.toLowerCase());
      });
    },
    employeeResponseRate() {
      if (!this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      // console.log(this.answers, this.sent);
      return parseInt((this.answers.length / this.sent.length) * 100);
    },
    managerRatingRate() {
      if (!this.sent || !this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].finalRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    VPRatingRate() {
      if (!this.sent || !this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].VPRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    managers() {
      // List of people that have a team
      return this._.chain(this.activeEmployees)
        .filter((person) => {
          return (
            person.role &&
            person.name &&
            person.email &&
            person.subordinates &&
            person.subordinates.length > 0
          );
        })
        .map((person) => {
          return { personId: person.personId, name: person.name };
        })
        .value();
    },
  },
  watch: {
    searchQuery: function () {},
    groupPeopleByManager: function () {
      this.departments = this._.chain(this.people)
        .map((p) => {
          return p.employeeData.department.split("-")[0].trim();
        })
        .uniq()
        .sortBy((t) => {
          // This ensures that the color code stays (kinda...)
          return t;
        })
        .value();
    },
  },

  mounted() {
    this.isVPMode = this.$route.query.vpm ? true : false;
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchPeople",
      "fetchPerformanceReviewReceivers",
      "fetchAllPerformanceReviewAnswers",
      "fetchPerformanceReviewTemplate",
      "updatePerformanceReviewVPRating",
      "readChangedManagers",
      "getVPMapping",
    ]),
    async importVPMapping() {
      const vpmapping = await this.getVPMapping({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this._.each(vpmapping.mapping, (map) => {
        this.vpMapping[map.personId] = map.vpPersonId;
      });

      this.vps = this._.map(vpmapping.mapping, (map) => {
        return map.vpPersonId;
      });
    },
    async init() {
      console.log("Is Manager View", this.isManagerAccess);
      this.isLoading = true;

      await this.fetchPeople({ boardId: this.boardId });

      // If this is a manager view, then only show the team under management
      if (this.isManagerAccess && this.myPersonId(this.boardId)) {
        this.searchQuery = this.myPersonId(this.boardId);
      }

      this.receviers = await this.fetchPerformanceReviewReceivers({
        boardId: this.boardId,
        templateId: this.templateId,
      });
      this.sent = this.receviers.sent;

      await this.fetchAnswers();

      // Fetch the name of the template
      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.templateName = ret.name;

      const changedManagersObj = await this.readChangedManagers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      if (changedManagersObj) {
        // Convert the Firebase data into changedManager Object
        this._.each(changedManagersObj.changedManagerArray, (obj) => {
          const personId = obj.targetPersonId;

          this.changedManagers[personId] = {
            personId: obj.managerPersonId,
            name: obj.managerName,
          };
          // console.log(this.$refs[`${personId}_evaluating_manager`]);
          // this.$refs[`${personId}_evaluating_manager`][0].value =
          //   this.changedManagers[personId];
        });
      }

      await this.importVPMapping();

      this.changedManagerFetched = true;
      this.isLoading = false;
    },
    getDepartmentColor(department) {
      return OutlineColors[
        this.departments.indexOf(department.split("-")[0].trim())
      ];
    },
    filter() {
      this.filterQuery = this.filterInput;
    },

    queryManager(person, queryText) {
      const personId = person.personId;

      if (
        !this.employeesByPersonId[personId] ||
        !this.employeesByPersonId[personId].name
      )
        return false;
      return (
        this.employeesByPersonId[personId].name
          .toLowerCase()
          .includes(queryText) ||
        this.employeesByPersonId[personId].role
          .toLowerCase()
          .includes(queryText)
      );
    },
    getName(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.name : "";
    },

    getRole(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.role : "";
    },
    getDepartment(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.employeeData.department : "No Department";
    },
    getLocation(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.officeLocation : "No Location";
    },

    async fetchAnswers() {
      this.answers = await this.fetchAllPerformanceReviewAnswers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.answersByPersonId = this._.groupBy(this.answers, (ans) => {
        return ans.revieweePersonId;
      });
      // console.log(this.answersByPersonId);
    },

    async approveAll() {
      this.isApproving = true;
      // Find the people that will be approved by VPs
      const peopleInDepartment = this._.filter(this.answers, (ans) => {
        return this.orderedSentList.includes(ans.revieweePersonId);
      });

      this._.each(peopleInDepartment, async (ans) => {
        await this.updatePerformanceReviewVPRating({
          boardId: this.boardId,
          templateId: ans.templateId,
          revieweePersonId: ans.revieweePersonId,
          VPApproved: true,
          approvedBy: this.user.displayName,
          user: this.user,
        });
      });
      await this.init();

      this.isApproving = false;
    },
    getFinalRating(answer) {
      const finalRating = answer[0]?.CEORating
        ? answer[0].CEORating
        : answer[0].VPRating;
      if (finalRating) return finalRating;
      // No other ratings exists. Return Manager's rating. Oddly, that's named final rating.
      return answer[0]?.finalRating;
    },
  },
};
</script>
<style scoped>
tr {
  height: 50px;
}
</style>
