<template>
  <v-app>
    <Navigation />

    <v-main>
      <v-container
        class="router-view ma-0 pa-0"
        style="padding-top: 48px !important; background-color: white"
        fill-height
        fluid
      >
        <router-view />
      </v-container>
    </v-main>

    <!-- <v-snackbar :timeout="-1" v-model="showWeUseCookie" color="info">
      This website uses cookies to ensure you get the best experience on our
      website.
      <a class="white--text" href="https://cookiesandyou.com/">Learn more</a>

      <template v-slot:action="{ attrs }">
        <v-btn
          wv-bind="attrs"
          outlined
          v-bind="attrs"
          @click="dismissCookieDialog"
          >Got it</v-btn
        >
      </template>
    </v-snackbar> -->
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import firebase from "firebase/app";
import "firebase/auth";
import * as Sentry from "@sentry/browser";

export default {
  name: "App",

  components: {
    Navigation,
  },

  data: () => ({
    devMode: false,
    showWeUseCookie: false,
  }),

  computed: {
    isMainWebsite() {
      return window.location.origin === "https://agentnoon.com";
    },
    boards() {
      return this.$store.state.board.boards;
    },
  },

  created() {
    console.log("app created");

    this.checkReferral();

    window.mixpanel.track("app_open");
    firebase.auth().onAuthStateChanged(async (userObj) => {
      if (userObj) {
        window.mixpanel.identify(userObj.uid);
        window.mixpanel.track("login");
        window.mixpanel.people.set({
          $email: userObj.email, // only reserved properties need the $
          creationTime: userObj.metadata.creationTime,
          lastSignInTime: userObj.metadata.lastSignInTime,
        });
        window.mixpanel.set_group("company", userObj.email.split("@")[1]);

        if (window.heap) {
          window.heap.identify(userObj.uid);
          window.heap.addUserProperties({
            email: userObj.email,
            AccountCreation: userObj.metadata.creationTime,
            lastSignInTime: userObj.metadata.lastSignInTime,
          });
        }

        if (window.MODE === "PRODUCTION") {
          var verify = firebase
            .functions()
            .httpsCallable("intercomIdentifyVerification-verify");
          verify().then((ret) => {
            if (ret.data) {
              window.Intercom("boot", {
                app_id: "v85uga53",
                name: userObj.displayName,
                email: userObj.email,
                created_at: userObj.metadata.creationTime,
                user_hash: ret.data,
                company: userObj.email.split("@")[1],
                avatar: {
                  type: "avatar",
                  image_url: userObj.photoURL,
                },
                referral_code: window.localStorage.getItem(
                  "agentnoon_referred_by"
                ),
              });
            }
          });
        }

        if (window.localStorage.getItem("agentnoon_referred_by")) {
          const referredBy = window.localStorage.getItem(
            "agentnoon_referred_by"
          );
          window.mixpanel.track("referral_app_open", {
            referral_code: referredBy,
          });
          window.Intercom("referral_app_open", referredBy);
          window.mixpanel.people.set({
            referral: referredBy,
          });
          window.localStorage.removeItem("agentnoon_referred_by");
        }

        // User is signed in.
        this.$store.dispatch("setUserAuth", userObj);
        this.$store.dispatch("fetchUserMetaData", userObj.uid);

        Sentry.configureScope(function (scope) {
          scope.setUser({ email: userObj.email });
          scope.setUser({ username: userObj.displayName });
          scope.setUser({ id: userObj.uid });
        });

        if (window.smartlook) {
          window.smartlook("identify", userObj.uid, {
            name: userObj.displayName,
            email: userObj.email,
          });
        }

        if (!userObj.emailVerified) {
          return this.$router.push({ path: "/verify" });
        }

        await this.$store.dispatch("fetchAllBoards", userObj.uid);
        if (this.$route.name === "Login" || this.$route.name === "Signup") {
          let redirect = "";
          if (this.$route.query.redirect) {
            redirect = this.$route.query.redirect;
          }
          return this.$router.push({ path: "/start", query: { redirect } });
        }
      } else {
        console.log("user not authenticated");
        console.log("current route", this.$route.name);

        // This is a special case for employee performance review
        if (this.$route.name === "PerformanceReviewForm") {
          return this.$router.push({
            path: "/",
            query: { redirect: `${this.$route.path}` },
          });
        }

        if (this.$route.name !== "Signup" && this.$route.name !== "Login")
          return this.$router.push({ path: "/" });
        // Go to main page
        // if (this.$router.currentRoute.fullPath !== "/") {
        // return this.$router.push({ path: "/login" });
        // }
      }
    });
  },

  mounted() {
    if (window.localStorage.getItem("agentnoon_show_cookie")) {
      // Hide we use cookies dialog
      this.showWeUseCookie =
        window.localStorage.getItem("agentnoon_show_cookie") === "false";
    } else {
      this.showWeUseCookie = true;
    }
  },

  methods: {
    dismissCookieDialog() {
      window.localStorage.setItem(
        "agentnoon_show_cookie",
        this.showWeUseCookie
      );

      this.showWeUseCookie = false;
    },
    checkReferral() {
      // We store this in Mixpanel.
      // This is not an ideal solution as we will need to manually check Mixpanel from time to time
      // to see who signed up with an referral code. But it will do for the time being.
      if (this.$route.query.referral) {
        const code = this.$route.query.referral;
        window.localStorage.setItem("agentnoon_referred_by", code);
      }
    },
  },
};
</script>

<style>
.rounded-corners {
  border-radius: 15px !important;
}

.thumbnail {
  width: 60px !important;
  height: 60px !important;
}

.router-view > div {
  width: 100%;
  height: 100%;
}

.menu.v-card.v-sheet {
  z-index: 99999 !important;
}

.card-shadow {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 8px rgba(0, 0, 0, 0.05),
    0px 30px 30px rgba(0, 0, 0, 0.02) !important;
}

hr.vertical {
  height: 140%; /* you might need some positioning for this to work, see other questions about 100% height */
  width: 0;
  margin-top: -25px;
  border: thin solid rgba(0, 0, 0, 0.1);
}
</style>
