<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" sm="12" offset-md="2">
        <v-card outlined class="pa-6 mt-6 text-center">
          As a security measure, please verify your email first.
          <v-card-actions class="d-flex justify-center mt-2">
            <v-btn
              color="af_blue"
              class="white--text"
              @click="user.sendEmailVerification()"
            >
              Send verification email
            </v-btn>
            <v-btn class="ml-4" @click="done">Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "VerifyEmail",
  data: () => ({}),
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    done() {
      window.location = "/";
    },
  },
};
</script>
