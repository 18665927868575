// import Vue from 'vue';
import firebase from "firebase/app";
import "firebase/firestore";
// import * as Sentry from "@sentry/browser";
import "firebase/functions";

// Documentation available at
// https://app.clickup.com/14149194/v/dc/dftja-927/dftja-827
const getDefaultState = () => {
  return {
    isLoaded: false,
    boardId: null,
    ceoPersonId: null,
    hrLeadPersonId: null,
    financeLeadPersonId: null,
    compensationManagerPersonId: null,
    hiringRequestReviewers: [],
    isBambooHRAutoSyncOn: false,
    isReadOnly: false,
    showFutureHiresOnOrgChart: false,
    overlayPerformance: false,
    disableReports: false,
    performanceAccessibleByHROnly: false,
    baseCurrency: 'USD',
    enableCalibration: false,
    nextJobCode: '0',
    isRestrictToTeamViewEnabled: false
  }
}

const state = getDefaultState()

const getters = {
  preferredBaseCurrency: (_state) => {
    return _state.baseCurrency
  },
  getHiringRequestReviewers: (_state) => {
    return _state.hiringRequestReviewers
  },
  loaded: (_state) => {
    return _state.isLoaded
  },
  nextJobCode: (_state) => {
    return _state.nextJobCode
  }
}

const mutations = {
  setSettings(_state, settings) {
    if (!settings) return
    _state.boardId = settings.boardId
    _state.ceoPersonId = settings.ceoPersonId
    _state.hrLeadPersonId = settings.hrLeadPersonId
    _state.financeLeadPersonId = settings.financeLeadPersonId
    _state.compensationManagerPersonId = settings.compensationManagerPersonId
    _state.hiringRequestReviewers = settings.hiringRequestReviewers
    _state.isBambooHRAutoSyncOn = settings.isBambooHRAutoSyncOn
      ? settings.isBambooHRAutoSyncOn
      : false
    _state.isReadOnly = settings.isReadOnly ? settings.isReadOnly : false
    _state.showFutureHiresOnOrgChart = settings.showFutureHiresOnOrgChart
      ? settings.showFutureHiresOnOrgChart
      : false
    _state.isLoaded = true
    if (settings.overlayPerformance)
      _state.overlayPerformance = settings.overlayPerformance
    if (settings.disableReports) _state.disableReports = settings.disableReports
    if (settings.performanceAccessibleByHROnly)
      _state.performanceAccessibleByHROnly =
        settings.performanceAccessibleByHROnly

    if (settings.baseCurrency) _state.baseCurrency = settings.baseCurrency
    if (settings.enableCalibration)
      _state.enableCalibration = settings.enableCalibration
    _state.nextJobCode = settings.nextJobCode || 0
    if (settings.isRestrictToTeamViewEnabled)
      _state.isRestrictToTeamViewEnabled = settings.isRestrictToTeamViewEnabled
  },
  toggleBambooHRAutoSync(_state, isAutoSyncOn) {
    _state.isBambooHRAutoSyncOn = isAutoSyncOn
  },
  resetSettings(_state) {
    Object.assign(_state, getDefaultState())
  }
}

const actions = {
  resetSettings(context) {
    context.commit('resetSettings')
  },
  async setCEO(context, { ceoPersonId, boardId }) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          ceoPersonId,
          boardId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setHRLead(context, { hrLeadPersonId, boardId }) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          hrLeadPersonId,
          boardId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setFinanceLead(context, { financeLeadPersonId, boardId }) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          financeLeadPersonId,
          boardId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setCompensationManager(
    context,
    { compensationManagerPersonId, boardId }
  ) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          compensationManagerPersonId,
          boardId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setHiringRequestReviewers(
    context,
    { hiringRequestReviewers, boardId }
  ) {
    console.log('setHiringRequestReviewers', hiringRequestReviewers)
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          hiringRequestReviewers,
          boardId
        },
        { merge: true }
      )
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setBambooHRAutoSync(context, { isAutoSyncOn, boardId }) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          isBambooHRAutoSyncOn: isAutoSyncOn,
          boardId
        },
        { merge: true }
      )
      context.commit('toggleBambooHRAutoSync', isAutoSyncOn)
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setReadOnly(context, { isReadOnly, boardId }) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          isReadOnly,
          boardId
        },
        { merge: true }
      )
      context.dispatch('fetchSettings', { boardId })
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setShowFutureHiresOnOrgChart(
    context,
    { showFutureHiresOnOrgChart, boardId }
  ) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          showFutureHiresOnOrgChart,
          boardId
        },
        { merge: true }
      )
      context.dispatch('fetchSettings', { boardId })
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async setIsRestrictToTeamViewEnabled(
    context,
    { isRestrictToTeamViewEnabled, boardId }
  ) {
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          isRestrictToTeamViewEnabled,
          boardId
        },
        { merge: true }
      )
      context.dispatch('fetchSettings', { boardId })
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },

  async setBaseCurrency(context, { baseCurrency, boardId }) {
    if (!boardId) throw new Error('BoardId is missing')
    try {
      await firebase.firestore().collection('settings').doc(boardId).set(
        {
          baseCurrency: baseCurrency,
          boardId
        },
        { merge: true }
      )
      context.dispatch('fetchSettings', { boardId })
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong saving'
    }
  },
  async fetchSettings(context, { boardId }) {
    console.log('fetch settings')
    try {
      const doc = await firebase
        .firestore()
        .collection('settings')
        .doc(boardId)
        .get()

      if (!doc.exists) {
        context.state.isLoaded = true
      } else {
        context.commit('setSettings', doc.data())
      }
    } catch (e) {
      console.error(e)
      throw 'Something weng wrong fetching data'
    }
  },
  async getNextJobCode(context, boardId) {
    try {
      await firebase
        .firestore()
        .collection('settings')
        .doc(boardId)
        .update({
          nextJobCode: firebase.firestore.FieldValue.increment(1)
        })

      await context.dispatch('fetchSettings', { boardId })
      return context.getters.nextJobCode
    } catch (e) {
      console.error(e)
      return 0
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
