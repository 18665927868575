import Vue from 'vue';
import firebase from 'firebase/app';
// import 'firebase/auth';
import 'firebase/firestore';
// import * as Sentry from '@sentry/browser';

const state = {
  history: [],
};

const mutations = {
  setHistory(state, historyArr) {
    state.history = historyArr;
  },
};

const actions = {
  // setUserAuth(context, user) {
  //   context.commit('setUser', user);
  // },

  // Fetch the changes in the last 48 hrs in a board
  async fetchOrgHistory(context, { boardId, hoursAgo }) {
    const current = Date.now();

    let compareDate = new Date(current - hoursAgo * 60 * 60 * 1000);
    compareDate = firebase.firestore.Timestamp.fromDate(compareDate);

    const querySnapshot = await firebase
      .firestore()
      .collection('people_history')
      .where('boardId', '==', boardId)
      .where('updatedAt', '>', compareDate)
      .get();

    let ret = [];
    querySnapshot.forEach(doc => {
      ret.push(doc.data());
    });

    ret = Vue._.sortBy(ret, o => {
      return -1 * o.updatedAt.seconds;
    });

    context.commit('setHistory', ret);
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
