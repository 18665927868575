<template>
  <v-container>
    <v-card v-if="template" class="card-shadow pa-8 mt-8">
      <v-alert prominent dense dark type="warning" class="mx-12">
        <p class="body-1 my-0 py-0">
          Please fill out all the <b>yellow</b> sections.
        </p>

        <p class="body-1 my-0 py-0 mt-2">
          Manager's can override employee's self assessments.
        </p>
      </v-alert>

      <v-row class="mb-6 mt-8">
        <v-col cols="12">
          <h1 v-if="person">{{ template.name }} by {{ person.name }}</h1>

          <h1 v-if="answers">
            Submitted at
            {{
              answers.createdAt.seconds | moment("ddd, MMM D YYYY, h:mm:ss a")
            }}
          </h1>
          <h1 v-else class="mt-4">Not submitted yet</h1>
        </v-col>
      </v-row>

      <v-form v-if="answers" ref="form">
        <v-simple-table v-if="answers" class="mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th><h2>Objectives</h2></th>
                <th v-if="!template.goalSettingMode">
                  <h2>Achievement/Result</h2>
                </th>
                <th v-if="template.goalSettingMode"><h2>Target</h2></th>
                <th v-if="template.goalSettingMode"><h2>Action</h2></th>
                <th v-if="template.enableOKRPercentage">
                  <h2 class="text-left">Weighted Importance (Total to 100%)</h2>
                </th>
                <th v-if="template.enableEvaluationPercentage">
                  <h2>Score</h2>
                </th>
                <th v-if="template.enableEvaluationPercentage">
                  <h2>Result</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                no-gutters
                v-for="res in answers.responsibilities"
                :key="res.okrId"
              >
                <td>
                  <v-textarea
                    dense
                    auto-grow
                    class="mt-4"
                    rows="1"
                    hint="Completed by the employee"
                    v-model="res.responsibility"
                    tabindex="-1"
                  />
                </td>
                <td v-if="!template.goalSettingMode">
                  <v-textarea
                    dense
                    auto-grow
                    class="mt-4"
                    rows="1"
                    hint="Completed by the employee"
                    v-model="res.result"
                    tabindex="-1"
                  />
                </td>
                <td v-if="template.goalSettingMode">
                  <v-textarea
                    v-model="res.target"
                    hint="Filled out by employee"
                    solo
                    dense
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </td>

                <td v-if="template.goalSettingMode">
                  <v-textarea
                    v-model="res.action"
                    hint="Filled out by employee"
                    solo
                    dense
                    auto-grow
                    rows="1"
                  ></v-textarea>
                </td>
                <td v-if="template.enableOKRPercentage" style="width: 100px">
                  <v-text-field
                    dense
                    class="mt-4"
                    hint="Completed by the employee"
                    v-model="res.percentage"
                    append-icon="mdi-percent-outline"
                    tabindex="-1"
                    @input="computeResult(res)"
                  />
                </td>
                <td
                  v-if="template.enableEvaluationPercentage"
                  style="width: 150px"
                >
                  <v-text-field
                    v-model="res.score"
                    label="0 ~ 100"
                    type="number"
                    solo
                    class="mt-4"
                    background-color="yellow lighten-2"
                    dense
                    :rules="[numberRule, noneEmptyRule]"
                    @input="computeResult(res)"
                  />
                </td>
                <td
                  v-if="template.enableEvaluationPercentage"
                  style="width: 100px"
                >
                  <p :key="componentKey">{{ res.managerResult }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row v-if="template.enableEvaluationPercentage" class="mt-6 mb-12">
          <v-col cols="12" :key="componentKey">
            <h2
              v-bind:class="[
                responsibilityScoreTotal > 100 ? 'red--text' : '',
                'float-right',
              ]"
            >
              Total Score For Objectives: {{ responsibilityScoreTotal }} / 100
            </h2>
          </v-col>

          <v-col v-if="responsibilityScoreTotal > 100" cols="12">
            <p class="red--text float-right">
              The total cannot be greater than 100.
            </p>
          </v-col>
        </v-row>

        <v-simple-table v-if="answers" class="mt-8">
          <template v-slot:default>
            <thead>
              <tr>
                <th><h2>Evaluation of Leadership Principles</h2></th>
                <th><h2>Assessment</h2></th>
                <th v-if="template.enableOKRPercentage">
                  <h2 class="text-left">Weighted Importance (Total to 100%)</h2>
                </th>
                <th v-if="template.enableOKRPercentage"><h2>Score</h2></th>
                <th v-if="template.enableOKRPercentage"><h2>Result</h2></th>
              </tr>
            </thead>
            <tbody>
              <tr no-gutters v-for="(eva, k) in answers.evaluations" :key="k">
                <td>
                  <p class="body-1 mt-2">{{ eva.category }}</p>
                </td>
                <td>
                  <v-textarea
                    dense
                    auto-grow
                    class="mt-4"
                    rows="1"
                    hint="Completed by the employee"
                    tabindex="-1"
                    v-model="eva.assessment"
                  />
                </td>
                <td v-if="template.enableOKRPercentage" style="width: 100px">
                  <v-text-field
                    dense
                    class="mt-4"
                    hint="Completed by the employee"
                    v-model="eva.percentage"
                    append-icon="mdi-percent-outline"
                    tabindex="-1"
                    @input="computeResult(res)"
                  />
                </td>

                <td v-if="template.enableOKRPercentage" style="width: 150px">
                  <v-text-field
                    v-model="eva.score"
                    label="0 ~ 100"
                    type="number"
                    solo
                    class="mt-4"
                    background-color="yellow lighten-2"
                    dense
                    @input="computeResult(eva)"
                    :rules="[numberRule, noneEmptyRule]"
                  />
                </td>
                <td v-if="template.enableOKRPercentage" style="width: 100px">
                  <p :key="componentKey">{{ eva.managerResult }}</p>
                  <!-- <v-text-field
                  v-model="eva.managerResult"
                  append-icon="mdi-percent-outline"
                  type="number"
                  solo
                  background-color="yellow lighten-2"
                  class="mt-4"
                  dense
                  :rules="[numberRule]"
                  @change="getTotalResult()"
                /> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row v-if="template.enableEvaluationPercentage" class="mt-12">
          <v-col cols="12" :key="componentKey">
            <h2
              v-bind:class="[
                evaluationsTotal > 100 ? 'red--text' : '',
                'float-right',
              ]"
            >
              Total Score For Leadership Principles:
              {{ evaluationsTotal }} / 100
            </h2>
          </v-col>
          <v-col v-if="evaluationsTotal > 100" cols="12">
            <p class="red--text float-right">
              The final score cannot be greater than 100.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="template.enableEvaluationPercentage" class="mt-12">
          <v-col cols="12" :key="componentKey">
            <h2 class="float-right">
              Overall Performance Score:
              {{
                parseInt(
                  responsibilityScoreTotal * 0.75 + evaluationsTotal * 0.25
                )
              }}
              / 100
            </h2>
          </v-col>
          <v-col v-if="evaluationsTotal > 100" cols="12">
            <p class="red--text float-right">
              The final score cannot be greater than 100.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="!template.disableSelfEvaluation" class="mt-6">
          <v-col cols="12">
            <h2 class="mb-4">Employee Self Evaluation</h2>

            <p class="body-2">Question: "{{ template.evaluationQuestion }}"</p>

            <p
              v-if="answers"
              class="body-1"
              v-html="answers.selfEvaluation"
            ></p>
          </v-col>
        </v-row>

        <v-divider />

        <v-row v-if="isCalibrationEligible" class="mt-12">
          <v-col cols="12">
            <h2>Summary of the overall performance</h2>
            <p>
              Manager summarize the overall performance. Please include where
              the employee has done well and where they could have done better.
            </p>
            <p>
              <a
                href="https://drive.google.com/file/d/13koPfdFZgs9ONgpPlvxJzMYZHyoZgpJW/view?usp=sharing"
                target="_blank"
              >
                Manager Feedback Tips
              </a>
            </p>
            <v-textarea
              label="Manager's comment"
              dense
              auto-grow
              rows="3"
              solo
              background-color="yellow lighten-2"
              :disabled="!isOpen && !isAdmin(boardId)"
              :rules="[noneEmptyRule]"
              v-model="comment"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row v-if="isCalibrationEligible">
          <v-col cols="12">
            <h2>Select the final rating of employee</h2>

            <p>
              <i
                >* Note: the term "Performance Expectations" below refers to the
                expected delivery of both core responsibilities of the role and
                agreed goals/objectives for the performance period.</i
              >
            </p>
            <ul>
              <li>
                <p>
                  <b
                    >Outstanding
                    <!-- <span v-if="template.enableOKRPercentage"
                      >(80 -100)
                    </span> -->
                  </b>
                  <br />
                  Significantly and consistently exceeded all performance
                  expectations*, while serving as a role model for leadership
                  principles.<br />
                  Executed goals and responsibilities with a high degree of
                  autonomy.<br />
                  Exceptional contributor to the success of the company
                  (department or site depending on the role).
                </p>
              </li>
              <li>
                <p>
                  <b>Exceeded Expectation </b>
                  <br />
                  Achieved and frequently exceeded performance expectations*,
                  while consistently demonstrating leadership principles.<br />
                  Executed goals and responsibilities with the expected level of
                  guidance, occasionally demonstrating a high degree of
                  autonomy.
                </p>
              </li>
              <li>
                <p>
                  <b>Met Expectation </b><br />
                  Met overall performance expectations* and demonstrated
                  leadership principles. May have exceeded expectations
                  occasionally.<br />
                  Executed goals and responsibilities with the expected level of
                  guidance.
                </p>
              </li>
              <li>
                <p>
                  <b>Below Expectation </b>
                  <br />
                  Partially met performance expectations* but missed some
                  deliverables with moderate impact or exhibited behaviors that
                  do not align with leadership principles.
                  <br />
                  Required moderate guidance and supervision.
                </p>
              </li>
              <li>
                <p>
                  <b>Needs Improvement </b><br />
                  Did not meet most performance expectations* (constantly missed
                  deliverables or missed deliverables with high impact) or
                  significantly lacked behaviors aligned with leadership
                  principles.<br />
                  Required excessive guidance and supervision.
                </p>
              </li>
            </ul>

            <v-select
              :items="ratings"
              v-model="finalRating"
              :disabled="!isOpen && !isAdmin(boardId)"
              background-color="yellow lighten-2"
              label="Manager's Final Rating"
              :rules="[noneEmptyRule]"
              solo
            ></v-select>
            <div v-if="isVP || isAdmin(boardId)">
              <h2>
                Senior leader calibrated rating: Select the final rating of the
                employee
              </h2>
              <v-select
                :items="ratings"
                v-model="VPRating"
                :disabled="!isOpen && !isAdmin(boardId)"
                background-color="red lighten-2"
                label="Senior leader calibrated rating"
                solo
              ></v-select>
            </div>
            <div v-if="isCEO || isAdmin(boardId)">
              <h2>
                (Optional) CEO can select the final rating of the employee if
                different from above
              </h2>
              <v-select
                :items="ratings"
                v-model="CEORating"
                background-color="blue lighten-2"
                label="Final calibrated rating"
                solo
              ></v-select>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              dense
              class="mr-4"
              :loading="isSaving"
              @click="submit(false)"
            >
              Save
            </v-btn>

            <v-btn
              v-if="isCalibrationEligible"
              dense
              :loading="isSaving"
              color="yellow"
              @click="submit(true)"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="isAdmin(boardId) || isVP">
        <h3>Audit trail</h3>
        <ul>
          <li v-for="(log, i) in logs" :key="i">
            <p>
              {{ getUpdatedByInfo(log.updatedBy) }} - Updated
              {{ log.updatedAt.seconds | moment("from") }} ({{
                log.updatedAt.seconds | moment("ddd, MMM D, h:mm a")
              }})
            </p>
          </li>
        </ul>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceReviewResponse",
  props: {},
  data: () => ({
    ratings: [
      "Outstanding",
      "Exceeded Expectation",
      "Met Expectation",
      "Below Expectation",
      "Needs Improvement",
    ],
    isSaving: false,
    answers: null,
    template: null,
    finalRating: null,
    VPRating: null,
    CEORating: "",
    comment: "",
    totalResult: 0,
    responsibilityScoreTotal: 0,
    evaluationsTotal: 0,
    componentKey: 0,
    person: null,
    isVP: false,
    isCEO: false,
    isOpen: false,
    logs: [],
    userNames: [],
    numberRule: (v) => {
      if (!v) return true;
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
      return "Number has to be between 0 and 100";
    },
    noneEmptyRule: (v) => {
      return !!v || "Required";
    },
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "isAdmin",
      "activeEmployees",
      "user",
      "people",
      "role",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    targetPersonId() {
      return this.$route.params.personId;
    },
    isCalibrationEligible() {
      if (this.template?.name) {
        return !this.template.name.includes("Goal");
      }
      return true;
    },
  },
  watch: {
    CEORating: function () {
      console.log(this.CEORating);

      if (this.isCEO || this.isAdmin(this.boardId)) {
        this.updatePerformanceReviewCEORating({
          boardId: this.boardId,
          templateId: this.templateId,
          revieweePersonId: this.targetPersonId,
          user: this.user,
          CEORating: this.CEORating,
        });
      }
    },
  },
  async mounted() {
    this.getRevieweeProfile();
    this.isVP = this.$route.query.vpm ? true : false;

    this.answers = await this.fetchPerformanceReviewAnswer({
      boardId: this.boardId,
      templateId: this.templateId,
      personId: this.targetPersonId,
    });

    if (this.answers) {
      this.answers.responsibilities = this._.filter(
        this.answers.responsibilities,
        (res) => {
          return (
            res.responsibility ||
            res.result ||
            res.percentage ||
            res.action ||
            res.target
          );
        }
      );

      this._.each(this.answers.responsibilities, (res) => {
        if (res.score === undefined || !res.score) res.score = null;
        if (res.managerResult === undefined || !res.managerResult)
          res.managerResult = 0;

        this.computeResult(res);
      });

      this._.each(this.answers.evaluations, (eva) => {
        if (eva.score === undefined || !eva.score) eva.score = null;
        if (eva.managerResult === undefined || !eva.managerResult)
          eva.managerResult = 0;

        this.computeResult(eva);
      });

      if (this.answers.comment) {
        this.comment = this.answers.comment;
        this.comment = this.comment.replaceAll("<br/>", "\n");
      }

      if (this.answers.finalRating) this.finalRating = this.answers.finalRating;
      if (this.answers.VPRating) this.VPRating = this.answers.VPRating;
    }

    this.template = await this.fetchPerformanceReviewTemplate({
      boardId: this.boardId,
      templateId: this.templateId,
    });

    await this.fetchPeople({ boardId: this.boardId });

    if (
      this.isAdmin(this.boardId) ||
      this.employeesByPersonId[this.myPersonId(this.boardId)].role === "ceo"
    ) {
      this.isCEO = true;
      console.log(this.answers);
      if (this.answers && this.answers.CEORating)
        this.CEORating = this.answers.CEORating;
    }
    this.getTotalResult();
    this.importVPMapping();

    if (this.isAdmin(this.boardId)) {
      this.logs = await this.fetchLogs({
        boardId: this.boardId,
        templateId: this.templateId,
        personId: this.targetPersonId,
      });

      this.logs.push(this.answers);

      this.logs = this._.sortBy(this.logs, "updatedAt.seconds");

      const uids = this._.chain(this.logs)
        .map((log) => {
          return log.updatedBy;
        })
        .uniq()
        .value();
      this.userNames = await this.getUserNames(uids);
    }
  },
  methods: {
    ...mapActions([
      "fetchPerformanceReviewAnswer",
      "fetchPerformanceReviewTemplate",
      "updatePerformanceReviewAnswers",
      "updatePerformanceReviewCEORating",
      "fetchPerson",
      "fetchPeople",
      "getVPMapping",
      "fetchLogs",
      "getUserNames",
    ]),
    async importVPMapping() {
      console.log("importVPMapping");
      // const vpmapping = await this.getVPMapping({
      //   boardId: this.boardId,
      //   templateId: this.templateId,
      //   user: this.user,
      // });

      // const isVP = this._.filter(vpmapping.mapping, (map) => {
      //   return map.personId === this.targetPersonId;
      // });

      // this.isVP = isVP.length > 0 ? true : false;
    },
    getTotalResult() {
      if (this.answers) {
        this.responsibilityScoreTotal = this._.sumBy(
          this.answers.responsibilities,
          (res) => {
            return res.score
              ? (parseInt(res.score) * parseInt(res.percentage)) / 100
              : 0;
          }
        );

        this.evaluationsTotal = this._.sumBy(
          this.answers.evaluations,
          (eva) => {
            return eva.score
              ? (parseInt(eva.score) * parseInt(eva.percentage)) / 100
              : 0;
          }
        );
      }

      // this.totalResult = sum1 + sum2;
      // this.averageResult =
      //   this.totalResult /
      //   (this.answers.responsibilities.length +
      //     this.answers.evaluations.length);
      // this.averageResult = this.averageResult.toFixed(2);
    },
    getUpdatedByInfo(uid) {
      const user = this._.find(this.userNames, (user) => {
        return user.uid === uid;
      });
      if (user) {
        return `${user.displayName} (${user.email})`;
      }
    },
    computeResult(res) {
      if (res?.percentage) {
        // If percentage exists, calculate the result
        // Result = percentage x score
        res.managerResult = (
          (parseInt(res.percentage) / 100) *
          parseInt(res.score)
        ).toFixed(2);
        this.getTotalResult();
        this.forceRerender();
      }
      // res.managerResult = 0;
    },
    async getRevieweeProfile() {
      // if (this.answers) return this.answers.revieweeName;

      this.person = await this.fetchPerson({
        boardId: this.boardId,
        personId: this.targetPersonId,
      });

      if (this.isCEO || this.isAdmin(this.boardId)) {
        this.CEORating = this.answers.CEORating;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async submit(showDialog) {
      this.isSaving = true;

      if (!this.$refs.form.validate()) {
        this.isSaving = false;
        return;
      }

      const data = {
        boardId: this.boardId,
        templateId: this.templateId,
        personId: this.targetPersonId,
        user: this.user,
        finalRating: this.finalRating,
        responsibilities: [],
        evaluations: [],
        VPRating: this.VPRating,
        comment: this.comment.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
      };

      if (this.answers) {
        data.responsibilities = this.answers.responsibilities;
        data.evaluations = this.answers.evaluations;
      }

      await this.updatePerformanceReviewAnswers(data);
      this.isSaving = false;
      if (showDialog) alert("Rating is submitted. Thank you.");
    },
  },
};
</script>
