<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        outlined
        color="orange"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      >
        Import VP CSV Mapping
      </v-btn>
    </template>

    <v-card class="pa-8">
      <p>
        Paste the CSV export of Employee to VP mapping. The required columns are
        Position ID (of the employee), and VP ID (Position ID of VP).
        <b class="red--text">Warning: This will override the current mapping</b>
      </p>
      <p>Example</p>
      <v-img width="200" src="@/assets/Employee_n_VP_Mapping_Example.png" />
      <v-textarea
        v-model="vps"
        outlined
        label="Paste CSV here."
        class="mt-2"
      ></v-textarea>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialog = false" :disabled="isSending">
          Cancel
        </v-btn>
        <v-btn color="primary" text :loading="isSending" @click="done()">
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VP_CSVImport",
  props: {
    loading: {
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    isSending: false,
    vps: "",
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions(["setVPMapping"]),
    async done() {
      const csvString = this.vps;
      var csvRowArray = csvString.split(/\n/);
      var headerCellArray = csvRowArray.shift().split(",");
      var objectArray = [];

      while (csvRowArray.length) {
        var rowCellArray = csvRowArray.shift().split(",");
        var rowObject = this._.zipObject(headerCellArray, rowCellArray);
        objectArray.push(rowObject);
      }

      objectArray = this._.filter(objectArray, (obj) => {
        return obj["Position ID"] && obj["VP ID"];
      });

      const peopleByPositionId = this._.keyBy(this.people, (person) => {
        return person.sourceEmployeeId;
      });

      objectArray = this._.map(objectArray, (obj) => {
        // each object has two fields
        // Position ID and VP ID
        try {
          obj.personId = peopleByPositionId[obj["Position ID"]].personId;
          obj.vpPersonId = peopleByPositionId[obj["VP ID"]].personId;
        } catch (e) {
          console.error(e);
          console.log(obj);
        }

        return obj;
      });

      await this.setVPMapping({
        boardId: this.boardId,
        templateId: this.templateId,
        mapping: objectArray,
      });

      this.dialog = false;
      this.$emit("imported");
    },
  },
};
</script>
