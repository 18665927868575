<template>
  <!-- Saywee specific alert for now -->

  <v-row
    v-if="enabledBoardIds.includes(boardId) && forms.length > 0"
    class="my-6"
  >
    <!-- HARD CODED FOR CEO OF WEEE -->
    <!-- <v-col v-if="uid === 'RGmJgYSVhlMubvmDbfv0vgN3nrs1'" cols="12" md="6">
      <v-alert border="left" colored-border color="" elevation="1">
        <h3>CEO's Evaluation</h3>

        <ul>
          <li style="list-style: none" class="pa-0;">
            <a
              class="ml-n6"
              :href="`/#/PerformanceReviewCEOApproval/bd_UqtZmqM98qM1DKsuA04JPM/prt_0qp0BdnHjjndqvbgDKRc13?vpm=1`"
              >H2 Performance Review - OPs</a
            >
          </li>

          <li style="list-style: none" class="pa-0;">
            <a
              class="ml-n6"
              :href="`/#/PerformanceReviewCEOApproval/bd_UqtZmqM98qM1DKsuA04JPM/prt_CvQ4eiPwzXO6ktrNr47aS?vpm=1`"
              >H2 Performance Review Non-Ops</a
            >
          </li>
        </ul>
      </v-alert>
    </v-col> -->

    <v-col cols="12" md="6">
      <v-alert border="left" colored-border color="af_green" elevation="1">
        <h3>View your final performance review rating</h3>

        <ul>
          <li v-for="form in forms" :key="form.templateId">
            <a
              :href="`/#/PerformanceReviewRatingOnly/${boardId}/${form.templateId}`"
              >See my final rating for {{ form.name }}</a
            >
          </li>
        </ul>
      </v-alert>
    </v-col>

    <v-col cols="12" md="6">
      <v-alert border="left" colored-border color="af_green" elevation="1">
        <h3>Performance Review</h3>
        <p class="body-2 mt-2">
          If you have not completed your performance review yet, please go to
          the links below to complete them.
        </p>
        <ul>
          <li v-for="form in forms" :key="form.templateId">
            <a
              :href="`/#/performance-review/${form.boardId}/${form.templateId}`"
              >{{ form.name }}</a
            >
            (Deadline: {{ form.deadline }})
          </li>
        </ul>
      </v-alert>
    </v-col>

    <!-- <v-col v-if="isManager" cols="12" md="6">
      <v-alert border="left" colored-border color="af_red" elevation="1">
        <h3>Manager's Evaluation</h3>
        <p class="body-2 mt-2">
          If you are a manager, please complete manager's employee evaluation by
          Jan 21, 2022.
        </p>
        <ul>
          <li
            v-for="form in forms"
            :key="form.templateId"
            style="list-style: none"
            class="pa-0;"
          >
            <a
              v-if="!form.name.includes('Goal')"
              class="ml-n6"
              :href="`/#/PerformanceReviewManagerView/${form.boardId}/${form.templateId}`"
              >Evaluate employee submitted assessments</a
            >
          </li>
        </ul>
      </v-alert>
    </v-col>

    <v-col v-if="isVP" cols="12" md="6">
      <v-alert border="left" colored-border color="af_blue" elevation="1">
        <h3>For VPs</h3>

        <ul>
          <li v-for="templateId in getVPTemplateIds()" :key="templateId + 'vp'">
            <a
              :href="`/#/PerformanceReviewVPApproval/${boardId}/${templateId}?vpm=1`"
              >VP Evaluation Page for {{ getTemplateName(templateId) }}</a
            >
          </li>
        </ul>
      </v-alert>
    </v-col> -->
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "About",
  data: () => ({
    enabledBoardIds: [
      "demo_IF7f7SiuA8alVqaomQBRw",
      "bd_UqtZmqM98qM1DKsuA04JPM",
    ],
    associatedReviews: [],
    forms: [],
    isManager: false,
    vps: [],
    templateIds: null,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "people",
      "user",
      "getBoard",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },

    // Returns true if the current user is VP
    isVP() {
      // return this.vps.includes(this.myPersonId(this.boardId));
      return this._.find(this.vps, (map) => {
        return this._.find(map.mapping, (_m) => {
          return this.myPersonId(this.boardId) === _m.vpPersonId;
        });
      });
    },
  },
  watch: {
    people: function () {
      if (this.people.length > 0) {
        this.load();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.people.length === 0) {
        this.fetchPeople({ boardId: this.boardId });
      }
    }, 3000);
  },
  methods: {
    ...mapActions([
      "getPerformanceReviewForPersonId",
      "fetchPerformanceReviewTemplate",
      "fetchPeople",
      "getVPMapping",
    ]),

    async fetchVPMaps(templateId) {
      const vpmapping = await this.getVPMapping({
        boardId: this.boardId,
        templateId: templateId,
      });

      this.vps.push(vpmapping);
    },

    getVPTemplateIds() {
      const ret = this._.filter(this.vps, (map) => {
        return this._.find(map.mapping, (_m) => {
          return this.myPersonId(this.boardId) === _m.vpPersonId;
        });
      });

      if (ret) return this._.map(ret, "templateId");
      return [];
    },

    getTemplateName(templateId) {
      const ret = this._.find(this.forms, (form) => {
        return form.templateId === templateId;
      });

      if (ret) return ret.name;
      return "";
    },

    async load() {
      const ret = await this.getPerformanceReviewForPersonId({
        boardId: this.boardId,
        personId: this.myPersonId(this.boardId),
      });

      this.templateIds = this._.map(ret, (template) => {
        return template.templateId;
      });

      this.associatedReviews.splice(0);
      this._.each(ret, (o) => {
        this.associatedReviews.push({
          boardId: o.boardId,
          templateId: o.templateId,
        });
      });

      this.forms.splice(0);

      // Fetch the templates to display titles
      this._.each(this.associatedReviews, async (o) => {
        try {
          // Oddly, fetchPerformanceReviewTemplate is called without
          // a boardId or templateId from time to time
          const t = await this.fetchPerformanceReviewTemplate({
            boardId: o.boardId,
            templateId: o.templateId,
          });
          this.forms.push({
            boardId: t.boardId,
            templateId: t.templateId,
            name: t.name,
            deadline: t.deadline,
          });

          this.fetchVPMaps(t.templateId);
        } catch (e) {
          console.error(e);
        }
      });

      // Check if this person is a manager, if so display review table
      const myPersonId = this.myPersonId(this.boardId);
      const me = this.employeesByPersonId[myPersonId];
      if (me && me.subordinates.length > 0) {
        // Then this is a manager and likely to have to review others' performnace assessments
        this.isManager = true;
      }
    },
  },
};
</script>
