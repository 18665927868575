<template>
  <v-container v-if="!isLoading">
    <h1 class="mt-8">Customize the performance review form</h1>
    <h3 class="mb-8">
      This is reviewee facing template. Managers can add scores or ratings in
      the <u>Manager's Review tab</u>
    </h3>

    <p class="body-2 my-4">
      Shareable link to the performance reivew form:
      <a
        :href="`https://alpha.agentnoon.com/#/performance-review/${this.boardId}/${this.templateId}`"
        >https://alpha.agentnoon.com/#/performance-review/{{ boardId }}/{{
          templateId
        }}</a
      >
    </p>
    <v-card class="card-shadow pa-4">
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="name"
            label="Title of the performance review"
            hint="Employee self-assessment 2022 Q1"
            solo
            required
            :rules="textNoneEmptyRule"
            :disabled="locked"
            @keydown.enter="save()"
          ></v-text-field>
        </v-col>

        <v-col cols="6">
          <v-menu
            ref="deadlineMenu"
            v-model="deadlineMenu"
            :close-on-content-click="false"
            :return-value.sync="deadline"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="locked"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="deadline"
                label="Due date (Used for alerts)"
                append-icon="mdi-calendar"
                readonly
                solo
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="deadline" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.deadlineMenu.save(deadline)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-0">
        <v-col cols="12">
          <v-checkbox
            class="float-right"
            label="Enable Percentage"
            v-model="enableOKRPercentage"
            :disabled="locked"
          ></v-checkbox>

          <v-checkbox
            class="float-right mr-2"
            label="Enable Goal Setting Mode"
            v-model="goalSettingMode"
            :disabled="locked"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Objectives</th>
              <th v-if="!goalSettingMode">Achievement/Result</th>
              <th v-if="goalSettingMode">Target</th>
              <th v-if="goalSettingMode">Action</th>
              <th v-if="enableOKRPercentage">Precentage</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in responsibilities" :key="r.okrId">
              <td style="width: 200px">
                <v-text-field
                  v-model="r.responsibility"
                  :label="`Project/OKR #${i + 1}`"
                  hint="Filled out by employee"
                  outlined
                  dense
                ></v-text-field>
              </td>
              <td v-if="!goalSettingMode">
                <v-text-field
                  v-model="r.result"
                  :label="`Achievement/Result #${i + 1}`"
                  hint="Filled out by employee"
                  outlined
                  dense
                ></v-text-field>
              </td>

              <td v-if="goalSettingMode">
                <v-text-field
                  v-model="r.target"
                  hint="Filled out by employee"
                  outlined
                  dense
                ></v-text-field>
              </td>

              <td v-if="goalSettingMode">
                <v-text-field
                  v-model="r.action"
                  hint="Filled out by employee"
                  outlined
                  dense
                ></v-text-field>
              </td>

              <td v-if="enableOKRPercentage" style="width: 100px">
                <v-text-field
                  v-model="r.percentage"
                  label="%"
                  type="number"
                  hint="Filled out by employee"
                  outlined
                  dense
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row no-gutters>
        <v-col cols="12" class="pa-4">
          <v-btn @click="addOKR" dense class="mr-4 white--text" color="af_blue">
            <v-icon class="" dense>mdi-plus-circle-outline</v-icon>
            Add Row
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field
            class="mt-4 ml-4"
            v-model="evaluationTitle"
            label="Evaluation criteria title"
            hint="Leadership Principles"
            solo
            required
            :rules="textNoneEmptyRule"
            :disabled="locked"
            @keydown.enter="save()"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            label="Enable Self-Assessment"
            v-model="enableSelfAssessment"
            :disabled="locked"
          ></v-checkbox>
        </v-col>
        <v-col cols="2">
          <v-checkbox
            label="Enable Percentage"
            v-model="enableEvaluationPercentage"
            :disabled="locked"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Evaluations</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(evaluation, k) in evaluationCategories"
              :key="k"
              class="mt-1"
            >
              <td style="width: 400px">
                <v-text-field
                  v-model="evaluationCategories[k]"
                  :label="`Category #${k + 1}`"
                  hint="Set any evaluation criteria"
                  outlined
                  dense
                />
              </td>

              <td v-if="enableSelfAssessment">
                <v-text-field
                  outlined
                  dense
                  :placeholder="evaluationDescriptions[k]"
                  hint="Filled out by employee"
                />
              </td>
              <td v-if="enableEvaluationPercentage" style="width: 200px">
                <v-text-field
                  label="%"
                  type="number"
                  outlined
                  dense
                  hint="Filled out by employee"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row class="my-12"
        ><v-col cols="12" class="d-flex justify-end">
          <v-switch
            v-model="disableSelfEvaluation"
            label="Remove self evaluation"
          ></v-switch>
        </v-col>
      </v-row>

      <v-row v-if="!disableSelfEvaluation" class="mt-4">
        <v-col cols="12">
          <h3 class="mb-4 ml-4">Employee Self Evaluation</h3>

          <v-text-field
            class="mt-4 mx-4"
            v-model="evaluationQuestion"
            hint="Set an employee self-evaluation question"
            solo
            required
            :rules="textNoneEmptyRule"
            :disabled="locked"
            @keydown.enter="save()"
          ></v-text-field>

          <v-textarea outlined dense class="mx-4" label=""></v-textarea>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn @click="checkSample()" dense class="mr-4">
            Check sample
          </v-btn>
          <v-btn
            :disabled="!this.name"
            dense
            class="white--text"
            :loading="isSaving"
            color="af_blue"
            @click="save"
          >
            <v-icon class="mr-2" dense>mdi-content-save</v-icon>
            Save
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="updatedAt" no-gutters>
        <v-col cols="12">
          <p class="caption mt-4 float-right">
            Saved at {{ updatedAt | moment("ddd, MMM D h:mm a") }}
          </p>
        </v-col></v-row
      >
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { createPerformanceReviewTemplateOkrId } from "@/lib/idCreator";

export default {
  name: "PerformanceReviewTemplate",
  components: {},
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "people",
      "user",
      "getBoard",
      "myEmail",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    board() {
      return this.getBoard(this.boardId);
    },
  },
  data: () => ({
    isLoading: true,
    isSaving: false,
    // Array of question objects
    // { okrId, responsibility: "", result: "", percentage: ""}
    responsibilities: [],
    evaluationCategories: [
      "Customer Centric",
      "Hire & Grow Right People",
      "Act Like Owner",
      "Play To Win",
    ],
    evaluationDescriptions: [
      "Put customers’ needs first;  Think long-term and always act on behalf of the company, not just your own team",
      "Be humble with a growth mindset",
      "Be responsible and demonstrate great attitude towards work; Take initiative and deliver high quality of work",
      "Invent and simplify; Drive for results",
    ],
    deadline: "",
    deadlineMenu: false,
    enableOKRPercentage: true,
    goalSettingMode: false,
    enableEvaluationPercentage: true,
    enableSelfAssessment: false,
    name: "",
    evaluationTitle: "",
    evaluationQuestion:
      "What they've achieved so far and How do they demonstrate the leadership principles? Where they've done well and where they could be better?",
    updatedAt: null,
    textNoneEmptyRule: [(v) => !!v || "Please fill out this field"],
    locked: false,
    disableSelfEvaluation: false,
  }),
  watch: {},
  mounted() {
    this.init();
    this.addOKR();
    this.addOKR();
    this.addOKR();
    this.addOKR();
  },
  methods: {
    ...mapActions([
      "fetchPerformanceReviewTemplate",
      "updatePerformanceReviewTemplate",
    ]),
    async init() {
      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.isLoading = false;

      if (ret === "error") {
        throw new Error("error fetching performence review template");
      }
      this.name = ret.name;
      this.evaluationTitle = ret.evaluationTitle;
      if (ret.responsibilities) this.responsibilities = ret.responsibilities;
      if (ret.deadline) this.deadline = ret.deadline;

      if (ret.evaluationCategories?.length > 0)
        this.evaluationCategories = ret.evaluationCategories;

      if (ret.enableEvaluationPercentage !== "undefined")
        this.enableEvaluationPercentage = ret.enableEvaluationPercentage;
      if (ret.enableOKRPercentage !== "undefined")
        this.enableOKRPercentage = ret.enableOKRPercentage;
      if (ret.enableEvaluationPercentage !== "undefined")
        this.enableEvaluationPercentage = ret.enableEvaluationPercentage;
      if (ret.enableSelfAssessment !== "undefined")
        this.enableSelfAssessment = ret.enableSelfAssessment;

      if (ret.goalSettingMode !== "undefined")
        this.goalSettingMode = ret.goalSettingMode;

      if (ret.evaluationQuestion)
        this.evaluationQuestion = ret.evaluationQuestion;

      if (ret.disableSelfEvaluation)
        this.disableSelfEvaluation = ret.disableSelfEvaluation;

      if (ret.locked !== "undefined") this.locked = ret.locked;
      this.updatedAt = ret.updatedAt.seconds;
    },
    addOKR() {
      this.responsibilities.push({
        okrId: createPerformanceReviewTemplateOkrId(),
        responsibility: "",
        result: "",
        percentage: "",
      });
    },
    async save() {
      if (!this.name) return;
      this.isSaving = true;
      const updates = {};

      if (this.responsibilities.length > 0) {
        updates.responsibilities = this.responsibilities;
      }

      if (this.name) updates.name = this.name;
      if (this.deadline) updates.deadline = this.deadline;
      if (this.evaluationTitle) updates.evaluationTitle = this.evaluationTitle;
      if (this.evaluationQuestion)
        updates.evaluationQuestion = this.evaluationQuestion;

      updates.evaluationCategories = this.evaluationCategories;
      updates.enableOKRPercentage = this.enableOKRPercentage
        ? this.enableOKRPercentage
        : false;
      updates.enableEvaluationPercentage = this.enableEvaluationPercentage
        ? this.enableEvaluationPercentage
        : false;
      updates.enableSelfAssessment = this.enableSelfAssessment
        ? this.enableSelfAssessment
        : false;
      updates.disableSelfEvaluation = this.disableSelfEvaluation
        ? this.disableSelfEvaluation
        : false;

      updates.goalSettingMode = this.goalSettingMode
        ? this.goalSettingMode
        : false;

      await this.updatePerformanceReviewTemplate({
        templateId: this.templateId,
        boardId: this.boardId,
        user: this.user,
        updates,
      });

      this.updatedAt = new Date();
      this.isSaving = false;
    },
    async checkSample() {
      await this.save();
      window.open(
        `/#/performance-review/${this.boardId}/${this.templateId}?demo=1`
      );
    },
  },
};
</script>

<style scoped>
td,
th {
  border-bottom: none !important;
}
</style>
