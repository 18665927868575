<template>
  <div v-if="isAdmin(boardId)" class="pa-12">
    <v-row v-if="!isLoading" class="mt-6">
      <v-col cols="12" md="8"
        ><h1>CEO's Final Review</h1>

        <div v-if="templateName">
          <h3 class="mt-4">Viewing {{ templateName }}</h3>
        </div>

        <p>
          In case you are having an issue, please take a look at
          <a href="https://doc.clickup.com/d/h/dftja-2407/0635be99c4dcef6 "
            >our quick guide.</a
          >
        </p>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="af_blue"
    ></v-progress-linear>

    <v-row v-if="!isLoading && answers.length > 0">
      <v-col cols="12" xl="6">
        <PerformanceManagementDistribution
          :ratings="answersByPersonId"
          :visibleList="orderedSentList"
          :boardId="boardId"
          :templateId="templateId"
          :showHelpText="true"
          display="VPRating"
          :vpMapping="vpMapping"
          class="my-6"
        /> </v-col
    ></v-row>

    <v-row v-if="!isLoading" no-gutters class="my-6">
      <v-col cols="12" md="6" class="d-flex align-center">
        <v-text-field
          v-model="filterInput"
          dense
          label="Filter by employee name"
          class="my-6 ml-2"
          hide-details
          append-icon="mdi-filter"
          @click:append="filter()"
          v-on:keyup.enter="filter()"
        />
      </v-col>

      <v-col v-if="isVPMode" cols="12" md="6" class="d-flex justify-end">
        <v-btn outlined @click="csvExport()" class="mr-4">Export CSV</v-btn>
        <v-btn
          color="af_blue"
          class="white--text"
          @click="approveAll"
          :loading="isApproving"
          >Approve All Ratings</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-if="!isLoading" class="mb-4">
      <v-col cols="12">
        <v-card class="card-shadow px-4 pb-4">
          <v-simple-table class="mt-0 pt-0" dense>
            <template v-slot:default>
              <thead style="height: 80px">
                <th>Name</th>
                <th>Department</th>
                <th style="height: 140px; min-width: 200px">Executive</th>
                <th style="height: 140px">Evaluating Manager</th>
                <th>Manager's Rating</th>
                <th>
                  <v-btn small text @click="sortByRating = !sortByRating"
                    >VP Rating
                    <v-icon small class="ml-2"
                      >mdi-sort-ascending</v-icon
                    ></v-btn
                  >
                </th>

                <th>VP Approved</th>
                <th>CEO Rating</th>
              </thead>
              <tbody v-if="changedManagerFetched">
                <tr v-for="personId in filteredOrderedList" :key="personId">
                  <td class="text-truncate">
                    <p class="body-1 ma-0 pa-0">
                      {{ employeesByPersonId[personId].name }},
                      {{ employeesByPersonId[personId].role }}
                    </p>
                  </td>

                  <td class="text-center" style="width: 200px">
                    <v-chip
                      class="white--text ma-0"
                      dense
                      small
                      :color="
                        getDepartmentColor(
                          employeesByPersonId[personId].employeeData.department
                        )
                      "
                    >
                      {{
                        employeesByPersonId[personId].employeeData.department
                      }}
                    </v-chip>
                  </td>
                  <td>
                    <p class="body-1 ma-0">
                      <span v-if="employeesByPersonId[vpMapping[personId]]">{{
                        employeesByPersonId[vpMapping[personId]].name
                      }}</span>
                    </p>
                  </td>

                  <td>
                    <span
                      v-if="
                        employeesByPersonId[
                          employeesByPersonId[personId].managers[0]
                        ]
                      "
                    >
                      <p v-if="isManagerAccess" class="body-1 ma-0">
                        <span v-if="changedManagers[personId]">
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}
                          <v-icon small>mdi-chevron-right</v-icon>
                          {{ changedManagers[personId].name }}
                        </span>
                        <span v-else>
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}</span
                        >
                      </p>
                    </span>
                  </td>
                  <td>
                    <v-chip
                      small
                      v-if="answersByPersonId[personId]"
                      :color="
                        getColorByRating(
                          answersByPersonId[personId][0].finalRating
                        )
                      "
                    >
                      {{ answersByPersonId[personId][0].finalRating }}
                    </v-chip>
                  </td>
                  <td class="text-center">
                    <v-chip
                      small
                      v-if="answersByPersonId[personId]"
                      :color="
                        getColorByRating(
                          answersByPersonId[personId][0].VPRating
                        )
                      "
                    >
                      {{ answersByPersonId[personId][0].VPRating }}
                    </v-chip>
                  </td>
                  <td class="text-center">
                    <v-icon
                      v-if="
                        answersByPersonId[personId] &&
                        answersByPersonId[personId][0].VPApproved
                      "
                      large
                      color="af_green"
                      >mdi-check-decagram-outline</v-icon
                    >
                  </td>
                  <td class="text-center">
                    <p v-if="answersByPersonId[personId]" class="body-1 ma-0">
                      {{ answersByPersonId[personId][0].CEORating }}
                    </p>
                  </td>
                  <td class="text-center">
                    <span
                      v-if="
                        answersByPersonId[personId] &&
                        answersByPersonId[personId][0].ceoApproved
                      "
                    >
                      <v-icon large color="af_green">mdi-check-decagram</v-icon>
                    </span>
                    <v-btn
                      v-else
                      small
                      color="af_blue"
                      dense
                      class="ma-0 white--text text-capitalize"
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}`"
                      >Detail</v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import PerformanceManagementDistribution from "@/components/Calibration/PerformanceManagementDistribution";
// import firebase from "firebase/app";
// import "firebase/functions";
import { OutlineColors, BackgroundColors } from "@/lib/Colors";

export default {
  name: "PerformanceReviewCEOApproval",
  components: {
    PerformanceManagementDistribution,
  },
  data: () => ({
    // Use this array to sort the order of display
    roleSortOrder: [
      "noManager",
      "ceo",
      "cto",
      "coo",
      "cpo",
      "chro",
      "cfo",
      "chief",
      "vp",
      "director",
      "senior",
      "sr",
    ],
    vps: [],
    templateName: "",
    filterInput: "",
    filterQuery: "",
    searchQuery: "",
    departments: [], // array of uniq department values
    selectedEmployees: [], // array of personIds that will receive performance review
    showTeamList: [], // Array of manager's personId that indicates if the team table should be expanded or collapsed
    sent: [], // Array of personIds that indicates who received the performance review

    receivers: null, // The full recevier doc from Firestore
    answers: [],
    answersByPersonId: {},

    vpMapping: {}, // Mapping of employees to VPs
    isVPMode: false,

    // For employees that should have a different evaluation manager compared to default.
    changedManagers: {},
    changedManagerFetched: false,
    csvExporting: false,
    isLoading: true,
    isApproving: false,
    sortByRating: true,
    sortByDepartment: false,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
      "isAdmin",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    isManagerAccess() {
      // This is always going to be true for this module
      return true;
    },
    orderedSentList() {
      return this._.chain(this.sent)
        .sortBy((personId) => {
          return this.employeesByPersonId[personId].employeeData.department;
        })
        .sortBy((personId) => {
          return this.employeesByPersonId[personId].managers[0];
        })
        .filter((personId) => {
          // This is hard coded for Weee
          // We should make this dynamic
          if (
            ![
              "prt_0qp0BdnHjjndqvbgDKRc13",
              "prt_CvQ4eiPwzXO6ktrNr47aS",
            ].includes(this.templateId)
          ) {
            return true;
          }

          const startDate =
            this.employeesByPersonId[personId].employeeData.startDate;
          if (startDate) {
            const mStartDate = this.$moment(startDate, "MM/DD/YYYY");
            const comparedDate = this.$moment("10/01/2021", "MM/DD/YYYY");
            if (!mStartDate.isBefore(comparedDate)) {
              return false;
            }
          }

          return true;
        })
        .value();
    },
    filteredOrderedList() {
      // Return the ordered list items that are filtered
      let list = this._.filter(this.orderedSentList, (pid) => {
        return this.employeesByPersonId[pid].name
          .toLowerCase()
          .includes(this.filterQuery.toLowerCase());
      });

      if (this.sortByRating) {
        const ORDER = [
          "Outstanding",
          "Exceeded Expectation",
          "Met Expectation",
          "Below Expectation",
          "Needs Improvement",
        ];
        list = this._.sortBy(list, (pid) => {
          if (this.answersByPersonId[pid]) {
            return -1 * ORDER.indexOf(this.answersByPersonId[pid][0].VPRating);
          }

          return 100;
        });
      }

      return list;
    },
    employeeResponseRate() {
      if (!this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      // console.log(this.answers, this.sent);
      return parseInt((this.answers.length / this.sent.length) * 100);
    },
    managerRatingRate() {
      if (!this.sent || !this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].finalRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    VPRatingRate() {
      if (!this.sent || !this.sent.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].VPRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    managers() {
      // List of people that have a team
      return this._.chain(this.activeEmployees)
        .filter((person) => {
          return (
            person.role &&
            person.name &&
            person.email &&
            person.subordinates &&
            person.subordinates.length > 0
          );
        })
        .map((person) => {
          return { personId: person.personId, name: person.name };
        })
        .value();
    },
  },
  watch: {
    searchQuery: function () {},
    groupPeopleByManager: function () {
      this.departments = this._.chain(this.people)
        .map((p) => {
          return p.employeeData.department.split("-")[0].trim();
        })
        .uniq()
        .sortBy((t) => {
          // This ensures that the color code stays (kinda...)
          return t;
        })
        .value();
    },
  },

  mounted() {
    this.isVPMode = this.$route.query.vpm ? true : false;
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchPeople",
      "fetchPerformanceReviewReceivers",
      "fetchAllPerformanceReviewAnswers",
      "fetchPerformanceReviewTemplate",
      "updatePerformanceReviewCEOApproval",
      "readChangedManagers",
      "getVPMapping",
    ]),
    async importVPMapping() {
      const vpmapping = await this.getVPMapping({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this._.each(vpmapping.mapping, (map) => {
        this.vpMapping[map.personId] = map.vpPersonId;
      });

      this.vps = this._.map(vpmapping.mapping, (map) => {
        return map.vpPersonId;
      });
    },
    async init() {
      console.log("Is Manager View", this.isManagerAccess);
      this.isLoading = true;

      await this.fetchPeople({ boardId: this.boardId });

      // If this is a manager view, then only show the team under management
      if (this.isManagerAccess && this.myPersonId(this.boardId)) {
        this.searchQuery = this.myPersonId(this.boardId);
      }

      this.receviers = await this.fetchPerformanceReviewReceivers({
        boardId: this.boardId,
        templateId: this.templateId,
      });
      this.sent = this.receviers.sent;

      await this.fetchAnswers();

      // Fetch the name of the template
      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.templateName = ret.name;

      const changedManagersObj = await this.readChangedManagers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      if (changedManagersObj) {
        // Convert the Firebase data into changedManager Object
        this._.each(changedManagersObj.changedManagerArray, (obj) => {
          const personId = obj.targetPersonId;

          this.changedManagers[personId] = {
            personId: obj.managerPersonId,
            name: obj.managerName,
          };
          // console.log(this.$refs[`${personId}_evaluating_manager`]);
          // this.$refs[`${personId}_evaluating_manager`][0].value =
          //   this.changedManagers[personId];
        });
      }

      await this.importVPMapping();

      this.changedManagerFetched = true;
      this.isLoading = false;
    },
    getDepartmentColor(department) {
      return OutlineColors[
        this.departments.indexOf(department.split("-")[0].trim())
      ];
    },
    getColorByRating(rating) {
      if (rating === "Outstanding") {
        return BackgroundColors[2];
      }
      if (rating === "Successful") {
        return BackgroundColors[3];
      }
      if (rating === "Needs Improvement") {
        return BackgroundColors[1];
      }
    },

    filter() {
      this.filterQuery = this.filterInput;
    },

    queryManager(person, queryText) {
      const personId = person.personId;

      if (
        !this.employeesByPersonId[personId] ||
        !this.employeesByPersonId[personId].name
      )
        return false;
      return (
        this.employeesByPersonId[personId].name
          .toLowerCase()
          .includes(queryText) ||
        this.employeesByPersonId[personId].role
          .toLowerCase()
          .includes(queryText)
      );
    },
    getName(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.name : "";
    },

    getRole(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.role : "";
    },
    getDepartment(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.employeeData.department : "No Department";
    },
    getLocation(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.officeLocation : "No Location";
    },

    async fetchAnswers() {
      this.answers = await this.fetchAllPerformanceReviewAnswers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.answersByPersonId = this._.groupBy(this.answers, (ans) => {
        return ans.revieweePersonId;
      });
      // console.log(this.answersByPersonId);
    },

    async approveAll() {
      this.isApproving = true;
      // Find the people that will be approved by VPs
      const peopleInDepartment = this._.filter(this.answers, (ans) => {
        return this.orderedSentList.includes(ans.revieweePersonId);
      });

      this._.each(peopleInDepartment, async (ans) => {
        await this.updatePerformanceReviewCEOApproval({
          boardId: this.boardId,
          templateId: ans.templateId,
          revieweePersonId: ans.revieweePersonId,
          ceoApproved: true,
          user: this.user,
        });
      });
      await this.init();
      window.location.reload();
    },
    csvExport() {
      this.csvExporting = true;

      const answerDict = this._.keyBy(this.answers, "revieweePersonId");
      // console.log(answerDict);

      // Create a JSON representation of what we want to export.
      const exportJSON = this._.map(this.orderedSentList, (pid) => {
        const ans = answerDict[pid];

        if (ans) {
          const copy = this._.cloneDeep(ans);

          let managerPersonId =
            this.employeesByPersonId[ans.revieweePersonId].managers[0];
          let manager = this.employeesByPersonId[managerPersonId];

          copy.manager = manager ? manager.name : "";
          copy.department =
            this.employeesByPersonId[
              ans.revieweePersonId
            ].employeeData.department;
          copy.status = "Responded";
          copy.email = this.employeesByPersonId[ans.revieweePersonId].email;

          delete copy.updatedAt;
          delete copy.updatedBy;
          delete copy.createdAt;
          delete copy.createdBy;
          delete copy.boardId;
          delete copy.templateId;
          delete copy.revieweePersonId;
          delete copy.comment;
          delete copy.status;

          delete copy.responsibilities;
          delete copy.evaluations;
          delete copy.selfEvaluation;
          delete copy.finalRating;

          if (this.changedManagers[ans.revieweePersonId]) {
            copy.evaluatingManager =
              this.changedManagers[ans.revieweePersonId].name;
          }

          copy.VPRating = copy.VPRating ? copy.VPRating : "";

          // If VP's mapping exist, add VP's name to the row
          if (this.vpMapping[ans.revieweePersonId]) {
            const vpPersonId = this.vpMapping[ans.revieweePersonId];
            copy.VPName = this.employeesByPersonId[vpPersonId].name;
          }

          // Add Position ID if it exists
          if (
            this.employeesByPersonId[ans.revieweePersonId].importedData &&
            this.employeesByPersonId[ans.revieweePersonId].importedData[
              "Position ID"
            ]
          ) {
            copy.positionId =
              this.employeesByPersonId[ans.revieweePersonId].importedData[
                "Position ID"
              ];
          }

          return copy;
        }

        let managerPersonId = this.employeesByPersonId[pid].managers[0];
        const manager = this.employeesByPersonId[managerPersonId]
          ? this.employeesByPersonId[managerPersonId].name
          : "";
        const emp = this.employeesByPersonId[pid];
        const department = emp ? emp.employeeData.department : "";

        let email = emp.email;

        // Status,Email,Reviewee Name,Department,Manager,Comment,Manager Rating,VP Rating
        return {
          manager,
          department,

          email,
          revieweeName: emp.name,
        };
      });

      const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
      // Get the headers.
      // let header = Object.keys(exportJSON[0]);
      let header = this._.map(exportJSON, (json) => {
        return Object.keys(json);
      });
      header = this._.flatten(header);
      header = this._.uniq(header);
      console.log(header);
      const headerOrders = [
        "revieweeName",
        "department",
        "manager",
        "evaluatingManager",
        "VPName",
        "VPRating",
      ];

      header = this._.sortBy(header, (field) => {
        return headerOrders.indexOf(field) + 1;
      });

      const csv = [
        this._.map(header, (key) => {
          return this._.startCase(key);
        }).join(","), // header row first. Convert the camel case to start case
        ...exportJSON.map((row) =>
          header
            .map((fieldName) => JSON.stringify(row[fieldName], replacer))
            .join(",")
        ),
      ]
        .join("\r\n")
        .replace(/\\"/g, '""');

      let csvContent = "data:text/csv;charset=utf-8," + csv;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `Agentnoon Performance Rating Exports - ${
          this.templateName
        } ${this.$moment().format("MMM DD, YYYY")}.csv`
      );
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".
      this.csvExporting = false;
    },
  },
};
</script>
<style scoped>
tr {
  height: 50px;
}
</style>
