<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        outlined
        color="red"
        v-bind="attrs"
        v-on="on"
        :loading="loading"
      >
        Import CEO Rating
      </v-btn>
    </template>

    <v-card class="pa-8">
      <p>
        Paste the CSV export of Employees' emails to CEO's final rating. The
        required columns are email of the employees, and CEO's final performance
        rating. <br /><b>The columns required are: 'email' and 'rating'.</b>
        <br />
        <b class="red--text">Warning: This will override the current ratings</b>
      </p>
      <p>Example</p>
      <v-img width="200" src="@/assets/CEO_Rating_Import_Example.png" />
      <v-textarea
        v-model="ratings"
        outlined
        label="Paste CSV here."
        class="mt-2"
      ></v-textarea>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialog = false" :disabled="isSending">
          Cancel
        </v-btn>
        <v-btn color="primary" text :loading="isSending" @click="done()">
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VP_CSVImport",
  props: {
    loading: {
      required: false,
      type: Boolean,
    },
  },
  data: () => ({
    dialog: false,
    isSending: false,
    ratings: "",
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions(["updatePerformanceReviewCEORating"]),
    async done() {
      const csvString = this.ratings;
      var csvRowArray = csvString.split(/\n/);
      var headerCellArray = csvRowArray.shift().split(",");
      var objectArray = [];

      while (csvRowArray.length) {
        var rowCellArray = csvRowArray.shift().split(",");
        var rowObject = this._.zipObject(headerCellArray, rowCellArray);
        objectArray.push(rowObject);
      }
      // console.log(objectArray)
      objectArray = this._.filter(objectArray, (obj) => {
        return obj["email"] && obj["rating"];
      });
      // console.log(objectArray)

      const peopleByEmail = this._.keyBy(this.people, (person) => {
        return this._.toLower(person.email);
      });

      objectArray = this._.map(objectArray, (obj) => {
        try {
          const email = this._.toLower(obj.email);
          if (peopleByEmail[email])
            obj.personId = peopleByEmail[email].personId;
          obj.email = email;
        } catch (e) {
          console.error(e);
          console.log(obj);
        }
        return obj;
      });

      this._.each(objectArray, (obj) => {
        console.log(obj.personId);
        this.updatePerformanceReviewCEORating({
          boardId: this.boardId,
          templateId: this.templateId,
          revieweePersonId: obj.personId,
          CEORating: obj.rating,
          user: this.user,
        });
      });

      this.dialog = false;
      this.$emit("imported");
      console.log("done");
    },
  },
};
</script>
