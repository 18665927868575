// import Vue from "vue";
import firebase from "firebase/app";
// import 'firebase/auth';
import "firebase/firestore";
import * as Sentry from "@sentry/browser";
// import { createPersonId } from '@/lib/idCreator';
import { AccessCheck } from "@/lib/Access";

const state = {};

const mutations = {};

const actions = {
  async fetchTypeformWorkspace(context, { boardId }) {
    console.log("fetchTypeformWorkspace");
    var fetchWorkspace = firebase
      .functions()
      .httpsCallable("form-fetchWorkspace");
    const ret = await fetchWorkspace({
      boardId,
    });
    return ret.data;
  },

  async createTypeformWorkspace(context, { boardId }) {
    var createWorkspace = firebase
      .functions()
      .httpsCallable("form-createWorkspace");
    const ret = await createWorkspace({
      boardId: boardId,
    });
    return ret.data;
  },

  async createForm(context, { boardId, templateId, typeformWorkspaceURL }) {
    var createForm = firebase.functions().httpsCallable("form-createForm");
    const ret = await createForm({
      boardId,
      templateId,
      typeformWorkspaceURL,
    });
    return ret;
  },

  async updateForm(context, { boardId, templateId }) {
    var updateForm = firebase.functions().httpsCallable("form-updateForm");
    const ret = await updateForm({
      boardId,
      templateId,
    });
    return ret.data;
  },

  async fetchPerformance(
    context,
    { board, user, targetPerson, me, peopleDict }
  ) {
    // If this role is not hired yet, we can't have a performance indicator
    if (!targetPerson.name) return;
    if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
      // Check if performanceAccessibleByHROnly
      if (context.rootState.settings.performanceAccessibleByHROnly) {
        // If this is on, then check if the current user has
        // HR lead access
        if (me.personId !== context.rootState.settings.hrLeadPersonId) {
          console.log("access not granted");
          return null;
        }
      }

      try {
        const doc = await firebase
          .firestore()
          .collection("performances")
          .doc(`${board.boardId}_${targetPerson.personId}`)
          .get();

        return doc ? doc.data() : null;
      } catch (e) {
        // This means the doc does not exist becuase the performance was never set for this employee
        if (e.code === "permission-denied") return;
        Sentry.captureException(e);
        return;
      }
    } else {
      // This is an illegal action
      return null;
    }
  },

  async storePerformance(
    context,
    { performance, board, user, targetPerson, me, peopleDict }
  ) {
    if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
      // Check if performanceAccessibleByHROnly
      if (context.rootState.settings.performanceAccessibleByHROnly) {
        // If this is on, then check if the current user has
        // HR lead access
        if (me.personId !== context.rootState.settings.hrLeadPersonId) {
          console.log("access not granted");
          return null;
        }
      }

      try {
        await firebase
          .firestore()
          .collection("performances")
          .doc(`${board.boardId}_${targetPerson.personId}`)
          .set({
            personId: targetPerson.personId,
            boardId: board.boardId,
            performance: performance,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        return "error";
      }
    } else {
      // This is an illegal action
    }
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
