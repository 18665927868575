import Vue from "vue";
import firebase from "firebase/app";
// import 'firebase/auth';
import "firebase/firestore";
import * as Sentry from "@sentry/browser";
// import { AccessCheck } from "@/lib/Access";
import { createPerformanceReviewTemplateId } from "@/lib/idCreator";

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();

const mutations = {
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  async fetchPerformanceReviews(context, { boardId }) {
    if (!boardId) {
      throw new Error("Required fields are missing");
    }

    const querySnapshot = await firebase
      .firestore()
      .collection("performance_review_templates")
      .where("boardId", "==", boardId)
      .get();

    const templates = [];
    querySnapshot.forEach((doc) => {
      templates.push(doc.data());
    });
    return templates;
  },

  async fetchPerformanceReviewTemplate(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }
    // if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
    //   // Check if performanceAccessibleByHROnly
    //   if (context.rootState.settings.performanceAccessibleByHROnly) {
    //     // If this is on, then check if the current user has
    //     // HR lead access
    //     if (me.personId !== context.rootState.settings.hrLeadPersonId) {
    //       console.log("access not granted");
    //       return null;
    //     }
    //   }

    try {
      const doc = await firebase
        .firestore()
        .collection("performance_review_templates")
        .doc(`${boardId}_${templateId}`)
        .get();

      return doc.data();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async updatePerformanceReviewTemplate(
    context,
    { boardId, templateId, user, updates }
  ) {
    if (!boardId || !templateId || !user || !updates) {
      throw new Error("Required fields are missing");
    }
    await firebase
      .firestore()
      .collection("performance_review_templates")
      .doc(`${boardId}_${templateId}`)
      .update({
        updatedBy: user.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...updates,
      });
  },

  async fetchPerformanceReviewReceivers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }
    const ret = await firebase
      .firestore()
      .collection("performance_review_receivers")
      .doc(`${boardId}_${templateId}`)
      .get();

    return ret.data();
  },

  async getPerformanceReviewForPersonId(context, { boardId, personId }) {
    if (!boardId) {
      throw new Error("Required fields are missing");
    }
    const ret = await firebase
      .firestore()
      .collection("performance_review_receivers")
      .where("boardId", "==", boardId)
      .where("sent", "array-contains", personId)
      .get();

    const reviews = [];
    ret.forEach((doc) => {
      reviews.push(doc.data());
    });
    return reviews;
  },

  async setPerformanceReviewReceivers(
    context,
    { boardId, templateId, user, receivers }
  ) {
    if (!boardId || !templateId || !user || !receivers) {
      throw new Error("Required fields are missing");
    }
    console.log("setPerformanceReviewReceivers");
    await firebase
      .firestore()
      .collection("performance_review_receivers")
      .doc(`${boardId}_${templateId}`)
      .set(
        {
          boardId,
          templateId,
          updatedBy: user.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          receivers,
        },
        { merge: true }
      );
  },

  async createPerformanceReviewTemplate(context, { board, user }) {
    if (!board || !user) {
      throw new Error("Required fields are missing");
    }
    // if (AccessCheck(board, user, targetPerson, me, peopleDict)) {
    //   // Check if performanceAccessibleByHROnly
    //   if (context.rootState.settings.performanceAccessibleByHROnly) {
    //     // If this is on, then check if the current user has
    //     // HR lead access
    //     if (me.personId !== context.rootState.settings.hrLeadPersonId) {
    //       console.log("access not granted");
    //       return null;
    //     }
    //   }

    try {
      const templateId = createPerformanceReviewTemplateId();
      await firebase
        .firestore()
        .collection("performance_review_templates")
        .doc(`${board.boardId}_${templateId}`)
        .set({
          templateId: templateId,
          boardId: board.boardId,
          completed: 0,
          updatedBy: user.uid,
          createdBy: user.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      return templateId;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async saveDraftPerformanceReviewAnswers(
    context,
    {
      boardId,
      user,
      templateId,
      personId,
      responsibilities,
      evaluations,
      selfEvaluation,
    }
  ) {
    if (!boardId || !user) {
      throw new Error("Required fields are missing");
    }

    try {
      await firebase
        .firestore()
        .collection("performance_review_answer_drafts")
        .doc(`${templateId}_${personId}`)
        .set({
          templateId: templateId,
          boardId: boardId,
          revieweeName: user.displayName,
          revieweePersonId: personId,
          responsibilities,
          evaluations,
          selfEvaluation,
          updatedBy: user.uid,
          createdBy: user.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async loadDraft(context, { boardId, templateId, personId }) {
    console.log(boardId, templateId, personId);
    if (!boardId || !templateId || !personId) {
      throw new Error("Required fields are missing");
    }

    console.log("loadDraft");

    try {
      const doc = await firebase
        .firestore()
        .collection("performance_review_answer_drafts")
        .doc(`${templateId}_${personId}`)
        .get();
      // if (querySnapshot.docs.length > 0) {
      //   return querySnapshot.docs[0].data();
      // }
      return doc.data();
    } catch (e) {
      // console.error(e);
      // Sentry.captureException(e);
      return "document DNE";
    }
  },

  async createPerformanceReviewAnswers(
    context,
    {
      boardId,
      user,
      templateId,
      personId,
      responsibilities,
      evaluations,
      selfEvaluation,
      isSubmitted,
    }
  ) {
    if (!boardId || !user) {
      throw new Error("Required fields are missing");
    }

    try {
      // Fetch the document to be updated
      // If it exists, we are updating
      // If it does not exist, we are creating a new one
      const querySnapshot = await firebase
        .firestore()
        .collection("performance_review_answers")
        .where("templateId", "==", templateId)
        .where("boardId", "==", boardId)
        .where("revieweePersonId", "==", personId)
        .get();

      if (querySnapshot.docs.length > 0) {
        querySnapshot.docs[0].ref.set(
          {
            templateId: templateId,
            boardId: boardId,
            revieweeName: user.displayName,
            revieweePersonId: personId,
            responsibilities,
            evaluations,
            selfEvaluation,
            isSubmitted,
            updatedBy: user.uid,
            createdBy: user.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      } else {
        await firebase
          .firestore()
          .collection("performance_review_answers")
          .doc()
          .set({
            templateId: templateId,
            boardId: boardId,
            revieweeName: user.displayName,
            revieweePersonId: personId,
            responsibilities,
            evaluations,
            selfEvaluation,
            isSubmitted,
            updatedBy: user.uid,
            createdBy: user.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async updatePerformanceReviewAnswers(
    context,
    {
      boardId,
      templateId,
      personId,
      responsibilities,
      evaluations,
      user,
      comment,
      finalRating,
      VPRating,
    }
  ) {
    if (!boardId || !templateId || !personId) {
      throw new Error("Required fields are missing");
    }

    // Fetch the document to be updated
    const querySnapshot = await firebase
      .firestore()
      .collection("performance_review_answers")
      .where("templateId", "==", templateId)
      .where("boardId", "==", boardId)
      .where("revieweePersonId", "==", personId)
      .limit(1)
      .get();

    if (querySnapshot.docs.length === 0) {
      // Create a new doc
      await firebase
        .firestore()
        .collection("performance_review_answers")
        .doc()
        .set({
          templateId: templateId,
          boardId: boardId,
          responsibilities,
          revieweePersonId: personId,
          evaluations,
          comment,
          finalRating,
          VPRating,
          updatedBy: user.uid,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: user.uid,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } else {
      const ref = querySnapshot.docs[0].ref;

      try {
        ref.set(
          {
            responsibilities,
            evaluations,
            comment,
            finalRating,
            VPRating,
            updatedBy: user.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
        return "error";
      }
    }
  },

  async updatePerformanceReviewVPRating(
    context,
    { boardId, templateId, revieweePersonId, VPApproved, user, approvedBy }
  ) {
    if (!boardId || !templateId || !revieweePersonId) {
      throw new Error("Required fields are missing");
    }

    // Fetch the document to be updated
    const querySnapshot = await firebase
      .firestore()
      .collection("performance_review_answers")
      .where("templateId", "==", templateId)
      .where("boardId", "==", boardId)
      .where("revieweePersonId", "==", revieweePersonId)
      .limit(1)
      .get();

    if (querySnapshot.docs.length === 0) {
      throw new Error("Document does not exist");
    }

    const ref = querySnapshot.docs[0].ref;

    try {
      ref.update({
        VPApproved,
        approvedBy,
        updatedBy: user.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async updatePerformanceReviewCEORating(
    context,
    { boardId, templateId, revieweePersonId, CEORating, user }
  ) {
    if (!boardId || !templateId || !revieweePersonId) {
      throw new Error("Required fields are missing");
    }

    console.log("updatePerformanceReviewCEORating");

    // Fetch the document to be updated
    const querySnapshot = await firebase
      .firestore()
      .collection("performance_review_answers")
      .where("templateId", "==", templateId)
      .where("boardId", "==", boardId)
      .where("revieweePersonId", "==", revieweePersonId)
      .limit(1)
      .get();

    if (querySnapshot.docs.length === 0) {
      throw new Error("Document does not exist");
    }

    const ref = querySnapshot.docs[0].ref;

    try {
      ref.update({
        CEORating,
        updatedBy: user.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchSetting(context, { boardId }) {
    if (!boardId) {
      throw new Error("Required fields are missing");
    }

    const doc = await firebase
      .firestore()
      .collection("performance_review_settings")
      .doc(boardId)
      .get();

    return doc.data();
  },

  async updatePerformanceReviewCEOApproval(
    context,
    { boardId, templateId, revieweePersonId, ceoApproved, user }
  ) {
    if (!boardId || !templateId || !revieweePersonId) {
      throw new Error("Required fields are missing");
    }

    // Fetch the document to be updated
    const querySnapshot = await firebase
      .firestore()
      .collection("performance_review_answers")
      .where("templateId", "==", templateId)
      .where("boardId", "==", boardId)
      .where("revieweePersonId", "==", revieweePersonId)
      .limit(1)
      .get();

    if (querySnapshot.docs.length === 0) {
      throw new Error("Document does not exist");
    }

    const ref = querySnapshot.docs[0].ref;

    try {
      ref.update({
        ceoApproved,
        updatedBy: user.uid,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchPerformanceReviewAnswer(
    context,
    { boardId, templateId, personId }
  ) {
    console.log(boardId, templateId, personId);
    if (!boardId || !templateId || !personId) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchPerformanceReviewAnswer");

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("performance_review_answers")
        .where("templateId", "==", templateId)
        .where("boardId", "==", boardId)
        .where("revieweePersonId", "==", personId)
        .orderBy("updatedAt", "desc")
        .get();

      const ret = [];
      querySnapshot.forEach((doc) => {
        ret.push(doc.data());
      });

      // If there are more than one answer, pick the one that is
      // latest && has manager & vp rating
      // This happend because employees were submittinf after
      // the ratings were done. Which caused the existing doc with managers
      // rating to be overwritten.
      return Vue._.maxBy(ret, "updatedAt.seconds");
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchLogs(context, { boardId, templateId, personId }) {
    console.log(boardId, templateId, personId);
    if (!boardId || !templateId || !personId) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchLogs");

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("performance_review_history")
        .where("templateId", "==", templateId)
        .where("boardId", "==", boardId)
        .where("revieweePersonId", "==", personId)
        .orderBy("updatedAt", "desc")
        .get();

      const ret = [];
      querySnapshot.forEach((doc) => {
        ret.push(doc.data());
      });

      return ret;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchPerformanceReviewRatingOnly(
    context,
    { boardId, templateId, revieweePersonId }
  ) {
    console.log(boardId, templateId, revieweePersonId);
    if (!boardId || !templateId || !revieweePersonId) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchPerformanceReviewRatingOnly");

    try {
      var fetch = firebase
        .functions()
        .httpsCallable("performanceReview-fetchRatingForEmployee");

      const rating = await fetch({
        boardId,
        templateId,
        revieweePersonId,
      });

      return rating.data;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchPerformanceReviewsForDepartment(
    context,
    { boardId, templateId, departmentCode }
  ) {
    console.log(boardId, templateId, departmentCode);
    if (!boardId || !templateId || !departmentCode) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchPerformanceReviewsForDepartment");

    try {
      var fetch = firebase
        .functions()
        .httpsCallable("performanceReview-fetchDepartmentReviews");

      const rating = await fetch({
        boardId,
        templateId,
        departmentCode,
      });

      return rating.data;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },
  async fetchReviewsForPersonIds(context, { boardId, targetPersonIds }) {
    if (!boardId || !targetPersonIds) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchReviewsForPersonIds");

    try {
      var fetch = firebase
        .functions()
        .httpsCallable("performanceReview-fetchReviewsForPersonIds");

      const rating = await fetch({
        boardId,
        targetPersonIds,
      });

      return rating.data;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async fetchAllPerformanceReviewAnswers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }

    console.log("fetchAllPerformanceReviewAnswers");

    try {
      const querySnapshot = await firebase
        .firestore()
        .collection("performance_review_answers")
        .where("templateId", "==", templateId)
        .where("boardId", "==", boardId)
        .get();

      const ret = [];
      querySnapshot.forEach((doc) => {
        ret.push(doc.data());
      });

      // Gropu the ansers by person Id. Identified the latest revisions and leave only the latest
      const groupedByPersonId = Vue._.chain(ret)
        .orderBy(["updatedAt.seconds"], ["desc"])
        .groupBy("revieweePersonId")
        .map((arr) => {
          return Vue._.maxBy(arr, "updatedAt.seconds");
        })
        .value();

      return groupedByPersonId;
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  // There are cases where the evaluating manager is different from
  // the current manager.
  // e.g. an employee has switched team recently and the current
  // manager has no context to evaluate the manager.
  // In such case, we must override the reporting manager with
  // a manager responsible for evaluating.
  async saveChangedManagers(
    context,
    { boardId, templateId, changedManagerArray }
  ) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }

    console.log("saveChangedManagers");

    try {
      await firebase
        .firestore()
        .collection("performance_review_changed_managers")
        .doc(`${boardId}_${templateId}`)
        .set({
          boardId,
          templateId,
          changedManagerArray,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },
  async readChangedManagers(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }

    console.log("readChangedManagers");

    try {
      const ret = await firebase
        .firestore()
        .collection("performance_review_changed_managers")
        .doc(`${boardId}_${templateId}`)
        .get();

      return ret.data();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async setVPMapping(context, { boardId, templateId, mapping }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }

    console.log("setVPMapping");

    try {
      await firebase
        .firestore()
        .collection("performance_review_vp_mapping")
        .doc(`${boardId}_${templateId}`)
        .set({
          boardId,
          templateId,
          mapping,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },

  async getVPMapping(context, { boardId, templateId }) {
    if (!boardId || !templateId) {
      throw new Error("Required fields are missing");
    }

    console.log("getVPMapping");

    try {
      const ret = await firebase
        .firestore()
        .collection("performance_review_vp_mapping")
        .doc(`${boardId}_${templateId}`)
        .get();
      return ret.data();
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      return "error";
    }
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
