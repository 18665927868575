<template>
  <v-card class="card-shawdow pa-4">
    <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center"></th>
                <th class="text-center"># of Assessments</th>
                <th class="text-center">Completed</th>
                <th class="text-center">Outstanding</th>
                <th class="text-center">Exceeded Expectation</th>
                <th class="text-center">Met Expectation</th>
                <th class="text-center">Below Expectation</th>
                <th class="text-center">Needs Improvement</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dist in distribution" :key="dist.department">
                <td class="text-center">{{ getDepartment(dist) }}</td>
                <td class="text-center">{{ dist.count }}</td>
                <td class="text-center">
                  {{ getCompletionRate(dist, dist.count) }}
                </td>
                <td class="text-center">
                  {{ getOutstanding(dist, dist.count) }}
                </td>
                <td class="text-center">
                  {{ getExceededExpectation(dist, dist.count) }}
                </td>
                <td class="text-center">
                  {{ getMetExpectation(dist, dist.count) }}
                </td>
                <td class="text-center">
                  {{ getBelowExpectation(dist, dist.count) }}
                </td>
                <td class="text-center">
                  {{ getNeedsImprovement(dist, dist.count) }}
                </td>
              </tr>
              <tr>
                <td class="text-center font-weight-bold">Total</td>
                <td class="text-center font-weight-bold">
                  {{ getTotalAssessments() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalCompleted() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalOutstanding() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalExceededExpectation() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalMetExpectation() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalBelowExpectation() }}
                </td>
                <td class="text-center font-weight-bold">
                  {{ getTotalNeedsImprovement() }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceManagementDistribution",
  props: {
    boardId: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    ratings: {
      type: Object,
      required: true,
    },
    visibleList: {
      type: Array,
      required: true,
    },
    // Key that shold be used for computing distribution
    display: {
      type: String,
      required: true,
    },
    showHelpText: {
      type: Boolean,
    },
    vpMapping: {
      // Mapping of employee to VP
      // Use to group people by VP
      type: Object,
    },
    changedManagers: {
      // Mapping of employee to manager
      // Use to group people by manager
      type: Object,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),

    distribution() {
      const isVpListAvailable =
        this.vpMapping && Object.keys(this.vpMapping).length > 0;

      let _ratings = this._.map(this.visibleList, (pid) => {
        // Get department
        if (!this.employeesByPersonId[pid]) return;
        let department = this.employeesByPersonId[pid].employeeData.department;
        if (department.split("-").length > 1) {
          department = department.split("-")[0].trim();
        }

        const ret = {
          department,
        };

        // If VP mapping is not null, find the VP responsible for this employee
        if (isVpListAvailable) {
          const vpPersonId = this.vpMapping[pid];
          ret.vpResponsible = this.employeesByPersonId[vpPersonId]
            ? this.employeesByPersonId[vpPersonId].name
            : "";
        }

        if (this.display === "finalRating") {
          // Find the manager responsible for rating
          ret.managerResponsible = "";

          if (this.changedManagers[pid]) {
            ret.managerResponsible = this.changedManagers[pid].name;
          }

          if (
            !ret.managerResponsible ||
            ret.managerResponsible === "undefined" ||
            ret.managerResponsible === ""
          ) {
            const _pid = this.employeesByPersonId[pid].managers[0];
            if (this.employeesByPersonId[_pid])
              ret.managerResponsible = this.employeesByPersonId[_pid].name;
          }
        }

        if (this.ratings[pid]) {
          const rating = this.ratings[pid][0];
          let _rating = rating[this.display] ? rating[this.display] : "Pending";
          // If we are looking at VP rating and the rating is null
          // check if the VP has approved the rating. In which case
          // we use manager's rating.
          if (
            this.display === "VPRating" &&
            !rating[this.display] &&
            rating.VPApproved
          ) {
            // Final rating is the manager's rating. (It's weird and a design error)
            _rating = rating["finalRating"];
          }

          ret.rating = _rating;

          return ret;
        }

        ret.person = this.employeesByPersonId[pid].name;
        ret.rating = "Pending";
        return ret;
      });

      _ratings = this._.compact(_ratings);

      let groupedByDepartment = this._.groupBy(_ratings, "department");
      if (this.display === "VPRating") {
        // Group by VP instead of department in this case
        groupedByDepartment = this._.groupBy(_ratings, "vpResponsible");
      } else if (this.display === "finalRating") {
        groupedByDepartment = this._.groupBy(_ratings, "managerResponsible");
      }

      const distributionByDepartment = this._.map(
        groupedByDepartment,
        (dep, department) => {
          return {
            distribution: this._.countBy(dep, "rating"),
            count: dep.length,
            department,
          };
        }
      );
      return distributionByDepartment;
    },

    totalCount() {
      return this.visibleList.length;
    },
  },
  watch: {},
  mounted() {
    console.log("PerformanceManagementDistribution");
  },
  methods: {
    ...mapActions([]),
    getDepartment(dist) {
      if (dist.department === "undefined") return "";
      return dist.department ? dist.department : "";
    },
    getCompletionRate(dist, count) {
      if (!dist.distribution["Pending"]) return "100%";
      return `${parseInt((1 - dist.distribution["Pending"] / count) * 100)}% (${
        count - dist.distribution["Pending"]
      }/${count})`;
    },
    getOutstanding(dist, count) {
      if (!dist.distribution["Outstanding"]) return 0;
      return `${parseInt((dist.distribution["Outstanding"] / count) * 100)}% (${
        dist.distribution["Outstanding"]
      }/${count})`;
    },
    getExceededExpectation(dist, count) {
      if (!dist.distribution["Exceeded Expectation"]) return 0;
      return `${parseInt(
        (dist.distribution["Exceeded Expectation"] / count) * 100
      )}% (${dist.distribution["Exceeded Expectation"]}/${count})`;
    },
    getMetExpectation(dist, count) {
      if (!dist.distribution["Met Expectation"]) return 0;
      return `${parseInt(
        (dist.distribution["Met Expectation"] / count) * 100
      )}% (${dist.distribution["Met Expectation"]}/${count})`;
    },
    getBelowExpectation(dist, count) {
      if (!dist.distribution["Below Expectation"]) return 0;
      return `${parseInt(
        (dist.distribution["Below Expectation"] / count) * 100
      )}% (${dist.distribution["Below Expectation"]}/${count})`;
    },
    getNeedsImprovement(dist, count) {
      if (!dist.distribution["Needs Improvement"]) return 0;
      return `${parseInt(
        (dist.distribution["Needs Improvement"] / count) * 100
      )}% (${dist.distribution["Needs Improvement"]}/${count})`;
    },
    getTotalAssessments() {
      console.log(this.visibleList);
      return this.visibleList.length;
    },
    getTotalCompleted() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (!this.ratings[pid]) return false;
        if (!this.ratings[pid][0]) return false;
        // return this.ratings[pid][0][this.display] ? true : false;

        let rating = this.ratings[pid][0];
        if (
          this.display === "VPRating" &&
          !rating[this.display] &&
          rating.VPApproved
        ) {
          // Final rating is the manager's rating. (It's weird and a design error)
          rating = rating["finalRating"];
        } else {
          rating = rating[this.display];
        }
        return rating ? true : false;
      });

      const percent = (counts.true / this.visibleList.length) * 100;

      if (!percent) return "--";
      return `${percent.toFixed(2)}% (${counts.true}/${this._.sum(
        this._.values(counts)
      )})`;
    },
    getTotalOutstanding() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (this.ratings[pid] && this.ratings[pid][0][this.display]) {
          return this.ratings[pid][0][this.display] === "Outstanding";
        }

        if (!this.ratings[pid]) return false;
        let rating = this.ratings[pid][0];
        if (this.display === "VPRating" && rating.VPApproved) {
          return rating["finalRating"] === "Outstanding";
        }
      });

      let percent = (counts.true / this.visibleList.length) * 100;
      percent = percent ? percent : 0;
      const count = counts.true ? counts.true : 0;
      return `${percent.toFixed(2)}% (${count}/${this.visibleList.length})`;
    },
    getTotalExceededExpectation() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (this.ratings[pid] && this.ratings[pid][0][this.display])
          return this.ratings[pid]
            ? this.ratings[pid][0][this.display] === "Exceeded Expectation"
            : false;

        if (!this.ratings[pid]) return false;
        let rating = this.ratings[pid][0];
        if (
          this.display === "VPRating" &&
          !rating[this.display] &&
          rating.VPApproved
        ) {
          return rating
            ? rating["finalRating"] === "Exceeded Expectation"
            : false;
        }
      });

      let percent = (counts.true / this.visibleList.length) * 100;
      percent = percent ? percent : 0;
      const count = counts.true ? counts.true : 0;
      return `${percent.toFixed(2)}% (${count}/${this.visibleList.length})`;
    },
    getTotalMetExpectation() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (this.ratings[pid] && this.ratings[pid][0][this.display])
          return this.ratings[pid]
            ? this.ratings[pid][0][this.display] === "Met Expectation"
            : false;

        if (!this.ratings[pid]) return false;
        let rating = this.ratings[pid][0];
        if (
          this.display === "VPRating" &&
          !rating[this.display] &&
          rating.VPApproved
        ) {
          return rating ? rating["finalRating"] === "Met Expectation" : false;
        }
      });

      let percent = (counts.true / this.visibleList.length) * 100;
      percent = percent ? percent : 0;
      const count = counts.true ? counts.true : 0;
      return `${percent.toFixed(2)}% (${count}/${this.visibleList.length})`;
    },

    getTotalBelowExpectation() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (this.ratings[pid] && this.ratings[pid][0][this.display])
          return this.ratings[pid]
            ? this.ratings[pid][0][this.display] === "Below Expectation"
            : false;

        if (!this.ratings[pid]) return false;
        let rating = this.ratings[pid][0];
        if (
          this.display === "VPRating" &&
          !rating[this.display] &&
          rating.VPApproved
        ) {
          return rating ? rating["finalRating"] === "Below Expectation" : false;
        }
      });

      let percent = (counts.true / this.visibleList.length) * 100;
      percent = percent ? percent : 0;
      const count = counts.true ? counts.true : 0;
      return `${percent.toFixed(2)}% (${count}/${this.visibleList.length})`;
    },

    getTotalNeedsImprovement() {
      const counts = this._.countBy(this.visibleList, (pid) => {
        if (this.ratings[pid] && this.ratings[pid][0][this.display])
          return this.ratings[pid]
            ? this.ratings[pid][0][this.display] === "Needs Improvement"
            : false;

        if (!this.ratings[pid]) return false;
        let rating = this.ratings[pid][0];
        if (
          this.display === "VPRating" &&
          !rating[this.display] &&
          rating.VPApproved
        ) {
          return rating ? rating["finalRating"] === "Needs Improvement" : false;
        }
      });

      let percent = (counts.true / this.visibleList.length) * 100;
      percent = percent ? percent : 0;
      const count = counts.true ? counts.true : 0;
      return `${percent.toFixed(2)}% (${count}/${this.visibleList.length})`;
    },
  },
};
</script>
