<template>
  <div class="pa-12">
    <v-row class="mt-6">
      <v-col cols="12" md="12"
        ><h1>Review submitted forms</h1>
        <!-- <p v-if="isManagerAccess && !showManagerCalibration">
          Manager calibration is closed
        </p> -->

        <div v-if="templateName">
          <h3 class="mt-4">Viewing {{ templateName }}</h3>

          <p v-if="!isManagerAccess" class="mt-4">
            For manager's calibration view, please share
            <a
              :href="`/#/PerformanceReviewManagerView/${boardId}/${templateId}`"
            >
              http://alpha.agentnoon.com/#/PerformanceReviewManagerView/{{
                boardId
              }}/{{ templateId }}</a
            >
          </p>

          <p v-if="!isManagerAccess" class="mt-4">
            For VP's calibration view, please share
            <a
              :href="`/#/PerformanceReviewVPApproval/${boardId}/${templateId}?vpm=1`"
            >
              http://alpha.agentnoon.com/#/PerformanceReviewVPApproval/{{
                boardId
              }}/{{ templateId }}?vpm=1</a
            >
          </p>

          <p v-if="!isManagerAccess" class="mt-4">
            To show an employee's final rating, please share
            <a
              :href="`/#/PerformanceReviewRatingOnly/${boardId}/${templateId}`"
            >
              http://alpha.agentnoon.com/#/PerformanceReviewRatingOnly/{{
                boardId
              }}/{{ templateId }}</a
            >
          </p>
        </div>
      </v-col>
    </v-row>

    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="af_blue"
    ></v-progress-linear>

    <!-- <v-row v-if="!isLoading && answers.length > 0 && isManagerAccess">
      <v-col cols="12" md="6">
        <PerformanceManagementDistribution
          :ratings="answersByPersonId"
          :visibleList="orderedSentList"
          :boardId="boardId"
          :templateId="templateId"
          :showHelpText="true"
          :changedManagers="changedManagers"
          display="finalRating"
          class="my-6"
        />
      </v-col>
    </v-row> -->

    <!-- <v-btn
      v-if="!isManagerAccess"
      @click="showDistribution = !showDistribution"
      class="my-6"
      >Distribution</v-btn
    > -->
    <!-- <v-row
      v-if="
        !isLoading && answers.length > 0 && !isManagerAccess && showDistribution
      "
    >
      <v-col cols="12" md="6">
        <p>Manager Rating Distribution</p>
        <PerformanceManagementDistribution
          :ratings="answersByPersonId"
          :visibleList="orderedSentList"
          :boardId="boardId"
          :templateId="templateId"
          :showHelpText="false"
          :changedManagers="changedManagers"
          display="finalRating"
          class="my-6"
        />
      </v-col>

      <v-col cols="12" md="6">
        <p>VP Rating Distribution</p>
        <PerformanceManagementDistribution
          :ratings="answersByPersonId"
          :visibleList="orderedSentList"
          :boardId="boardId"
          :templateId="templateId"
          :showHelpText="false"
          display="VPRating"
          :vpMapping="vpMapping"
          class="my-6"
        />
      </v-col>
    </v-row> -->

    <v-row v-if="!isManagerAccess" no-gutters class="my-6">
      <v-col cols="12" md="12" class="d-flex align-center">
        <v-text-field
          v-model="filterInput"
          dense
          label="Filter by manager's name or department"
          class="my-6 ml-2"
          hide-details
          append-icon="mdi-filter"
          @click:append="filter()"
          v-on:keyup.enter="filter()"
        />
      </v-col>

      <v-col cols="12" md="12" class="d-flex justify-end align-center">
        <v-btn
          class="mx-2"
          outlined
          color="af_green"
          @click="remindManagers"
          :loading="isLoading"
          disabled
          >Send Evaluation Request To Manager
          <v-icon small class="ml-1">mdi-send</v-icon></v-btn
        >

        <v-btn
          class="mx-2"
          outlined
          @click="sendToAllManagers"
          :loading="isLoading"
          disabled
          >Send Message To All Managers
          <v-icon small class="ml-1">mdi-send</v-icon></v-btn
        >

        <VP_CSVImport :loading="isLoading" @imported="importVPMapping()" />

        <PerformanceReviewImportCEORating :loading="isLoading" />

        <!-- <v-btn class="mx-2" outlined @click="csvExport" :loading="csvExporting"
          >CSV Export</v-btn
        > -->
      </v-col></v-row
    >

    <v-row v-if="!isManagerAccess" class="mt-8 mb-4">
      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">Employee Responses</p>
          <h1>{{ employeeResponseRate }}%</h1>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">Manager Ratings</p>
          <h1>{{ managerRatingRate }}%</h1>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">VP Rating</p>
          <h1>{{ VPRatingRate }}%</h1>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-4">
      <v-col cols="12">
        <v-card class="card-shadow px-4 pb-4">
          <v-simple-table class="mt-0 pt-0" dense>
            <template v-slot:default>
              <thead style="height: 80px">
                <th>Name</th>
                <th>Department</th>
                <th style="height: 140px; min-width: 200px">Senior Leader</th>
                <th v-if="!isManagerAccess">Manager</th>
                <th style="height: 140px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        >Evaluating Manager
                        <v-icon color="grey lighten-1" small>
                          mdi-help
                        </v-icon></span
                      >
                    </template>
                    <span>
                      Based on HR policy, the evaluating manager can be
                      different from the current manager
                    </span>
                  </v-tooltip>
                </th>
                <th>Self-Review</th>
                <th>Manager</th>
                <!-- <th v-if="isPerformanceReview">Calibrated Rating</th> -->
                <th v-if="isVPMode">VP Edits</th>
                <th v-if="!isManagerAccess && isPerformanceReview">
                  Sr. Leader
                </th>
                <th v-if="!isManagerAccess && isPerformanceReview">CEO</th>
                <th>Final</th>
                <th v-if="!isManagerAccess"></th>
              </thead>
              <tbody v-if="changedManagerFetched">
                <tr v-for="personId in filteredOrderedList" :key="personId">
                  <td class="text-truncate">
                    <p class="body-1 ma-0 pa-0">
                      {{ employeesByPersonId[personId].name }},
                      {{ employeesByPersonId[personId].role }}
                    </p>
                  </td>

                  <td class="text-center" style="width: 200px">
                    <v-chip
                      class="white--text ma-0"
                      dense
                      small
                      :color="
                        getDepartmentColor(
                          employeesByPersonId[personId].employeeData.department
                        )
                      "
                    >
                      {{
                        employeesByPersonId[personId].employeeData.department
                      }}
                      <!-- {{ employeesByPersonId[personId].officeLocation }} -->
                    </v-chip>
                  </td>
                  <td>
                    <!-- <v-combobox
                      v-if="!isManagerAccess"
                      dense
                      solo
                      elevation="0"
                      class="ma-0"
                      :items="vps"
                      hide-details
                      v-model="vpMapping[personId]"
                      @change="onVPchange($event, personId)"
                      style="min-width: 200px"
                      disabled
                    >
                      <template v-slot:selection="data">
                        <p class="body-1 ma-0 pa-0">
                          {{ getName(data.item) }}
                        </p>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="`${getName(item)}, ${getRole(item)}`"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="
                              `${getDepartment(item)} ${getLocation(item)}`
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox> -->
                    <p class="body-1 ma-0">
                      <span v-if="employeesByPersonId[vpMapping[personId]]">{{
                        employeesByPersonId[vpMapping[personId]].name
                      }}</span>
                    </p>
                  </td>

                  <td>
                    <span
                      v-if="
                        employeesByPersonId[
                          employeesByPersonId[personId].managers[0]
                        ] && changedManagerFetched
                      "
                    >
                      <p v-if="isManagerAccess" class="body-1 ma-0">
                        <span v-if="changedManagers[personId]">
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}
                          <v-icon small>mdi-chevron-right</v-icon>
                          {{ changedManagers[personId].name }}
                        </span>
                        <span v-else>
                          {{
                            employeesByPersonId[
                              employeesByPersonId[personId].managers[0]
                            ].name
                          }}</span
                        >
                      </p>
                      <p v-else class="body-1 ma-0">
                        {{
                          employeesByPersonId[
                            employeesByPersonId[personId].managers[0]
                          ].name
                        }}
                      </p>
                    </span>
                  </td>

                  <td v-if="!isManagerAccess">
                    <!-- :ref="`${personId}_evaluating_manager`" -->
                    <v-combobox
                      v-if="
                        changedManagerFetched // We toggle this ON when the manager data is fetched
                      "
                      v-model="changedManagers[personId]"
                      @change="onManagerchange($event, personId)"
                      dense
                      solo
                      elevation="0"
                      :filter="queryManager"
                      :items="managers"
                      hide-details
                    >
                      <template v-slot:selection="data">
                        <p class="body-1 ma-0 pa-0">
                          {{ getName(data.item.personId) }}
                        </p>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              `${getName(item.personId)}, ${getRole(
                                item.personId
                              )}`
                            "
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="
                              `${getDepartment(item.personId)} ${getLocation(
                                item.personId
                              )}`
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </td>

                  <td class="text-center">
                    <p
                      v-if="isVPMode && answersByPersonId[personId]"
                      class="body-1 ma-0"
                    >
                      Completed
                    </p>
                    <v-btn
                      v-if="
                        answersByPersonId[personId] &&
                        !isVPMode &&
                        showManagerCalibration
                      "
                      class="ma-0 blue--text text-capitalize"
                      small
                      text
                      dense
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}`"
                      target="_blank"
                    >
                      See Full Response
                      <span v-if="!isManagerAccess"
                        >({{
                          getSumOfPercentage(answersByPersonId[personId][0])
                        }}%)</span
                      >
                    </v-btn>
                    <v-btn
                      v-if="
                        answersByPersonId[personId] &&
                        !isVPMode &&
                        !showManagerCalibration
                      "
                      class="ma-0 blue--text text-capitalize"
                      small
                      text
                      dense
                      :to="`/PerformanceReviewReadOnly/${boardId}/${templateId}/${personId}`"
                      target="_blank"
                    >
                      See Full Response
                      <span v-if="!isManagerAccess"
                        >({{
                          getSumOfPercentage(answersByPersonId[personId][0])
                        }}%)</span
                      >
                    </v-btn>

                    <v-btn
                      v-if="!answersByPersonId[personId]"
                      class="body-1 ma-0"
                      small
                      dense
                      disabled
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}`"
                    >
                      Pending
                    </v-btn>
                  </td>
                  <td>
                    <v-btn
                      v-if="answersByPersonId[personId]"
                      :disabled="!showManagerCalibration"
                      text
                      dense
                      class="ma-0 blue--text text-capitalize"
                      :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}`"
                      target="_blank"
                    >
                      {{ answersByPersonId[personId][0].finalRating }}</v-btn
                    >
                  </td>
                  <td v-if="!isManagerAccess">
                    <div class="d-flex flex-column justify-center">
                      <p v-if="answersByPersonId[personId]" class="body-1 ma-0">
                        {{ answersByPersonId[personId][0].VPRating }}
                      </p>

                      <span
                        v-if="
                          answersByPersonId[personId] &&
                          answersByPersonId[personId][0].VPApproved
                        "
                      >
                        <v-icon small color="af_green" class="mt-1"
                          >mdi-check-decagram-outline</v-icon
                        >

                        {{ answersByPersonId[personId][0].approvedBy }}
                      </span>
                    </div>
                  </td>

                  <td v-if="!isManagerAccess">
                    <p
                      v-if="answersByPersonId[personId]"
                      class="body-1 ma-0 font-weight-bold"
                    >
                      {{ answersByPersonId[personId][0].CEORating }}
                    </p>
                  </td>

                  <td>
                    <p
                      v-if="answersByPersonId[personId]"
                      class="body-1 ma-0 font-weight-bold"
                    >
                      {{ getFinalRating(answersByPersonId[personId]) }}
                    </p>
                  </td>

                  <td v-if="!isManagerAccess">
                    <div class="d-flex">
                      <v-btn
                        v-if="answersByPersonId[personId]"
                        text
                        dense
                        class="ma-0 green--text text-capitalize"
                        :to="`/PerformanceReviewResponse/${boardId}/${templateId}/${personId}`"
                        target="_blank"
                      >
                        <span v-if="answersByPersonId[personId][0].adminRating">
                          {{ answersByPersonId[personId][0].adminRating }}</span
                        >
                        <span v-else>Edit</span>
                      </v-btn>
                      <v-btn
                        icon
                        :to="`/PerformanceReviewPrint/${boardId}/${templateId}/${personId}`"
                        target="_blank"
                      >
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </div>
                  </td>

                  <td v-if="!isManagerAccess" class="text-center">
                    <PerformanceReviewRemovePersonConfirmation
                      v-if="!isManagerAccess"
                      :employee="employeesByPersonId[personId]"
                      :templateName="templateName"
                      @done="init()"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { OutlineColors } from "@/lib/Colors";
import VP_CSVImport from "@/components/Calibration/VP_CSVImport";
import PerformanceReviewImportCEORating from "@/components/Calibration/PerformanceReviewImportCEORating";
import PerformanceReviewRemovePersonConfirmation from "@/components/Calibration/PerformanceReviewRemovePersonConfirmation";
// import PerformanceManagementDistribution from "@/components/Calibration/PerformanceManagementDistribution";
// import { AccessCheck } from "@/lib/Access";

import { json2csvAsync } from "json-2-csv";

import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "PerformanceReviewManagerReviewTable",
  components: {
    VP_CSVImport,
    PerformanceReviewRemovePersonConfirmation,
    // PerformanceManagementDistribution,
    PerformanceReviewImportCEORating,
  },
  data: () => ({
    // Use this array to sort the order of display
    roleSortOrder: [
      "noManager",
      "ceo",
      "cto",
      "coo",
      "cpo",
      "chro",
      "cfo",
      "chief",
      "vp",
      "director",
      "senior",
      "sr",
    ],
    showDistribution: false,
    vps: [],
    templateName: "",
    filterInput: "",
    filterQuery: "",
    searchQuery: "",
    departments: [], // array of uniq department values
    selectedEmployees: [], // array of personIds that will receive performance review
    showTeamList: [], // Array of manager's personId that indicates if the team table should be expanded or collapsed
    sent: [], // Array of personIds that indicates who received the performance review

    receivers: null, // The full recevier doc from Firestore
    answers: [],
    answersByPersonId: {},

    vpMapping: {}, // Mapping of employees to VPs
    isVPMode: false,

    // For employees that should have a different evaluation manager compared to default.
    changedManagers: {},
    changedManagerSubordinates: [],
    changedManagerFetched: false,
    csvExporting: false,
    isLoading: true,
    showManagerCalibration: true,
    showTeamRatings: true,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    board() {
      return this._.find(this.$store.state.board.boards, {
        boardId: this.boardId,
      });
    },
    templateId() {
      return this.$route.params.templateId;
    },
    isPerformanceReview() {
      if (this.templateName.includes("Performance")) {
        return true;
      }
    },
    isManagerAccess() {
      return this.$route.name === "PerformanceReviewManagerView";
    },
    changedManagerList() {
      // Identify new evaluating manager

      const evaluatingManager = this._.filter(
        this.changedManagers,
        (evaluatingManager) => {
          return evaluatingManager.personId === this.searchQuery;
        }
      );

      let changedManagerSubordinates = [];

      this._.each(evaluatingManager, (e) => {
        const target = e.employeePersonId;

        // console.log(
        //   "Add",
        //   this.employeesByPersonId[target].name,
        //   "team to the list"
        // );
        // If this is true, then we should let all the subordinates of this person also visible
        let myTeam = [];
        const listAllSubordinates = (_target) => {
          if (!_target) return;
          myTeam.push(_target.personId);
          this._.map(_target.subordinates, (_pid) => {
            listAllSubordinates(this.employeesByPersonId[_pid]);
          });
        };

        listAllSubordinates(this.employeesByPersonId[target]);

        // Remove myself. Since I should not be allow to see my own performance
        const k = myTeam.indexOf(this.searchQuery);
        if (k > -1) myTeam.splice(k, 1);

        changedManagerSubordinates = changedManagerSubordinates.concat(myTeam);
      });

      return changedManagerSubordinates;
    },
    orderedSentList() {
      return this._.chain(this.sent)
        .sortBy((personId) => {
          return this.employeesByPersonId[personId]?.employeeData?.department;
        })
        .sortBy((personId) => {
          return this.employeesByPersonId[personId]?.managers[0];
        })
        .filter((personId) => {
          if (!this.employeesByPersonId[personId]) return true;
          if (!this.searchQuery && !this.isManagerAccess) return true;
          if (!this.searchQuery && this.isManagerAccess) return false;

          // I should not be able to see my own assessment.
          if (this.myPersonId(this.boardId) === personId) {
            return false;
          }

          const targets = [
            this.employeesByPersonId[personId].employeeData.department,
          ];

          const managersId = this.employeesByPersonId[personId].managers[0];

          if (managersId) {
            targets.push(this.employeesByPersonId[managersId].name);
          }

          if (this.isManagerAccess && this.searchQuery) {
            console.log(this.isManagerAccess, this.searchQuery);
            // Check if the current personId is a report of the VP.
            // If yes, the VP should be able to see this person's assessment
            // I am a manager. I need to check if I am access my own team
            let myTeam = [];
            const listAllSubordinates = (target) => {
              if (!target) return;
              myTeam.push(target.personId);
              this._.map(target.subordinates, (_pid) => {
                listAllSubordinates(this.employeesByPersonId[_pid]);
              });
            };

            listAllSubordinates(this.employeesByPersonId[this.searchQuery]);
            // Remove myself. Since I should not be allow to see my own performance
            const k = myTeam.indexOf(this.searchQuery);
            if (k > -1) myTeam.splice(k, 1);

            if (myTeam.includes(personId)) {
              return true;
            }
          }

          return this._.some(targets, (t) => {
            if (!t) return true;
            return t.toLowerCase().includes(this.searchQuery.toLowerCase());
          });
        })
        .value();
    },
    filteredOrderedList() {
      const returnList = this._.uniq(
        this.changedManagerList.concat(this.orderedSentList)
      );
      console.log(returnList);
      // Return the ordered list items that are filtered
      return this._.filter(returnList, (pid) => {
        if (!this.employeesByPersonId[pid]) {
          return false;
        }
        const managerPid = this.employeesByPersonId[pid].managers[0];
        const targets = [];

        if (
          this.employeesByPersonId[managerPid] &&
          this.employeesByPersonId[managerPid].name
        )
          targets.push(this.employeesByPersonId[managerPid].name);
        if (
          this.employeesByPersonId[managerPid] &&
          this.employeesByPersonId[managerPid].employeeData.department
        )
          targets.push(
            this.employeesByPersonId[managerPid].employeeData.department
          );

        // This is China HR director. Only show employee from China

        if (
          [
            "minyan.yang@sayweee.com",
            "rong.jin@sayweee.com",
            "yang.zhang@sayweee.com",
          ].includes(this.user.email)
        ) {
          // This user should only see the employees from China
          return ["Shanghai", "Xian", "Wuhan", "Nanjing"].includes(
            this.employeesByPersonId[pid].officeLocation
          );
        }

        return this._.some(targets, (t) => {
          return t.toLowerCase().includes(this.filterQuery.toLowerCase());
        });
      });
    },
    employeeResponseRate() {
      if (!this.orderedSentList.length) return 0;
      if (!this.answers || !this.answers.length) return 0;

      const noAnswerCount = this.orderedSentList.length - this.answers.length;

      return parseInt(
        ((this.orderedSentList.length - noAnswerCount) /
          this.orderedSentList.length) *
          100
      );
    },
    managerRatingRate() {
      if (!this.orderedSentList.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].finalRating;
      });

      return parseInt((rated.length / this.orderedSentList.length) * 100);
    },
    VPRatingRate() {
      if (!this.orderedSentList.length) return 0;
      if (!this.answers || !this.answers.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].VPRating || answers[0].VPApproved;
      });

      return parseInt((rated.length / this.orderedSentList.length) * 100);
    },
    managers() {
      // List of people that have a team
      return this._.chain(this.activeEmployees)
        .filter((person) => {
          return person.role && person.name && person.email;
        })
        .map((person) => {
          return { personId: person.personId, name: person.name };
        })
        .value();
    },
  },
  watch: {
    searchQuery: function () {},
    groupPeopleByManager: function () {
      this.departments = this._.chain(this.people)
        .map((p) => {
          return p.employeeData.department.split("-")[0].trim();
        })
        .uniq()
        .sortBy((t) => {
          // This ensures that the color code stays (kinda...)
          return t;
        })
        .value();
    },
  },

  mounted() {
    this.isVPMode = false;
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchPeople",
      "fetchPerformanceReviewReceivers",
      "fetchAllPerformanceReviewAnswers",
      "fetchPerformanceReviewTemplate",
      "saveChangedManagers",
      "readChangedManagers",
      "fetchSetting",
      "getVPMapping",
    ]),
    async init() {
      console.log("Is Manager View", this.isManagerAccess);

      const settings = await this.fetchSetting({
        boardId: this.boardId,
      });

      if (settings)
        this.showManagerCalibration = settings?.showManagerCalibration;

      this.csvExporting = true;
      await this.fetchPeople({ boardId: this.boardId });

      // If this is a manager view, then only show the team under management

      if (this.isManagerAccess && this.myPersonId(this.boardId)) {
        this.searchQuery = this.myPersonId(this.boardId);
      }

      if (
        this.isManagerAccess &&
        !this.showTeamRatings &&
        !this.showManagerCalibration
      ) {
        // Manager access is closed.
        this.isLoading = false;
        return;
      }

      this.receviers = await this.fetchPerformanceReviewReceivers({
        boardId: this.boardId,
        templateId: this.templateId,
      });
      this.sent = this.receviers.sent;

      await this.fetchAnswers();

      this._.delay(() => {
        this.csvExporting = false;
      }, 3000);

      // Fetch the name of the template
      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.templateName = ret.name;

      const changedManagersObj = await this.readChangedManagers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      if (changedManagersObj) {
        // Convert the Firebase data into changedManager Object
        this._.each(changedManagersObj.changedManagerArray, (obj) => {
          const personId = obj.targetPersonId;

          this.changedManagers[personId] = {
            personId: obj.managerPersonId,
            name: obj.managerName,
            employeePersonId: personId,
          };
          // console.log(this.$refs[`${personId}_evaluating_manager`]);
          // this.$refs[`${personId}_evaluating_manager`][0].value =
          //   this.changedManagers[personId];
        });
      }

      await this.importVPMapping();

      this.changedManagerFetched = true;
      this.isLoading = false;
    },
    getDepartmentColor(department) {
      return OutlineColors[
        this.departments.indexOf(department.split("-")[0].trim())
      ];
    },
    filter() {
      this.filterQuery = this.filterInput;
    },

    queryManager(person, queryText) {
      const personId = person.personId;

      if (
        !this.employeesByPersonId[personId] ||
        !this.employeesByPersonId[personId].name
      )
        return false;
      return (
        this.employeesByPersonId[personId].name
          .toLowerCase()
          .includes(queryText) ||
        this.employeesByPersonId[personId].role
          .toLowerCase()
          .includes(queryText)
      );
    },
    getName(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.name : "";
    },

    getRole(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.role : "";
    },
    getDepartment(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.employeeData.department : "No Department";
    },
    getLocation(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.officeLocation : "No Location";
    },

    onManagerchange(value, personId) {
      // Convert the changedManagers object to an easier format to save and understand
      this.changedManagers[personId] = value;

      const _changedManagers = this._.chain(this.changedManagers)
        .map((managerObj, targetPersonId) => {
          if (!managerObj) return null;
          const managerName = managerObj.name;
          const managerPersonId = managerObj.personId;

          return { targetPersonId, managerName, managerPersonId };
        })
        .filter((managerObj) => {
          return managerObj;
        })
        .value();

      this.saveChangedManagers({
        boardId: this.boardId,
        templateId: this.templateId,
        changedManagerArray: _changedManagers,
      });
    },
    onVPchange(VPpersonId, personId) {
      console.log(this.employeesByPersonId[VPpersonId].name, personId);

      // this._.each(vpmapping.mapping, (map) => {
      //   this.vpMapping[map.personId] = map.vpPersonId;
      // });

      // Convert the vpMapping to setVPMapping friendly format and save.
      // console.log(
      //   this.vpMapping[personId],
      //   this.employeesByPersonId[personId].name
      // );
      // console.log(
      //   this.vpMapping[VPpersonId],
      //   this.employeesByPersonId[VPpersonId].name
      // );
      // this._.each(this.vpMapping, (v, k) => {
      //   console.log(v, k);
      // });
    },

    async fetchAnswers() {
      this.answers = await this.fetchAllPerformanceReviewAnswers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.answersByPersonId = this._.groupBy(this.answers, (ans) => {
        return ans.revieweePersonId;
      });
    },
    remindManagers() {
      const answerDict = this._.keyBy(this.answers, "revieweePersonId");
      // Get the list of managers to send out the reminder email to

      let toBeRemindedManagers = this._.chain(this.orderedSentList)
        .filter((personId) => {
          // We only send out the reminder to the uncompleted ones.
          return answerDict[personId] ? true : false;
        })
        .map((personId) => {
          // Find the manager of the recipient

          if (this.employeesByPersonId[personId].managers.length == 0) {
            // If the manager array is empty, try to find the manager
            // This happens on first load for one employee out from hundreds
            // No root cause has been identified
            let manager = this._.find(this.activeEmployees, (emp) => {
              return emp.subordinates.includes(personId);
            });
            return manager.personId;
          }
          return this.employeesByPersonId[personId].managers[0];
        })
        .uniq()
        .map((personId) => {
          let name = this.employeesByPersonId[personId].name;
          if (name.includes(",")) {
            name = name.split(",")[1].trim();
          }
          if (name.trim().includes(" ")) {
            name = name.split(" ")[0];
          }
          return {
            name,
            email: this.employeesByPersonId[personId].email,
          };
        })
        .value();

      this._.each(toBeRemindedManagers, async (obj) => {
        var remind = firebase
          .functions()
          .httpsCallable("performanceReview-managerNotification");
        if (obj.email.includes("anawab.com")) return; // Let's not spam ali while testing.
        console.log(obj);
        const ret = await remind({
          boardId: this.boardId,
          templateId: this.templateId,
          name: obj.name,
          email: obj.email,
        });
        console.log(ret);
      });
    },
    sendToAllManagers() {
      // const answerDict = this._.keyBy(this.answers, "revieweePersonId");
      // Get the list of managers to send out the reminder email to

      let toBeRemindedManagers = this._.chain(this.orderedSentList)
        .map((personId) => {
          // Find the manager of the recipient

          if (this.employeesByPersonId[personId].managers.length == 0) {
            // If the manager array is empty, try to find the manager
            // This happens on first load for one employee out from hundreds
            // No root cause has been identified
            let manager = this._.find(this.activeEmployees, (emp) => {
              return emp.subordinates.includes(personId);
            });
            return manager.personId;
          }
          return this.employeesByPersonId[personId].managers[0];
        })
        .uniq()
        .map((personId) => {
          let name = this.employeesByPersonId[personId].name;
          if (name.includes(",")) {
            name = name.split(",")[1].trim();
          }
          if (name.trim().includes(" ")) {
            name = name.split(" ")[0];
          }
          return {
            name,
            email: this.employeesByPersonId[personId].email,
          };
        })
        .value();

      this._.each(toBeRemindedManagers, async (obj) => {
        var remind = firebase
          .functions()
          .httpsCallable("performanceReview-managerNotification");
        if (obj.email.includes("anawab.com")) return; // Let's not spam ali while testing.
        console.log(obj);
        const ret = await remind({
          boardId: this.boardId,
          templateId: this.templateId,
          name: obj.name,
          email: obj.email,
        });
        console.log(ret);
      });
    },
    async csvExport() {
      this.csvExporting = true;

      const answerDict = this._.keyBy(this.answers, "revieweePersonId");
      // console.log(answerDict);

      // Create a JSON representation of what we want to export.
      const exportJSON = this._.map(this.orderedSentList, (pid) => {
        const ans = answerDict[pid];

        if (ans) {
          const copy = this._.cloneDeep(ans);

          let managerPersonId =
            this.employeesByPersonId[ans.revieweePersonId].managers[0];
          let manager = this.employeesByPersonId[managerPersonId];

          copy.manager = manager ? manager.name : "";
          copy.department =
            this.employeesByPersonId[
              ans.revieweePersonId
            ].employeeData.department;
          copy.status = "Responded";
          copy.email = this.employeesByPersonId[ans.revieweePersonId].email;

          delete copy.updatedAt;
          delete copy.updatedBy;
          delete copy.createdAt;
          delete copy.createdBy;
          delete copy.boardId;
          delete copy.templateId;
          delete copy.revieweePersonId;

          copy.evaluations = this._.map(copy.evaluations, (res) => {
            return `Category: ${res.category}\nAssessment:${
              res.assessment
            }\nPercent:${res.percentage ? res.percentage : "N/A"}%`;
          }).join("\n\n");

          copy.evaluations = copy.evaluations
            ? copy.evaluations.replace(new RegExp("<br/>", "g"), "\n")
            : "";
          copy.evaluations = copy.evaluations
            ? copy.evaluations.replace(new RegExp("#", "g"), "%23")
            : "";

          copy.responsibilities = this._.map(
            copy.responsibilities,
            (res, i) => {
              return `${i + 1}.Responsibility: ${res.responsibility}\nResult:${
                res.result
              }\nPercent:${res.percentage ? res.percentage : "N/A"}%`;
            }
          ).join("\n\n");

          copy.responsibilities = copy.responsibilities
            ? copy.responsibilities.replace(new RegExp("<br/>", "g"), "\n")
            : "";
          copy.responsibilities = copy.responsibilities
            ? copy.responsibilities.replace(new RegExp("#", "g"), "%23")
            : "";

          copy.selfEvaluation = copy.selfEvaluation
            ? copy.selfEvaluation.replace(new RegExp("<br/>", "g"), "\n")
            : "";
          copy.selfEvaluation = copy.selfEvaluation
            ? copy.selfEvaluation.replace(new RegExp("#", "g"), "%23")
            : "";
          // delete copy.responsibilities;
          // delete copy.evaluations;
          // delete copy.selfEvaluation;
          // delete copy.comment;
          // copy.comment = copy.comment ? copy.comment.replace(/"/g, "'") : "";
          copy.comment = copy.comment
            ? copy.comment.replace(new RegExp("<br/>", "g"), "\n")
            : "";

          copy.comment = copy.comment
            ? copy.comment.replace(new RegExp("#", "g"), "%23")
            : "";

          if (this.changedManagers[ans.revieweePersonId]) {
            const evaluatingManager =
              this.changedManagers[ans.revieweePersonId];
            copy.evaluatingManager =
              this.changedManagers[ans.revieweePersonId].name;
            if (
              this.employeesByPersonId[evaluatingManager.personId].importedData
            ) {
              copy.evaluatingManagerPositionId =
                this.employeesByPersonId[
                  evaluatingManager.personId
                ].importedData["Position ID"];
            }
          }

          // Final rating name is incorrect. It should be final rating.
          copy.managerRating = copy.finalRating;
          delete copy.finalRating;

          copy.VPRating = copy.VPRating ? copy.VPRating : "";

          // If VP's mapping exist, add VP's name to the row
          if (this.vpMapping[ans.revieweePersonId]) {
            const vpPersonId = this.vpMapping[ans.revieweePersonId];
            copy.VPName = this.employeesByPersonId[vpPersonId].name;
          }

          // Add Position ID if it exists
          if (
            this.employeesByPersonId[ans.revieweePersonId].importedData &&
            this.employeesByPersonId[ans.revieweePersonId].importedData[
              "Position ID"
            ]
          ) {
            copy.positionId =
              this.employeesByPersonId[ans.revieweePersonId].importedData[
                "Position ID"
              ];
          }

          if (this.employeesByPersonId[ans.revieweePersonId].importedData) {
            copy.HD =
              this.employeesByPersonId[ans.revieweePersonId].importedData?.HD;
            copy["HDIndex"] =
              this.employeesByPersonId[ans.revieweePersonId].importedData[
                "HD Index"
              ];
          }

          return copy;
        }

        let managerPersonId = this.employeesByPersonId[pid].managers[0];
        const manager = this.employeesByPersonId[managerPersonId]
          ? this.employeesByPersonId[managerPersonId].name
          : "";
        const emp = this.employeesByPersonId[pid];
        const department = emp ? emp.employeeData.department : "";
        let status = "Pending";
        let email = emp.email;
        let positionId = emp.importedData
          ? emp.importedData["Position ID"]
          : "";

        // Status,Email,Reviewee Name,Department,Manager,Comment,Manager Rating,VP Rating
        const HD = emp.importedData?.HD;
        let HDIndex = "";
        if (emp.importedData && emp.importedData["HD Index"])
          HDIndex = emp.importedData["HD Index"];

        return {
          manager,
          department,
          status,
          email,
          revieweeName: emp.name,
          positionId,
          HD,
          HDIndex,
        };
      });

      this.csvExporting = false;
      // _.startCase('helloThere');
      console.log(exportJSON);
      const _csv = await json2csvAsync(exportJSON, {
        emptyFieldValue: "",
        sortHeader: true,
      });
      console.log(_csv);
      let csvContent = "data:text/plain;charset=utf-8," + _csv;
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `Agentnoon Exports - ${this.templateName} ${this.$moment().format(
          "MMM DD, YYYY"
        )}.csv`
      );
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    async importVPMapping() {
      const vpmapping = await this.getVPMapping({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this._.each(vpmapping.mapping, (map) => {
        this.vpMapping[map.personId] = map.vpPersonId;
      });

      this.vps = this._.map(vpmapping.mapping, (map) => {
        return map.vpPersonId;
      });
    },
    getSumOfPercentage(ans) {
      const sum = this._.sumBy(ans.responsibilities, (res) => {
        return parseInt(res.percentage);
      });
      return sum ? sum : 0;
    },
    getFinalRating(answer) {
      const finalRating = answer[0]?.CEORating
        ? answer[0].CEORating
        : answer[0].VPRating;
      if (finalRating) return finalRating;
      // No other ratings exists. Return Manager's rating. Oddly, that's named final rating.
      return answer[0]?.finalRating;
    },
  },
};
</script>
<style scoped>
tr {
  height: 50px;
}
</style>
