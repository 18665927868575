<template>
  <v-container class="pa-6">
    <v-row v-if="user" class="d-flex justify-space-between pl-4">
      <h1>
        {{ greetingText }}
        <span v-if="user.displayName">
          {{ user.displayName.split(" ")[0] }}</span
        >
      </h1>
      <v-btn text x-large color="error" @click="logout">Logout</v-btn>
    </v-row>

    <div v-if="user" class="mt-4">
      <v-btn
        v-if="showCreateNewOrgBtn"
        @click="showCreateNewSection = !showCreateNewSection"
        color="af_blue"
        dark
        class="mt-12"
        >Create new organization</v-btn
      >
      <CreateNewOrg />
    </div>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
// import Invitations from "@/components/Invitations";

// import CloningDialog from "@/components/CloningDialog";
// import GmailWarning from "@/components/GmailWarning";
// import TrySandboxCard from "@/components/TrySandboxCard";
import CreateNewOrg from "@/components/CreateNewOrg";
import { mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: {
    // GmailWarning,
    // Invitations,
    // CloningDialog,
    // TrySandboxCard,
    CreateNewOrg,
  },
  data: () => ({
    isCreatingDemo: false,
    originalBoardName: null,
    showCreateNewSection: false,
    showCreateNewOrgBtn: true,

    showDeleteBtn: false,

    companySelected: "",
    showConfirmDelete: false,
    isDeleting: false,
  }),
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },

    isMasterAfradAdmin() {
      if (
        this.user.email === "yungookim@gmail.com" ||
        this.user.email === "ali.nawab@gmail.com" ||
        this.user.email === "tester@agentnoon.com" ||
        this.user.email === "dave@agentnoon.com"
      )
        return true;
      return false;
    },
    myFistName() {
      if (!this.user) return "";
      return this.user.displayName.split(" ")[0];
    },
    userMetaData() {
      return this.$store.state.user.userMetaData;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    isAdmin() {
      // Check if this user has admin access to the board
      if (!this.selectedCompanyMainBoard) return false;
      if (!this.user) return false;

      const uid = this.user.uid;
      if (this.selectedCompanyMainBoard.accessLevels) {
        const ret = this._.find(
          this.selectedCompanyMainBoard.accessLevels,
          (accessObj) => {
            return accessObj.uid === uid;
          }
        );
        if (!ret) {
          // Access level is not set for this user.
          // As this user is under "owners" assume the user is Admin.
          // This is for legacy competiability.
          // We will need to address it in the future.
          // Below code is redundant. But it is a safety measure.
          // This user is guaranteed to be under owners array.
          // Otherwise, the user would have have been able to fetch this board
          // due to Firebase rules.
          const isOwner = this._.find(
            this.selectedCompanyMainBoard.owners,
            (ownerUid) => {
              return ownerUid === uid;
            }
          );
          if (isOwner) {
            return true;
          } else {
            // this user is not added to the access yet
            // grant a temporary non-admin access
            return false;
          }
        }

        if (ret.accessLevel === "admin") {
          return true;
        } else if (ret.accessLevel.includes("team")) {
          // Then this person only has Team access
          return false;
        }
      }
      return true;
    },

    companyGroupedBoards() {
      let sortedArr = this._.sortBy(this.$store.state.board.boards, (b) => {
        return this.$moment(b.timeTarget, "Q - YYYY");
      });
      sortedArr = this._.sortBy(sortedArr, (b) => {
        return !b.masterBranch;
      });
      sortedArr = this._.sortBy(sortedArr, (b) => {
        return b.boardName;
      });
      return this._.groupBy(sortedArr, "companyName");
    },
    companiesGroupedByCompanyId() {
      return this._.groupBy(this.boards, "companyId");
    },
    companies() {
      return this._.chain(this.boards)
        .groupBy("companyId")
        .map((company) => {
          return {
            text: `${company[0].companyName}, created ${this.$moment(
              company[0].createdAt.toDate()
            ).fromNow()}`,
            value: `${company[0].companyId}`,
          };
        })
        .value();
    },
    sortedCompanyArray() {
      let sortedArr = this._.sortBy(this.$store.state.board.boards, (b) => {
        return this.$moment(b.timeTarget, "Q - YYYY");
      });
      sortedArr = this._.sortBy(sortedArr, (b) => {
        return b.boardName;
      });
      return sortedArr;
    },
    companyIdGroupedMasterBoards() {
      const sortedArr = this._.filter(this.sortedCompanyArray, (b) => {
        return b.masterBranch;
      });
      return this._.groupBy(sortedArr, "companyId");
    },
    mainBoards() {
      return this._.filter(this.sortedCompanyArray, (b) => {
        return b.masterBranch;
      });
    },
    clonedBoards() {
      return this._.filter(this.sortedCompanyArray, (b) => {
        return !b.masterBranch;
      });
    },
    greetingText() {
      const splitAfternoon = 12; // 24hr time to split the afternoon
      const splitEvening = 17; // 24hr time to split the evening
      const currentHour = parseFloat(new Date().getHours());

      if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return "Good afternoon";
      } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return "Good evening";
      }
      // Between dawn and noon
      return "Good morning";
    },
    filteredCompanyBoards() {
      return this.companiesGroupedByCompanyId[this.companySelected];
    },
    filteredCompanyBoardsWithoutMaster() {
      return this._.filter(
        this.companiesGroupedByCompanyId[this.companySelected],
        (b) => {
          return !b.masterBranch;
        }
      );
    },
    selectedCompanyMainBoard() {
      const board = this._.find(
        this.companiesGroupedByCompanyId[this.companySelected],
        (b) => {
          return b.masterBranch;
        }
      );

      return board ? board : null;
    },

    selectedCompanyMainBoardId() {
      return this.selectedCompanyMainBoard
        ? this.selectedCompanyMainBoard.boardId
        : null;
    },
    boardsFetched() {
      return this.$store.state.board.fetchCount > 0;
    },
  },
  watch: {
    companies: function () {
      if (this.companies.length > 0 && !this.$route.params.companyId) {
        this.companySelected = this.companies[0].value;
      }
    },
    companySelected: function () {
      this.resetSettings();
      // if (cur !== this.$route.params.companyId) {
      //   this.$router.push({ path: `/c/${cur}` });
      //   return;
      // }

      // if (this.companySelected && this.selectedCompanyMainBoardId) {
      //   this.fetchSettings({
      //     boardId: this.selectedCompanyMainBoardId,
      //   });
      // }
    },
    boardsFetched: function () {
      if (this.boardFetched) {
        const selectedCompany = this._.find(this.companies, (company) => {
          return company.value === this.companySelected;
        });
        if (!selectedCompany) {
          // This company does not exist.
          // This means the router parameter is pointing to a company that does not exist
          // return this.$router.push({ path: "/" });
        }
      }

      if (this.boards.length === 0) {
        // Show the new board creation options if the user is not assigned to any of the orgs
        this.showCreateNewSection = true;
        this.showCreateNewOrgBtn = false;
      }
    },
  },
  created() {
    this.$store.dispatch("resetTeamModule");
  },
  mounted() {
    console.log("dashboard mounted");

    // if (
    //   this.$route.params.companyId &&
    //   this.$route.params.companyId !== this.companySelected
    // ) {
    //   this.companySelected = this.$route.params.companyId;
    // }

    // if (!this.$route.params.companyId && this.companies.length > 0) {
    //   this.companySelected = this.companies[0].value;
    // }
  },
  methods: {
    ...mapActions(["fetchSettings", "resetSettings", "makeDemoOrg"]),
    logout() {
      this.$store.dispatch("logout");
      // Reset all states in Vuex
      this.$store.dispatch("reset");
      return this.$router.push({ path: "/" });
    },

    async deleteBoards(companyId) {
      const boards = this._.filter(this.boards, (board) => {
        return board.companyId === companyId;
      });

      this._.each(boards, async (board) => {
        await firebase
          .firestore()
          .collection("board")
          .doc(board.boardId)
          .delete();

        const querySnapshot = await firebase
          .firestore()
          .collection("people")
          .where("boardId", "==", board.boardId)
          .get();

        var batch = firebase.firestore().batch();

        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();
      });
      this.showDeleteBtn = false;
      this.$store.dispatch("fetchAllBoards", this.user.uid);
    },

    async createDemo() {
      this.isCreatingDemo = true;
      const ret = await this.makeDemoOrg({
        name: `${this.user.displayName}`,
        email: this.user.email,
      });

      if (ret.data.companyId) {
        window.location.href = `/#/demo/${ret.data.boardId}`;
        window.location.reload();
      } else {
        alert(
          "Something went wrong. The team has been notified and it will be fixed soon. Apologies for the inconvenience."
        );
      }

      this.isCreatingDemo = false;
    },

    async deleteSampleOrg() {
      this.isDeleting = true;
      var deleteDemo = firebase.functions().httpsCallable("deleteDemo");
      const ret = await deleteDemo({
        boardId: this.selectedCompanyMainBoardId,
      });
      console.log(ret);
      window.location = "/";
    },
  },
};
</script>

<style scoped></style>
