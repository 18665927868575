import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/Home";
import CompanyView from "@/views/CompanyView";
import Dashboard from "@/views/Dashboard";
import Login from "@/views/Login";
import VerifyEmail from "@/views/VerifyEmail";
import PasswordReset from "@/views/PasswordReset";
import Signup from "@/views/Signup";
// import Team from "@/views/Team";
// import OrgChart from "@/views/OrgChart";
// import Project from "@/views/Project";
// import Table from "@/views/Table";
// import Requisitions from "@/views/Requisitions";
// import HiringRequest from "@/views/HiringRequest";
// import Settings from "@/views/Settings";
// import TeamMap from "@/views/Map";
// import HRViewHeadcountPlan from "@/views/HRViewHeadcountPlan";
// import HRViewHeadcountPlans from "@/views/HRViewHeadcountPlans";
// import HeadcountPlan from "@/views/HeadcountPlan";
// import HeadcountPlanReview from "@/views/HeadcountPlanReview";
// import SquadView from "@/views/SquadView";
// import Squads from "@/views/Squads";
// import Squad from "@/views/Squad";
// import ImportData from "@/views/ImportData";
// import WeeeImportData from "@/views/CustomViews/WeeeImportData";
// import Reports from "@/views/Reports";
import Init from "@/views/Init";
import PerformanceReview from "@/views/PerformanceReview";
import PerformanceReviewCreationView from "@/views/PerformanceReviewCreationView";
import PerformanceReviewForm from "@/views/PerformanceReviewForm";
import PerformanceReviewResponse from "@/views/PerformanceReviewResponse";
import PerformanceReviewPrint from "@/views/PerformanceReviewPrint";
import PerformanceReviewManagerReviewTable from "@/components/Calibration/PerformanceReviewManagerReviewTable";
import PerformanceReviewVPApproval from "@/components/Calibration/PerformanceReviewVPApproval";
import PerformanceReviewCEOApproval from "@/components/Calibration/PerformanceReviewCEOApproval";
import PerformanceReviewRatingOnly from "@/views/PerformanceReviewRatingOnly.vue";
import PerformanceReviewHRBPAccess from "@/views/PerformanceReviewHRBPAccess.vue";
import PerformanceReviewReadOnly from "@/views/PerformanceReviewReadOnly.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/demo/:boardId",
    name: "Demo",
    component: CompanyView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/company/:boardId",
    name: "Company",
    component: CompanyView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/verify",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/password_reset",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/start",
    name: "Init",
    component: Init,
  },
  {
    path: "/c",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/settings/:boardId",
  //   name: "Settings",
  //   component: Settings,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/PerformanceReview/:boardId",
    name: "PerformanceReview",
    component: PerformanceReview,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/PerformanceReview/:boardId/:templateId",
    name: "PerformanceReviewCreationView",
    component: PerformanceReviewCreationView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/performance-review/:boardId/:templateId",
    name: "PerformanceReviewForm",
    component: PerformanceReviewForm,
  },
  {
    path: "/PerformanceReviewResponse/:boardId/:templateId/:personId",
    name: "PerformanceReviewResponse",
    component: PerformanceReviewResponse,
  },
  {
    path: "/PerformanceReviewPrint/:boardId/:templateId/:personId",
    name: "PerformanceReviewPrint",
    component: PerformanceReviewPrint,
  },

  {
    path: "/PerformanceReviewManagerView/:boardId/:templateId",
    name: "PerformanceReviewManagerView",
    component: PerformanceReviewManagerReviewTable,
  },
  {
    path: "/PerformanceReviewVPApproval/:boardId/:templateId",
    name: "PerformanceReviewVPApproval",
    component: PerformanceReviewVPApproval,
  },
  {
    path: "/PerformanceReviewCEOApproval/:boardId/:templateId",
    name: "PerformanceReviewCEOApproval",
    component: PerformanceReviewCEOApproval,
  },
  {
    path: "/PerformanceReviewRatingOnly/:boardId/:templateId",
    name: "PerformanceReviewRatingOnly",
    component: PerformanceReviewRatingOnly,
  },
  {
    path: "/PerformanceReviewHRBPAccess/:boardId/",
    name: "PerformanceReviewHRBPAccess",
    component: PerformanceReviewHRBPAccess,
  },
  {
    path: "/PerformanceReviewReadOnly/:boardId/:templateId/:personId",
    name: "PerformanceReviewReadOnly",
    component: PerformanceReviewReadOnly,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
