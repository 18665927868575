<template>
  <v-container>
    <h1 class="mt-6 mb-4">Performance Review</h1>
    <v-row>
      <v-col cols="12" md="4">
        <v-card
          class="new-squad-card d-flex justify-center align-center"
          style="height: 110px"
        >
          <v-btn color="af_blue" class="" elevation="0" text @click="create">
            <v-icon class="mr-2" dense>mdi-plus-circle-outline</v-icon>
            Start New Review
          </v-btn>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          class="new-squad-card d-flex justify-center align-center"
          style="height: 110px"
        >
          <v-btn color="af_blue" class="" elevation="0" text disabled>
            <v-icon class="mr-2" dense>mdi-database-import-outline</v-icon>
            Import from Lattice
          </v-btn>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" v-for="t in templates" :key="t.templateId">
        <v-card class="card-shadow">
          <v-card-title class="body-1"
            ><a
              v-if="t.name"
              :href="`/#/PerformanceReview/${boardId}/${t.templateId}`"
              >{{ t.name }}</a
            >
            <a v-else :href="`/#/PerformanceReview/${boardId}/${t.templateId}`"
              >No title yet</a
            >
          </v-card-title>
          <v-card-subtitle>
            Created on
            {{ t.createdAt.seconds | moment("ddd, MMM D YYYY, h:mm a") }}
          </v-card-subtitle>
          <!-- <v-card-text> -->
          <!-- <p>Due: {{ t.deadline }}</p> -->
          <!-- <p>-- Reponse Rate | -- Manager Review Rate | -- VP Calibration</p> -->
          <!-- </v-card-text> -->

          <!-- <v-progress-linear
            v-model="t.completed"
            height="20"
            color="af_blue"
            class="white--text"
          >
            <strong>{{ t.completed }}%</strong>
          </v-progress-linear> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PerformanceReview",

  computed: {
    ...mapGetters(["uid", "myPersonId", "people", "user", "getBoard"]),
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.getBoard(this.boardId);
    },
  },
  data: () => ({ templates: [], placeholder: 80 }),
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "createPerformanceReviewTemplate",
      "fetchPerformanceReviews",
    ]),
    async init() {
      this.templates = await this.fetchPerformanceReviews({
        boardId: this.boardId,
      });

      this.templates = this._.sortBy(this.templates, (temp) => {
        return temp.createdAt.seconds;
      });
    },
    async create() {
      // Create a new performance review template
      const templateId = await this.createPerformanceReviewTemplate({
        board: this.board,
        user: this.user,
      });
      if (templateId === "error") {
        alert(
          "Something went wrong. The team has been alerted and the issue will be resolved soon."
        );
        return;
      }
      // Go to page
      this.$router.push({
        path: `/PerformanceReview/${this.boardId}/${templateId}`,
      });
    },
  },
};
</script>

<style scoped>
.new-squad-card {
  background-color: #e2ecf3;
  border: 1.5px dashed #0062ff;
  border-radius: 8px;
}
</style>
