import Vue from "vue";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import * as Sentry from "@sentry/browser";

const state = {
  // Firebase user object
  userObj: null,
  userMetaData: null,
  invitations: null,
  backupOriginalUser: null,
  photoURL: "",
  colorCodeMode: "Employee Status",
};

const mutations = {
  setUser(state, _user) {
    if (_user) {
      state.userObj = Vue._.cloneDeep(_user);
      // console.log(_user.photoURL);
      state.photoURL = _user.photoURL;
    }
  },
  setUserMetaData(state, metaData) {
    state.userMetaData = metaData;
  },
  setInvitations(state, invitations) {
    state.invitations = invitations;
  },
  backupOriginalUser(state) {
    state.backupOriginalUser = Vue._.cloneDeep(state.userObj);
  },
  setColorCodeMode(state, flag) {
    state.colorCodeMode = flag;
  },
};

const getters = {
  /**
   * Returns true if the current user is admin
   * @param {String} boardId Passed from the calling component.
   * @returns
   */
  // eslint-disable-next-line no-unused-vars
  isAdmin: (state, getters, rootState, rootGetters) => (boardId) => {
    // console.log(getters);
    const targetBoard = Vue._.find(rootState.board.boards, (board) => {
      return board.boardId === boardId;
    });
    if (!targetBoard) return false;
    if (targetBoard.accessLevels) {
      return Vue._.some(targetBoard.accessLevels, (access) => {
        return (
          access.uid === state.userObj.uid && access.accessLevel === "admin"
        );
      });
    }
    return false;
  },

  role: (state, getters, rootState) => (boardId) => {
    const targetBoard = Vue._.find(rootState.board.boards, (board) => {
      return board.boardId === boardId;
    });
    return Vue._.find(targetBoard.accessLevels, (access) => {
      return access.uid === getters.uid;
    }).accessLevel;
  },

  user: (state) => {
    return state.userObj;
  },

  uid: (state) => {
    return state.userObj.uid;
  },

  myEmail: (state) => {
    if (!state.userObj) return "";
    return state.userObj.email;
  },

  /**
   * Look up the people list, find my profile, return my personId
   * @param {*} state
   * @param {*} getters
   * @param {*} rootState
   * @param {*} rootGetters
   * @returns
   */
  // eslint-disable-next-line no-unused-vars
  myPersonId: (state, getters, rootState, rootGetters) => (boardId) => {
    const t = Vue._.find(rootState.team.people, (person) => {
      if (!person.email || !getters.myEmail) return false;
      return (
        person.email.toLowerCase().trim() ===
          getters.myEmail.toLowerCase().trim() && person.boardId === boardId
      );
    });
    if (t) return t.personId;
    return null;
  },
};

const actions = {
  async signInAction(context) {
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });

    firebase.auth().useDeviceLanguage();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        const uid = result.user.uid;

        if (result.additionalUserInfo.isNewUser) {
          window.mixpanel.track("signup");
          // window.location = "/#/start";
          return;
        }

        context.dispatch("fetchUserMetaData", uid);
      })
      .catch((error) => {
        window.console.error(error);
        if (Sentry) Sentry.captureException(error);
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      });
  },
  logout(context) {
    firebase
      .auth()
      .signOut()
      .then(
        () => {
          // Sign-out successful.
          context.commit("setUser", null);
          console.log("logout success");
        },
        function (error) {
          // An error happened.
          console.error(error);
          if (Sentry) Sentry.captureException(error);
        }
      );
  },
  setUserAuth(context, user) {
    context.commit("setUser", user);
  },

  async getUserNames(context, uids){
    var checkAccountStatus = firebase
    .functions()
    .httpsCallable("userCreate-getUserName");
    const ret = await checkAccountStatus({uids});
    if (ret.data) return ret.data

    return null
  },

  setViewAsOn(context, otherUser) {
    context.commit("backupOriginalUser");
    context.commit("setUser", otherUser);
    context.state.userObj.isViewAsMode = true;
  },
  resetViewAs(context) {
    context.commit("setUser", context.state.backupOriginalUser);
    context.state.backupOriginalUser = null;
    context.state.userObj.isViewAsMode = false;
  },

  async fetchUserMetaData(context, uid) {
    try {
      const ret = await firebase.firestore().collection("users").doc(uid).get();
      context.commit("setUserMetaData", ret.data());
    } catch (e) {
      // console.error(e)
      // console.error('fetchUserMetaData failed');
    }
  },

  async setUserMetaData(context, data) {
    context.commit("setUserMetaData", data);
    await firebase.firestore().collection("users").doc(data.uid).set(data);
  },

  async fetchPendingInvitations(context, { email }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("invitations")
      .where("recipient", "==", email)
      .where("invitationAccepted", "==", false)
      .get();

    const invitations = [];
    querySnapshot.forEach((doc) => {
      if (!doc.data().ignore) {
        invitations.push({ ...doc.data(), id: doc.id });
      }
    });
    context.commit("setInvitations", invitations);
  },

  async fetchPendingInvitationsForBoard(context, { boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("invitations")
      .where("boardId", "==", boardId)
      .where("invitationAccepted", "==", false)
      .get();

    const pendingInvitaitons = Vue._.map(querySnapshot.docs, (doc) => {
      let ret = doc.data();
      ret["recipient"] = ret["recipient"].toLowerCase().trim();
      return ret;
    });

    return pendingInvitaitons;
  },

  async acceptInvitation(context, { companyId, invitationObjectId }) {
    await firebase
      .firestore()
      .collection("invitations")
      .doc(invitationObjectId)
      .update({
        invitationAccepted: true,
        invitationAcceptedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

    var inviteAccepted = firebase.functions().httpsCallable("inviteAccepted");
    // TODO catch errors
    await inviteAccepted({ companyId, invitationObjectId });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  modules: {},
};
