// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import * as Sentry from '@sentry/browser';

const state = {
  proposalSelections: null,
};

const mutations = {
  // setUser(state, _user) {
  //   state.userObj = _user;
  // },
  setProposalSelections(state, proposalSelections) {
    state.proposalSelections = proposalSelections;
  },
};

const actions = {
  // async notifyNewHiringDecision(state, { boardName, boardId, decider, status, role, sendTo }) {
  // const origin = `${window.location.origin}/#`;
  // var notifyNewHiringDecision = firebase.functions().httpsCallable('notifyNewHiringDecision');
  // await notifyNewHiringDecision({ origin, boardName, boardId, decider, status, role, sendTo });
  // },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
