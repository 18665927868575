<template>
  <v-dialog v-model="show" max-width="500">
    <v-card>
      <v-card-title class="headline">
        Quick data import from BambooHR
      </v-card-title>

      <v-card-text>
        <v-text-field
          label="Company's BambooHR address"
          hint="e.g. https://agentnoon.bamboohr.com"
          v-model="bambooLink"
          :disabled="progress > 0"
        ></v-text-field>

        <v-text-field
          label="BambooHR API Key"
          hint="e.g. 0b6fga49b7h9b0ee65d1kibb66279c68028a570e"
          v-model="bambooHR_APIKey"
          :disabled="progress > 0"
        >
        </v-text-field>

        <p class="mt-6">
          <a
            href="https://www.notion.so/How-to-set-up-an-integration-with-BambooHR-5f2c4d5e401940daa36eed9b6ffcd381"
            >How to get BambooHR API Key</a
          >
        </p>
        <p>
          <a
            v-if="bambooLink !== ''"
            :href="`${bambooLink}/settings/permissions/api.php`"
            class="text-right"
          >
            Generate a BambooHR API key here
          </a>
        </p>

        <h3 v-show="progress > 0" class="my-12">
          Please do not leave or refresh the page
        </h3>
        <v-progress-linear v-show="progress > 0" v-model="progress" height="25">
          <strong>{{ progress }}%</strong>
        </v-progress-linear>

        <h3 v-if="importProgressText">{{ importProgressText }}</h3>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          :disabled="progress > 0"
          color="error darken-1"
          text
          @click="show = false"
        >
          Cancel
        </v-btn>

        <v-btn
          :disabled="progress > 0"
          color="green darken-1"
          text
          @click="importBambooHR"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "BambooHRImportDialog",
  props: {
    show: Boolean,
  },
  components: {},
  data: () => ({
    bambooLink: "",
    bambooHR_APIKey: "",
    importProgressText: "",
    progress: 0,
  }),
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
  },

  methods: {
    async importBambooHR() {
      // TODO
      // Error handlings
      // Wrong api key, wrong subdomain, etc
      // TEST DATA
      // https://afrad.bamboohr.com/
      // 0b6ffa49b779b0e9b5d166bb66279c68028a570e

      // https://onetenventuresinc.bamboohr.com/
      // 8ab63e0429aebf809ac8faae84387d86f3f56506

      this.progress = 1;
      this.importProgressText = "Fetching employee directory";
      // Extract the subdomain from the provided bambooHRLink
      var a = new String(this.bambooLink);
      a = a.replace("http://", "");
      a = a.replace("https://", "");
      const subdomain = a.split(".")[0];
      const apiKey = this.bambooHR_APIKey.trim();

      // Import everyone from Bamboo directory
      // We need to ask the user for their API key here for now.
      // Until we are listed on Bamboo's market place
      let ret = await this.$store.dispatch("importBambooHREmployeeList", {
        subdomain: subdomain,
        apiKey: apiKey,
      });

      if (ret === "error") {
        this.progress = 0;
        this.importProgressText =
          "There was an error fetching employee directory. Please check the BambooHr address and API key and try it again. If the problem continues, please reach out via the orange Feedback button at the left of the screen.";
        return;
      }

      // Create a new board if fetching employee directory was successful
      const idCreator = require("@/lib/idCreator");
      const companyId = idCreator.createCompanyId();
      const newBoardId = idCreator.createBoardId();
      const newBoard = {
        companyId: companyId,
        boardId: newBoardId,
        originalBoardId: newBoardId, // This is the original board
        boardName: subdomain + "'s org chart",
        companyName: subdomain,
        owners: [this.user.uid],
        accessLevels: [{ uid: this.user.uid, accessLevel: "admin" }],
        // timeTarget: this.$moment().format('Q - YYYY'),
        masterBranch: true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      // Store the API information for future use
      firebase.firestore().collection("integrations").doc().set({
        companyId: companyId,
        boardId: newBoardId,
        companyName: subdomain,
        source: "BambooHR",
        importedBy: this.user.uid,
        subdomain: subdomain,
        apiKey: apiKey,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      await firebase
        .firestore()
        .collection("board")
        .doc(newBoardId)
        .set(newBoard);

      let directory = this.$store.state.bambooHR.directory;

      const people = [];
      const peopleByBambooId = {};
      let y = 100;

      this._.each(directory, (employee) => {
        const compensationObj = {
          payRate: employee.payRate,
          payPer: employee.payPer,
          payType: employee.payType,
          payRateEffectiveDate: employee.payRateEffectiveDate,
        };

        delete employee.payRate;
        delete employee.payPer;
        delete employee.payType;
        delete employee.payRateEffectiveDate;

        const newPersonObj = {
          boardId: newBoardId,
          personId: idCreator.createPersonId(),
          name: employee.displayName,
          role: employee.jobTitle,
          email: employee.workEmail,
          avatarImg: employee.photoUrl
            ? employee.photoUrl
            : "https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5",
          officeLocation: employee.location,
          boardCoordinateX: 300,
          boardCoordinateY: (y += 50),
          subordinates: [],
          managers: [],
          employeeStatus: employee.status,
          employeeData: {
            holidayDates: [],
            department: employee.department,
            division: employee.division,
            startDate: employee.hireDate,
            terminationDate: employee.terminationDate,
            firstName: employee.firstName,
            lastName: employee.lastName,
            gender: employee.gender,
            linkedIn: employee.linkedIn,
            twitter: employee.twitterFeed,
            dataSourcce: "bamboohr",
            dataSource: "bamboohr",
          },
          importedData: employee,
          createdBy: "",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        this._.forEach(newPersonObj.employeeData, (v, k) => {
          if (!v || v === undefined || this._.isNull(v) || this._.isNaN(v)) {
            newPersonObj.employeeData[k] = "";
          }
        });

        people.push({
          person: newPersonObj,
          compensation: compensationObj,
        });
        peopleByBambooId[employee.employeeNumber] = newPersonObj;
      });

      // Find the manager of each person and map it in Afrad structure
      this._.map(peopleByBambooId, (person) => {
        if (peopleByBambooId[person.importedData.supervisorId]) {
          const manager = peopleByBambooId[person.importedData.supervisorId];
          if (manager) {
            person.managers = [manager.personId];
            manager.subordinates.push(person.personId);
          }
        }
      });

      // TODO make this accept list

      let count = 1;
      this._.each(people, async (obj) => {
        await this.$store.dispatch("addPerson", {
          personObj: obj.person,
          boardId: newBoardId,
          createdBy: this.user.uid,
        });

        await this.$store.dispatch("addCompensation", {
          personId: obj.person.personId,
          boardId: newBoardId,
          payPer: obj.compensation.payPer,
          payRate: obj.compensation.payRate,
          payRateEffectiveDate: obj.compensation.payRateEffectiveDate,
          payType: obj.compensation.payType,
          createdBy: this.user.uid,
        });

        this.progress = parseInt((count++ / (directory.length * 1.0)) * 100);
        this.importProgressText = "Added " + obj.person.name;

        if (count > directory.length) {
          this.importProgressText = "Done";

          // // TODO create org graph for other boards
          await this.$store.dispatch("fetchAllBoards", this.user.uid);
          this.$router.push({ path: `/board/${newBoardId}` });
          this.$destroy();
        }
      });
    },
  },
};
</script>
