const BackgroundColors = [
  "#E5D9F3",
  "#FFC9BF",
  "#EDF9D4",
  "#FBE8CF",
  "#D4E7F9",
  "#CAF0E9",
  "#F6D19F",
  "#E1E3E9",
  "#F4D5C3",
  "#D9E7DF",
  "#B7DAE5",
  "#DDEAFB",
  "#D2EFCB",
  "#CBCFEF",
  "#F3EED3",
  "#FFD6FF",
  "#F1ECB8",
  "#F1DAB8",
  "#FFDDEF",
  "#D6EEEB",
  "#BCE3FF",
  "#FCCECE",
  "#D1E0CE",
  "#DEDEEE",
  "#E5D7C1",
];

const OutlineColors = [
  "#C2A1E9",
  "#E29384",
  "#B5C498",
  "#CDBBA3",
  "#8BA2B7",
  "#58BDA9",
  "#E6AB5D",
  "#B0B5C1",
  "#987460",
  "#91B4A0",
  "#7EADBC",
  "#8CB3E7",
  "#8EB784",
  "#797FAF",
  "#B9B393",
  "#DA83DA",
  "#C6BE7B",
  "#B0956C",
  "#B992A7",
  "#94B0AE",
  "#50B5FF",
  "#E95E5E",
  "#73BB61",
  "#9593ED",
  "#C5821F",
];

export { BackgroundColors, OutlineColors };
