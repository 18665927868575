<template>
  <v-container>
    <v-card class="mx-auto my-12 pa-10">
      <v-progress-linear
        v-if="isFetching"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <div v-if="!isFetching">
        <v-card-text class="text-center">
          <h2 class="mb-12">{{ templateName }}</h2>
          <p v-if="!myName">We can't find your profile.</p>
          <p v-if="myName" class="text-center">Hi {{ myName }}</p>
          <p class="text-center">
            Please confirm your email:
            <span class="blue--text">{{ myEmail }}</span>
          </p>

          <v-btn
            v-if="myName"
            :disabled="confirmed"
            @click="confirm"
            class="my-4"
            color="primary"
            >Confirm</v-btn
          >
          <v-divider />

          <div v-if="confirmed">
            <div v-if="!finalRating">
              <p class="mt-6">We can't find your final rating.</p>
              <p class="mt-4">
                If you are experiencing an issue, please get in touch at
                <a href="mailto:hello@agentnoon.com">hello@agentnoon.com</a>
              </p>
            </div>

            <div v-if="finalRating">
              <p class="text-h6 text--grey mt-6 text-center">
                Your final rating
              </p>
              <p class="text-h4 text--primary my-6 text-center">
                {{ finalRating }}
              </p>

              <div class="text--primary text-center">
                Please contact your manager for any questions.
              </div>

              <!-- <div class="text--grey mt-6 text-center">
                Last updated at
                {{ updatedAt._seconds | moment("ddd, MMM D YYYY, h:mm:ss a") }}
              </div> -->
            </div>
          </div>
        </v-card-text>

        <!-- <v-simple-table v-if="answers" class="mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Responsibilities</th>
                <th>Achievement/Result</th>
                <th
                  v-if="
                    answers.responsibilities &&
                    answers.responsibilities[0].percentage
                  "
                >
                  Percentage
                </th>
                <th
                  v-if="
                    answers.responsibilities &&
                    answers.responsibilities[0].score
                  "
                >
                  Score
                </th>
                <th
                  v-if="
                    answers.responsibilities &&
                    answers.responsibilities[0].managerResult
                  "
                >
                  Manager Result
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                no-gutters
                v-for="res in answers.responsibilities"
                :key="res.okrId"
              >
                <td>
                  <p>{{ res.responsibility }}</p>
                </td>
                <td>
                  <p>{{ res.result }}</p>
                </td>
                <td v-if="res.percentage">
                  <p>{{ res.percentage }}%</p>
                </td>
                <td v-if="res.score">
                  <p>{{ res.score }}</p>
                </td>
                <td v-if="res.managerResult">
                  <p>{{ res.managerResult }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->

        <!-- <v-simple-table v-if="answers" class="mt-8">
          <template v-slot:default>
            <thead>
              <tr>
                <th>Evaluation of Leadership Principles</th>
                <th>Assessment</th>
                <th
                  v-if="
                    answers.evaluations && answers.evaluations[0].percentage
                  "
                >
                  Percentage
                </th>
                <th>Score</th>
                <th
                  v-if="
                    answers.evaluations && answers.evaluations[0].percentage
                  "
                >
                  Result
                </th>
              </tr>
            </thead>
            <tbody>
              <tr no-gutters v-for="(eva, k) in answers.evaluations" :key="k">
                <td>
                  <p class="body-1 mt-2">{{ eva.category }}</p>
                </td>
                <td>
                  <p>{{ eva.assessment }}</p>
                </td>
                <td v-if="eva.percentage">
                  <p>{{ eva.percentage }}%</p>
                </td>

                <td v-if="eva.score">
                  <p>{{ eva.score }}</p>
                </td>
                <td v-if="eva.managerResult">
                  <p>{{ eva.managerResult }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table> -->

        <div v-if="answers" class="mt-6">
          <!-- <h1>Self Evaluation</h1>
          <p v-html="answers.selfEvaluation"></p> -->

          <h1 class="mt-6">Manager's comment</h1>
          <p v-html="answers.comment"></p>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceReviewRatingOnly",
  props: {},
  data: () => ({
    finalRating: "",
    updatedAt: "",
    isFetching: false,
    templateName: "",
    confirmed: false,
    answers: null,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "isAdmin",
      "activeEmployees",
      "user",
      "people",
      "role",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    myEmail() {
      if (this.user) {
        return this.user.email;
      }
      return null;
    },
    myName() {
      if (!this.myEmail) return null;
      // We use this to see if I should be able to approve this request
      if (this.getPersonObjByEmail(this.myEmail)) {
        console.log(this.getPersonObjByEmail(this.myEmail).name);
        return this.getPersonObjByEmail(this.myEmail).name;
      }
      return null;
    },
    myPersonId() {
      if (!this.myEmail) return null;
      // We use this to see if I should be able to approve this request
      if (this.getPersonObjByEmail(this.myEmail)) {
        return this.getPersonObjByEmail(this.myEmail).personId;
      }
      return null;
    },
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchPeople",
      "fetchPerformanceReviewRatingOnly",
      "fetchPerformanceReviewTemplate",
    ]),
    async init() {
      this.isFetching = true;

      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.templateName = ret.name;

      await this.fetchPeople({ boardId: this.boardId });

      this.isFetching = false;
    },
    getPersonObjByEmail(email) {
      return this._.find(this.people, (person) => {
        return person.email === email;
      });
    },
    async confirm() {
      this.confirmed = true;

      this.isFetching = true;

      const rating = await this.fetchPerformanceReviewRatingOnly({
        boardId: this.boardId,
        revieweePersonId: this.myPersonId,
        templateId: this.templateId,
      });

      this.answers = rating;
      console.log(rating);
      this.finalRating = rating.finalRating;
      this.updatedAt = rating.updatedAt;
      this.isFetching = false;
    },
  },
};
</script>
