import _ from "lodash";

/**
 * Check if the user should have access to the targetPerson object.
 * @param {*} board board object
 * @param {*} user user object (Auth object by Firebase Auth)
 * @param {*} targetPerson person object of the person data being accessed
 * @param {*} me the current user's person object
 * @param {*} peopleDict people data keyed by personId
 * @returns
 */
const AccessCheck = (board, user, targetPerson, me, peopleDict) => {
  if (!board || !board.accessLevels) return false;

  if (!me) {
    if (user.uid === "MapVVRBAdfWwyOPPPTem8GMyceo1") {
      // Super admin user that's allow to edit
      //
      return true;
    }
    return false;
  }

  const ret = _.find(board.accessLevels, (accessObj) => {
    return accessObj.uid === user.uid;
  });

  if (ret.accessLevel === "admin" && targetPerson.personId !== me.personId) {
    // I'm Admin. I can access anything
    // unless it's myself
    return true;
  }

  if (targetPerson.personId === me.personId) {
    // I should not be able to see my own performance
    return false;
  }
  // I am a manager. I need to check if I am access my own team
  let myTeam = [];
  const listAllSubordinates = (target) => {
    if (!target) return;
    myTeam.push(target.personId);
    _.map(target.subordinates, (personId) => {
      listAllSubordinates(peopleDict[personId]);
    });
  };

  listAllSubordinates(me);
  // Remove myself. Since I should not be allow to see my own performance
  const k = myTeam.indexOf(me.personId);
  if (k > -1) myTeam.splice(k, 1);

  if (myTeam.includes(targetPerson.personId)) {
    return true;
  }
  return false;
};

export { AccessCheck };
