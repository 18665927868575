<template>
  <v-container>
    <h2 v-if="showLoginPrompt" class="mt-6">
      Please
      <a :href="`#/?redirect=${$route.path}`">login</a>
      or
      <a :href="`#/?redirect=${$route.path}`">create an account</a>
      to continue
    </h2>

    <v-card v-if="!isLoading && !eligible" class="card-shadow pa-4 mt-6 pa-8">
      <h3>You may not have access to {{ name }} page.</h3>

      <p class="mt-6">
        Only full-time employees who joined the company
        <b>before October 1st, 2021</b>
        are subject to the performance review process.
      </p>

      <p>
        If the above does not apply to you, please contact support by clicking
        the blue chat icon at the bottom right of this page, and we will get you
        set up quickly.
      </p>
    </v-card>

    <v-progress-linear v-if="isLoading" color="af_blue" indeterminate />
    <v-card
      v-if="!isLoading && !showLoginPrompt && eligible"
      class="card-shadow pa-4 mt-6"
    >
      <v-row>
        <v-col cols="12" class="text-center">
          <h1>{{ name }}</h1>
          <h3 data-intercom-target="Due Date">
            Due on {{ deadline | moment("dddd, MMMM DD, YYYY") }}
          </h3>
          <p
            class="body-1 text-center mb-4"
            data-intercom-target="Signed in as"
          >
            Signed in as {{ user.displayName }} ({{ user.email }})
          </p>
        </v-col>
      </v-row>

      <v-alert v-if="isCompleted" prominent type="info" class="ma-12">
        <p class="body-1 my-0 py-0">Your {{ name }} is submitted.</p>
      </v-alert>

      <v-form v-if="!isLoading" v-model="valid" ref="form">
        <div class="mt-2">
          <v-stepper elevation="0" class="mt-4">
            <v-stepper-header>
              <v-stepper-step
                step="1"
                :complete="isSectionCompleted().responsibilities"
              >
                Responsibilities & Achievements
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                :complete="isSectionCompleted().evaluationAnswers"
              >
                Leadership Principles
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="3"
                v-if="!disableSelfEvaluation"
                :complete="isSectionCompleted().selfEvaluation"
              >
                Self Evaluation
              </v-stepper-step>

              <v-divider v-if="!disableSelfEvaluation"></v-divider>

              <v-stepper-step step="" :complete="isCompleted">
                Submit
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-alert
          v-if="!isCompleted"
          prominent
          type="info"
          class="ma-12"
          data-intercom-target="How to"
        >
          <p class="body-1 my-0 py-0">
            Please fill out all the <b>blue</b> sections.
          </p>

          <p class="body-1 my-0 py-0 mt-2">
            If you have more than {{ responsibilities.length }} OKRs or
            projects, click ADD ROW.
          </p>

          <p class="body-1 my-0 py-0 mt-2">
            If you have less than {{ responsibilities.length }} OKRs or
            projects, leave the excess rows blank.
          </p>
        </v-alert>

        <v-simple-table class="mt-12">
          <template v-slot:default>
            <thead>
              <tr>
                <th><h3>Objectives</h3></th>
                <th v-if="!goalSettingMode"><h3>Achievement/Result</h3></th>
                <th v-if="goalSettingMode"><h3>Target</h3></th>
                <th v-if="goalSettingMode"><h3>Action</h3></th>
                <th v-if="enableOKRPercentage" class="text-center">
                  <h3
                    :class="{ 'red--text': getSum(responsibilities) !== 100 }"
                  >
                    Weighted Importance <br />(Total to 100)
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(r, i) in responsibilities" :key="r.okrId">
                <td>
                  <v-textarea
                    v-model="r.responsibility"
                    :label="`Project/OKR #${i + 1}`"
                    hint="Filled out by employee"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    auto-grow
                    rows="1"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  ></v-textarea>
                </td>
                <td v-if="!goalSettingMode">
                  <v-textarea
                    v-model="r.result"
                    :label="`Achievement/Result #${i + 1}`"
                    hint="Filled out by employee"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    auto-grow
                    rows="1"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  ></v-textarea>
                </td>
                <td v-if="goalSettingMode">
                  <v-textarea
                    v-model="r.target"
                    hint="Filled out by employee"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    auto-grow
                    rows="1"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  ></v-textarea>
                </td>

                <td v-if="goalSettingMode">
                  <v-textarea
                    v-model="r.action"
                    hint="Filled out by employee"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    auto-grow
                    rows="1"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  ></v-textarea>
                </td>
                <td v-if="enableOKRPercentage" style="width: 100px">
                  <v-text-field
                    v-model="r.percentage"
                    label="%"
                    type="number"
                    hint="Filled out by employee"
                    :rules="[numberRule]"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    :class="{ 'red-border': getSum(responsibilities) !== 100 }"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row no-gutters>
          <v-col cols="12" class="pa-4">
            <v-btn
              @click="addOKR"
              dense
              class="mr-4 white--text"
              color="af_blue"
              data-intercom-target="add row"
              :disabled="!editableFormAfterSubmission && isCompleted"
            >
              <v-icon class="" dense>mdi-plus-circle-outline</v-icon>
              Add Row
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h3 class="ml-4">{{ evaluationTitle }}</h3>
          </v-col>
        </v-row>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="width: 200px"></th>
                <th v-if="enableSelfAssessment"></th>
                <th v-if="enableEvaluationPercentage" class="text-center">
                  <h3
                    :class="{ 'red--text': getSum(evaluationAnswers) !== 100 }"
                  >
                    Weighted Importance (Total to 100)
                  </h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(evaluation, k) in evaluationCategories" :key="k">
                <td>
                  <p class="body-1 font-weight-black inline">
                    {{ evaluationCategories[k] }}
                  </p>
                </td>

                <!-- :label="getBehaviourLabel()" -->
                <td v-if="enableSelfAssessment">
                  <p>{{ evaluationDescriptions[k] }}</p>
                  <v-textarea
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    auto-grow
                    rows="1"
                    hint="Filled out by employee"
                    v-model="evaluationAnswers[k].assessment"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  />
                </td>
                <td v-if="enableEvaluationPercentage" style="width: 100px">
                  <v-text-field
                    label="%"
                    type="number"
                    solo
                    background-color="af_blue lighten-5"
                    dense
                    :rules="[numberRule]"
                    v-model="evaluationAnswers[k].percentage"
                    :class="{ 'red-border': getSum(evaluationAnswers) !== 100 }"
                    :disabled="!editableFormAfterSubmission && isCompleted"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row v-if="!disableSelfEvaluation" class="mt-4">
          <v-col cols="12">
            <h3 class="mb-4 ml-4">Employee Self Evaluation</h3>

            <p class="body-1 mx-4">{{ evaluationQuestion }}</p>

            <v-textarea
              dense
              class="mx-4"
              auto-grow
              rows="3"
              solo
              background-color="af_blue lighten-5"
              :rules="[noneEmptyRule]"
              v-model="selfEvaluation"
              :disabled="!editableFormAfterSubmission && isCompleted"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              :href="`/#/PerformanceReviewPrint/${boardId}/${templateId}/${personId}`"
              dense
              outlined
              class="mr-4"
              target="blank"
              >Print
            </v-btn>

            <v-btn
              :disabled="!name || isDemo || isCompleted"
              dense
              class=""
              :loading="isSaving"
              outlined
              @click="saveDraft"
            >
              Save Draft <span v-if="isDemo">Disabled in demo mode</span>
            </v-btn>

            <v-btn
              :disabled="
                !name ||
                isDemo ||
                !editableFormAfterSubmission ||
                !(
                  getSum(evaluationAnswers) + getSum(responsibilities) ===
                    200 ||
                  (getSum(evaluationAnswers) + getSum(responsibilities) === 0 &&
                    !enableOKRPercentage &&
                    !enableEvaluationPercentage)
                )
              "
              dense
              class="white--text ml-4"
              :loading="isSaving"
              color="af_blue"
              @click="submit"
              data-intercom-target="Submit"
            >
              Submit <span v-if="isDemo">Disabled in demo mode</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { createPerformanceReviewTemplateOkrId } from "@/lib/idCreator";
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "PerformanceReviewForm",
  props: {},
  data: () => ({
    showLoginPrompt: false,
    isLoading: true,
    isSaving: false,
    isDemo: false,
    isCompleted: false,
    valid: false,
    // Array of question objects
    // { okrId, responsibility: "", result: "", percentage: ""}
    responsibilities: [],
    evaluationCategories: [],
    evaluationDescriptions: [
      "Put customers’ needs first;  Think long-term and always act on behalf of the company, not just your own team",
      "Be humble with a growth mindset",
      "Be responsible and demonstrate great attitude towards work; Take initiative and deliver high quality of work",
      "Invent and simplify; Drive for results",
    ],
    deadline: "",
    enableOKRPercentage: true,
    goalSettingMode: false,
    enableEvaluationPercentage: true,
    enableSelfAssessment: false,
    name: "",
    evaluationTitle: "",
    evaluationQuestion: "",
    evaluationAnswers: [],
    selfEvaluation: "",
    disableSelfEvaluation: false,
    sumError: false,
    noneEmptyRule: (v) => {
      return !!v || "Required";
    },
    numberRule: (v) => {
      if (!v) return true;
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v > 0 && v <= 100) return true;
      return "Number has to be between 0 and 100";
    },
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    personId() {
      return this.myPersonId(this.boardId);
    },
    // Check eligibility
    // This is Weee specific stuff
    eligible() {
      console.log("eligibility check", this.uid);

      // // This is hard coded for Weee
      // // We should make this dynamic
      if (
        !["prt_0qp0BdnHjjndqvbgDKRc13", "prt_CvQ4eiPwzXO6ktrNr47aS"].includes(
          this.templateId
        )
      ) {
        return true;
      }

      // White list some of the employees that's having an issue.
      let whitelisted = ["AQXatqREAZToVaIfsJPhZ0qiHWF3"];
      console.log("whitelisted checking", whitelisted.includes(this.uid));
      if (this.uid === "AqUVYq4aaZeyEWFu01drzzRktlG2") return true;
      if (whitelisted.includes(this.uid)) return true;

      console.log(
        "employeesByPersonId fetched -",
        this.employeesByPersonId ? "yes" : "no"
      );
      console.log("user personId", this.myPersonId(this.boardId));
      if (this.employeesByPersonId && this.myPersonId(this.boardId)) {
        // Only enable this for Weee employees
        if (!this.user.email.includes("@sayweee.com")) {
          return true;
        }

        const startDate =
          this.employeesByPersonId[
            this.myPersonId(this.boardId)
          ].employeeData.startDate.trim();
        console.log("start date", startDate);
        const mStartDate = this.$moment(startDate, "MM/DD/YYYY");
        const comparedDate = this.$moment("10/01/2021", "MM/DD/YYYY");
        console.log("isBefore Oct 1, 2021?", mStartDate.isBefore(comparedDate));
        return mStartDate.isBefore(comparedDate);
      }

      return false;
    },

    // Weee made an error in the form. We need to allow the employees to make edits temporarily.
    editableFormAfterSubmission() {
      // Added editable form templates here
      // const editableForms = [
      //   "prt_7afqg1C9w3ViGMsy4eRaN",
      //   "prt_rEdR2TvDcQDjBSC1hTgJU",
      //   "prt_cbRCvV5RUwDKE9dANcACTla",
      // ];

      // return editableForms.includes(this.templateId);
      // return true;
      return !this.isCompleted;
    },
  },
  watch: {},
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      "fetchPerformanceReviewTemplate",
      "createPerformanceReviewAnswers",
      "fetchPerformanceReviewAnswer",
      "saveDraftPerformanceReviewAnswers",
      "loadDraft",
      "fetchPeople",
    ]),
    getSum(arr) {
      return this._.chain(arr)
        .map((r) => {
          return this._.toNumber(r.percentage);
        })
        .sum()
        .value();
    },
    async init() {
      const ret = await this.fetchPerformanceReviewTemplate({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      if (ret === "error") {
        // throw new Error("error fetching performence review template");
        // This is mostly likely due to not authenticated user. Show a login dialog.
        this.isLoading = false;
        this.showLoginPrompt = true;

        // check if this user should be invited into the org
        var autoAcceptInvite = firebase
          .functions()
          .httpsCallable("autoAcceptInvite");
        // TODO catch errors
        const completed = await autoAcceptInvite({ boardId: this.boardId });
        if (completed) window.location.reload();
        return;
      }

      if (this.$route.query.demo) {
        // This is a demo view. Disable submit button
        this.isDemo = true;
      }

      await this.fetchPeople({ boardId: this.boardId });

      this.name = ret.name;

      this.evaluationTitle = ret.evaluationTitle;
      if (ret.responsibilities) this.responsibilities = ret.responsibilities;
      if (ret.deadline) this.deadline = ret.deadline;

      if (ret.evaluationCategories?.length > 0)
        this.evaluationCategories = ret.evaluationCategories;

      // populate evaluation ansers
      this.evaluationAnswers = this._.map(this.evaluationCategories, (cat) => {
        return { category: cat, assessment: "", percentage: "" };
      });

      if (ret.enableEvaluationPercentage !== "undefined")
        this.enableEvaluationPercentage = ret.enableEvaluationPercentage;
      if (ret.enableOKRPercentage !== "undefined")
        this.enableOKRPercentage = ret.enableOKRPercentage;
      if (ret.enableEvaluationPercentage !== "undefined")
        this.enableEvaluationPercentage = ret.enableEvaluationPercentage;
      if (ret.enableSelfAssessment !== "undefined")
        this.enableSelfAssessment = ret.enableSelfAssessment;

      if (ret.disableSelfEvaluation)
        this.disableSelfEvaluation = ret.disableSelfEvaluation;
      if (ret.evaluationQuestion)
        this.evaluationQuestion = ret.evaluationQuestion;

      if (ret.goalSettingMode !== "undefined")
        this.goalSettingMode = ret.goalSettingMode;

      this.isLoading = false;
      // Check if this form was submitted by the user already
      try {
        if (this.myPersonId(this.boardId)) {
          const existingAnswer = await this.fetchPerformanceReviewAnswer({
            boardId: this.boardId,
            templateId: this.templateId,
            user: this.user,
            personId: this.myPersonId(this.boardId),
          });

          if (existingAnswer) {
            this.isCompleted = existingAnswer.isSubmitted
              ? existingAnswer.isSubmitted
              : false;

            if (existingAnswer.responsibilities)
              this.responsibilities = existingAnswer.responsibilities;

            if (existingAnswer.evaluations)
              this.evaluationAnswers = existingAnswer.evaluations;

            if (existingAnswer.selfEvaluation)
              this.selfEvaluation = existingAnswer.selfEvaluation.replace(
                new RegExp("<br/>", "g"),
                "\n"
              );
          }
        }
      } catch (e) {
        console.error(e);
      }

      // Is it's not completed yet, check if there is a draft
      if (this.myPersonId(this.boardId) && !this.isCompleted) {
        const draft = await this.loadDraft({
          boardId: this.boardId,
          templateId: this.templateId,
          personId: this.myPersonId(this.boardId),
        });

        if (draft) {
          if (draft.responsibilities)
            this.responsibilities = draft.responsibilities;

          if (draft.evaluations) this.evaluationAnswers = draft.evaluations;

          if (draft.selfEvaluation)
            this.selfEvaluation = draft.selfEvaluation.replace(
              new RegExp("<br/>", "g"),
              "\n"
            );
        }
      }
    },
    addOKR() {
      this.responsibilities.push({
        okrId: createPerformanceReviewTemplateOkrId(),
        responsibility: "",
        result: "",
        percentage: 0,
      });
    },
    validate() {
      return this.$refs.form.validate();
    },
    async saveDraft() {
      this.isSaving = true;

      await this.saveDraftPerformanceReviewAnswers({
        boardId: this.boardId,
        templateId: this.templateId,
        user: this.user,
        personId: this.myPersonId(this.boardId),
        responsibilities: this.responsibilities,
        evaluations: this.evaluationAnswers,
        selfEvaluation: this.selfEvaluation.replace(/(?:\r\n|\r|\n)/g, "<br/>"),
      });
      this.isSaving = false;
    },
    getBehaviourLabel() {
      if (this.name.includes("Goal Setting"))
        return "How do you plan to exemplify the company leadership principles?";

      return "How do you exemplify the company leadership principles?";
    },
    isSectionCompleted() {
      let responsibilities = this._.map(this.responsibilities, (res) => {
        return res.responsibility || res.percentage;
      });
      responsibilities = this._.compact(responsibilities);

      let evaluationAnswers = this._.map(this.evaluationAnswers, (res) => {
        return res.percentage || res.assessment;
      });
      evaluationAnswers = this._.compact(evaluationAnswers);

      const selfEvaluation = this.selfEvaluation.length > 0;

      return {
        responsibilities: responsibilities.length > 0,
        evaluationAnswers: evaluationAnswers.length > 0,
        selfEvaluation,
      };
    },
    async submit() {
      // const percentageSum = this._.sumBy(this.responsibilities, (res) => {
      //   return res.percentage ? parseInt(res.percentage) : 0;
      // });

      // const evaluationAnswerSum = this._.sumBy(
      //   this.evaluationAnswers,
      //   (ans) => {
      //     return ans.percentage ? parseInt(ans.percentage) : 0;
      //   }
      // );

      // if (evaluationAnswerSum != 100 || percentageSum != 100) {
      //   this.sumError = true;
      //   return;
      // }

      if (this.validate()) {
        this.isSaving = true;

        await this.createPerformanceReviewAnswers({
          boardId: this.boardId,
          templateId: this.templateId,
          user: this.user,
          personId: this.myPersonId(this.boardId),
          responsibilities: this.responsibilities,
          evaluations: this.evaluationAnswers,
          isSubmitted: true,
          selfEvaluation: this.selfEvaluation.replace(
            /(?:\r\n|\r|\n)/g,
            "<br/>"
          ),
        });
        await this.saveDraft();
        this.isSaving = false;
        this.isCompleted = true;
      }
    },
  },
};
</script>

<style scoped>
td,
th {
  border-bottom: none !important;
}

.red-border {
  border: 2px red solid !important;
}
</style>
