<template>
  <div>
    <v-card
      v-if="template && accessGranted"
      style="
        border: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      "
      class="pa-12"
    >
      <v-row class="">
        <v-col cols="12">
          <h1 v-if="person">Reviewee: {{ person.name }}</h1>
          <h3 v-if="answers">
            Submitted at
            {{
              answers.createdAt.seconds | moment("ddd, MMM D YYYY, h:mm:ss a")
            }}
          </h3>
        </v-col>
      </v-row>

      <v-form ref="form">
        <v-simple-table v-if="answers" class="mt-4">
          <template v-slot:default>
            <thead>
              <tr>
                <th><h2>Objectives</h2></th>
                <th v-if="!template.goalSettingMode">
                  <h2>Achievement/Result</h2>
                </th>
                <th v-if="template.goalSettingMode"><h2>Target</h2></th>
                <th v-if="template.goalSettingMode"><h2>Action</h2></th>
                <th v-if="template.enableOKRPercentage">
                  <h2 class="text-left">Weighted Importance (Total to 100%)</h2>
                </th>
                <th v-if="template.enableEvaluationPercentage">
                  <h2>Score</h2>
                </th>
                <th v-if="template.enableEvaluationPercentage">
                  <h2>Result</h2>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                no-gutters
                v-for="res in answers.responsibilities"
                :key="res.okrId"
              >
                <td>
                  <p>{{ res.responsibility }}</p>
                </td>
                <td v-if="!template.goalSettingMode">
                  <p>{{ res.result }}</p>
                </td>
                <td v-if="template.goalSettingMode">
                  <p>{{ res.target }}</p>
                </td>
                <td v-if="template.goalSettingMode">
                  <p>{{ res.action }}</p>
                </td>
                <td v-if="template.enableOKRPercentage">
                  <p>{{ res.percentage }}%</p>
                </td>
                <td
                  v-if="template.enableEvaluationPercentage"
                  style="width: 150px"
                >
                  <p>{{ res.score }}</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-simple-table v-if="answers" class="mt-8">
          <template v-slot:default>
            <thead>
              <tr>
                <th><p>Evaluation of Leadership Principles</p></th>
                <th><p>Assessment</p></th>
                <th v-if="template.enableEvaluationPercentage">
                  <p>Percentage</p>
                </th>
                <th
                  v-if="template.enableEvaluationPercentage && eva.percentage"
                >
                  <p>Score</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr no-gutters v-for="(eva, k) in answers.evaluations" :key="k">
                <td>
                  <p class="mt-2">{{ eva.category }}</p>
                </td>
                <td v-if="eva.assessment">
                  <p>{{ eva.assessment }}</p>
                </td>
                <td
                  v-if="template.enableEvaluationPercentage && eva.percentage"
                >
                  <p>{{ eva.percentage }}%</p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row v-if="answers.selfEvaluation" class="mt-6">
          <v-col cols="12">
            <h3>Employee Self Evaluation</h3>

            <p class="">
              <i>"{{ template.evaluationQuestion }}"</i>
            </p>

            <p v-if="answers" v-html="answers.selfEvaluation"></p>
          </v-col>
        </v-row>

        <v-divider />

        <v-row class="mt-12">
          <v-col cols="12">
            <!-- <h1>Final Rating: {{ rating }}</h1> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceReviewResponse",
  props: {},
  data: () => ({
    ratings: [
      "Outstanding",
      "Exceeded Expectation",
      "Met Expectation",
      "Below Expectation",
      "Needs Improvement",
    ],
    accessGranted: false,
    isSaving: false,
    isCompleted: false,
    answers: null,
    template: null,
    rating: "",
    totalResult: 0,
    responsibilityScoreTotal: 0,
    evaluationsTotal: 0,
    componentKey: 0,
    person: null,
    isVP: true,
    isCEO: false,
    numberRule: (v) => {
      if (!v) return true;
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
      return "Number has to be between 0 and 100";
    },
    noneEmptyRule: (v) => {
      return !!v || "Required";
    },
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "isAdmin",
      "activeEmployees",
      "user",
      "people",
      "role",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    targetPersonId() {
      return this.$route.params.personId;
    },
    user() {
      return this.$store.state.user.userObj;
    },
    boards() {
      return this.$store.state.board.boards;
    },
  },
  watch: {
    boards: function () {
      if (this.boards.length > 0) {
        if (["admin", "executive", "hr"].includes(this.role(this.boardId))) {
          this.accessGranted = true;
        }

        if (this.user.email === this.person?.email) {
          this.accessGranted = true;
        }
      }
    },
  },
  async mounted() {
    this.getRevieweeProfile();

    this.answers = await this.fetchPerformanceReviewAnswer({
      boardId: this.boardId,
      templateId: this.templateId,
      personId: this.targetPersonId,
    });

    if (this.answers) {
      this.isCompleted = true;
      this.answers.responsibilities = this._.filter(
        this.answers.responsibilities,
        (res) => {
          return (
            res.responsibility ||
            res.result ||
            res.percentage ||
            res.action ||
            res.target
          );
        }
      );

      this._.each(this.answers.responsibilities, (res) => {
        if (res.score === undefined || !res.score) res.score = null;
        if (res.managerResult === undefined || !res.managerResult)
          res.managerResult = 0;

        this.computeResult(res);
      });

      this._.each(this.answers.evaluations, (eva) => {
        if (eva.score === undefined || !eva.score) eva.score = null;
        if (eva.managerResult === undefined || !eva.managerResult)
          eva.managerResult = 0;

        this.computeResult(eva);
      });

      if (this.answers.comment) {
        this.comment = this.answers.comment;
        this.comment = this.comment.replaceAll("<br/>", "\n");
      }

      if (this.answers.finalRating) this.finalRating = this.answers.finalRating;
      if (this.answers.VPRating) this.VPRating = this.answers.VPRating;

      console.log(this.answers);
    }

    this.template = await this.fetchPerformanceReviewTemplate({
      boardId: this.boardId,
      templateId: this.templateId,
    });

    await this.fetchPeople({ boardId: this.boardId });

    // if (
    //   this.isAdmin(this.boardId) ||
    //   this.employeesByPersonId[this.myPersonId(this.boardId)].role === "ceo"
    // ) {
    //   this.isCEO = true;
    //   if (this.answers.CEORating) this.CEORating = this.answers.CEORating;
    // }
    this.getTotalResult();

    // Is it's not completed yet, check if there is a draft
    if (this.myPersonId(this.boardId) && !this.isCompleted) {
      const draft = await this.loadDraft({
        boardId: this.boardId,
        templateId: this.templateId,
        personId: this.myPersonId(this.boardId),
      });

      if (draft) {
        this.answers = draft;

        if (draft.responsibilities)
          this.responsibilities = draft.responsibilities;

        if (draft.evaluations) this.evaluationAnswers = draft.evaluations;

        if (draft.selfEvaluation)
          this.selfEvaluation = draft.selfEvaluation.replace(
            new RegExp("<br/>", "g"),
            "\n"
          );
      }
    }
    this.fetchFinalRating();
    // window.print();
  },
  methods: {
    ...mapActions([
      "fetchPerformanceReviewRatingOnly",
      "fetchPerformanceReviewAnswer",
      "fetchPerformanceReviewTemplate",
      "updatePerformanceReviewAnswers",
      "updatePerformanceReviewCEORating",
      "fetchPerson",
      "fetchPeople",
      "getVPMapping",
      "loadDraft",
    ]),

    getTotalResult() {
      if (this.answers) {
        this.responsibilityScoreTotal = this._.sumBy(
          this.answers.responsibilities,
          (res) => {
            return res.score
              ? (parseInt(res.score) * parseInt(res.percentage)) / 100
              : 0;
          }
        );

        this.evaluationsTotal = this._.sumBy(
          this.answers.evaluations,
          (eva) => {
            return eva.score
              ? (parseInt(eva.score) * parseInt(eva.percentage)) / 100
              : 0;
          }
        );
      }

      // this.totalResult = sum1 + sum2;
      // this.averageResult =
      //   this.totalResult /
      //   (this.answers.responsibilities.length +
      //     this.answers.evaluations.length);
      // this.averageResult = this.averageResult.toFixed(2);
    },
    computeResult(res) {
      if (res.percentage) {
        // If percentage exists, calculate the result
        // Result = percentage x score
        res.managerResult = (
          (parseInt(res.percentage) / 100) *
          parseInt(res.score)
        ).toFixed(2);
        this.getTotalResult();
        this.forceRerender();
      }
    },
    async getRevieweeProfile() {
      this.person = await this.fetchPerson({
        boardId: this.boardId,
        personId: this.targetPersonId,
      });

      if (this.isCEO || this.isAdmin(this.boardId)) {
        this.CEORating = this.answers?.CEORating;
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },

    async fetchFinalRating() {
      const rating = await this.fetchPerformanceReviewRatingOnly({
        boardId: this.boardId,
        revieweePersonId: this.targetPersonId,
        templateId: this.templateId,
      });
      this.rating = rating.finalRating;
    },
  },
};
</script>
