import Vue from "vue";
import Vuex from "vuex";
// import firebase from "firebase/app";
// import "firebase/firestore";

import user from "./user.module";
import team from "./team.module";
import board from "./board.module";
import proposals from "./proposals.module";
import personHistory from "./personHistory.module";
import project from "./project.module";
import bambooHR from "./bambooHR.module";
import orgHistory from "./orgHistory.module";
import settings from "./settings.module";
import requisition from "./requisition.module";
import requisitionComments from "./requisition.comments.module";
import requisitionHistory from "./requisition.history.module";
import plans from "./plans.module";
import squads from "./squads.module";
import performance from "./performance.module";
import performanceReview from "./performanceReview.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    team,
    user,
    board,
    proposals,
    personHistory,
    project,
    bambooHR,
    orgHistory,
    requisition,
    settings,
    requisitionComments,
    requisitionHistory,
    plans,
    squads,
    performance,
    performanceReview,
  },
});
