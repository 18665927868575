/* eslint-disable no-unused-vars */
import _ from "lodash";
import numeral from "numeral";
import { DEFAULT_AUTO_TREE_CONFIG } from "../config";
import dagre from "dagre";

/**
 * Return a list of line groups based on people data
 *
 * @param {Object} peopleData
 * @returns {Object}
 * e.g.) groupList =>
[[
  ["SkbfDZ7vZj"] // manager id list,[
  ["ArXVOwiRL_", ... , "DfWiDkSk", // subordinate id list
  ]
]]
 */
const getLineGroupByPeople = (peopleData) =>
  peopleData
    .filter((person) => person.subordinates && person.subordinates.length > 0)
    .map((person) => [[person.personId], person.subordinates]);

/**
 * Returns the actual directionX and directionY value of the current CurvePath
 *
 * @param {*} path
 * @returns { directionX, directionY }
 */
const getActualDirectionByPath = (path) => {
  const directionX = path.directionX;
  const directionY = path.directionY;

  const isFlipX = path.flipX;
  const isFlipY = path.flipY;

  return {
    directionX: isFlipX
      ? directionX === "right"
        ? "left"
        : "right"
      : directionX,
    directionY: isFlipY
      ? directionY === "bottom"
        ? "top"
        : "bottom"
      : directionY,
  };
};

/**
 * Returns a list of all root manager IDs in the people data list
 *
 * @param {*} peopleData
 * @returns
 */
const getRootPersonIds = (peopleData) => {
  const managerList = peopleData.filter(
    (people) => !!people.subordinates && people.subordinates.length > 0
  );
  const totalSubordinateIds = managerList
    .map((people) => people.subordinates)
    .reduce((prev, cur) => [...prev, ...cur], []);

  return managerList
    .filter((manager) => !totalSubordinateIds.includes(manager.personId))
    .map((manager) => manager.personId);
};

/**
 * Position update in a basic tree structure that does not take into account the position overlap of sub-nodes
 *
 * @param {*} peopleData
 * @param {*} managerId
 * @param {*} gap         Default gap x,y value.
 * @param {*} customGap   Custom gap x,y value
 * @returns
 */
const updateSubordinatesBasicPosition = (peopleData, managerId, gap) => {
  const managerData = peopleData.find(
    (people) => people.personId === managerId
  );

  if (!managerData) return;
  if (!managerData.subordinates || managerData.subordinates.length === 0) {
    return peopleData;
  }

  const subordinates = managerData.subordinates
    .map((personId) =>
      peopleData.find((people) => people.personId === personId)
    )
    .filter((person) => person)
    .sort((a, b) => a.boardCoordinateX - b.boardCoordinateX);

  let gapX = gap.x;
  let gapY = gap.y;

  // Update position.
  const startPointX =
    managerData.boardCoordinateX + -(((subordinates.length - 1) / 2) * gapX);
  const startPointY = managerData.boardCoordinateY + gapY;
  for (let i = 0; i < subordinates.length; i++) {
    subordinates[i].boardCoordinateX = startPointX + i * gapX;
    subordinates[i].boardCoordinateY = startPointY;
    updateSubordinatesBasicPosition(peopleData, subordinates[i].personId, gap);
  }
};

/**
 * Makes the spacing of sub-nodes
 * For each level, the dimensions of the tree node group are compared to increase the gap.
 * PeopleData will be updated in order from the lowest level to the root.
 *
 * @param {*} peopleData
 * @param {*} managerId
 * @param {*} gap
 * @param {*} customGap   Custom gap x,y value
 */
const updateSubordinatesOverlapPosition = (peopleData, managerId, gap) => {
  const managerData = peopleData.find(
    (people) => people.personId === managerId
  );

  if (!managerData) return;

  let peoplePerLevel = getPeoplePerLevel(managerData, peopleData);

  const keys = Object.keys(peoplePerLevel)
    .map((level) => parseInt(level))
    .sort((a, b) => a - b);

  for (let i = keys.length - 2; i >= 0; i--) {
    let groupDimension = [];
    peoplePerLevel[keys[i]].forEach((x) => {
      groupDimension.push(
        peopleData
          .filter((people) => x.subordinates.includes(people.personId))
          .map((people) => ({
            x: people.boardCoordinateX,
            personId: people.personId,
          }))
          .reduce(
            (prev, cur) => {
              return {
                minX: prev.minX ? Math.min(prev.minX, cur.x) : cur.x,
                maxX: prev.maxX ? Math.max(prev.maxX, cur.x) : cur.x,
                personId: x.personId,
              };
            },
            { minX: null, maxX: null, personId: null }
          )
      );
    });

    // Since It's added in order from the root of the tree, use `reverse` to organize the order.
    // If we change the order using the position of the avatar, the order may be organized incorrectly due to a tree structure problem.
    groupDimension = groupDimension.filter((x) => !!x.personId).reverse();

    let leftIndex = Math.floor(groupDimension.length / 2) - 1;
    let rightIndex = leftIndex + 1;
    for (let j = 0; j < groupDimension.length - 1; j++) {
      let gapX = gap.x;

      // Ignore root node
      if (groupDimension.length <= 1) return;

      // first step
      if (j === 0) {
        const isCollapsed =
          groupDimension[leftIndex].personId !==
            groupDimension[leftIndex + 1].personId &&
          groupDimension[leftIndex].maxX === groupDimension[leftIndex].minX &&
          groupDimension[leftIndex + 1].maxX ===
            groupDimension[leftIndex + 1].minX;
        if (
          !isCollapsed &&
          groupDimension[leftIndex].maxX + gapX / 2 >=
            groupDimension[leftIndex + 1].minX - gapX / 2
        ) {
          const padding = Math.round(
            groupDimension[leftIndex].maxX -
              groupDimension[leftIndex + 1].minX +
              gapX
          );
          const targetPerson1 = peopleData.find(
            (people) => people.personId === groupDimension[leftIndex].personId
          );
          const targetPerson2 = peopleData.find(
            (people) =>
              people.personId === groupDimension[leftIndex + 1].personId
          );

          if (targetPerson1 && targetPerson2) {
            groupDimension[leftIndex + 1].maxX += padding;
            groupDimension[leftIndex + 1].minX += padding;
            targetPerson2.boardCoordinateX =
              Math.floor(
                (groupDimension[leftIndex + 1].maxX -
                  groupDimension[leftIndex + 1].minX) /
                  2
              ) + groupDimension[leftIndex + 1].minX;
            updateOffsetAllChildren(targetPerson2, peopleData, padding);
          }
        }

        leftIndex--;
      } else if (j % 2 === 0) {
        // Even number
        const isCollapsedGroup =
          groupDimension[leftIndex].personId !==
            groupDimension[leftIndex + 1].personId &&
          groupDimension[leftIndex].maxX === groupDimension[leftIndex].minX &&
          groupDimension[leftIndex + 1].maxX ===
            groupDimension[leftIndex + 1].minX;

        if (
          !isCollapsedGroup &&
          groupDimension[leftIndex].maxX + gapX >=
            groupDimension[leftIndex + 1].minX
        ) {
          const padding = Math.round(
            groupDimension[leftIndex].maxX -
              groupDimension[leftIndex + 1].minX +
              gapX
          );

          const targetPerson1 = peopleData.find(
            (people) => people.personId === groupDimension[leftIndex].personId
          );
          const firstParent = peopleData.find((person) =>
            person?.subordinates?.includes(groupDimension[leftIndex].personId)
          );
          const secondParent = peopleData.find((person) =>
            person?.subordinates?.includes(
              groupDimension[leftIndex + 1].personId
            )
          );
          const isSameParentGroup =
            firstParent.personId === secondParent.personId;

          if (targetPerson1) {
            groupDimension[leftIndex].maxX += -padding;
            groupDimension[leftIndex].minX += -padding;

            // Check it has the same parent node, and if they are the same, only update the positions of the next group.
            // If not, update all child avatar positions of the next parent node
            if (isSameParentGroup) {
              const newPadding =
                targetPerson1.boardCoordinateX -
                Math.floor(
                  (groupDimension[leftIndex].minX +
                    groupDimension[leftIndex].maxX) /
                    2
                );
              targetPerson1.boardCoordinateX += -newPadding;
              updateOffsetAllChildren(targetPerson1, peopleData, -newPadding);
            } else {
              firstParent.subordinates.forEach((childrenPersonId) => {
                const childTargetPerson = peopleData.find(
                  (people) => people.personId === childrenPersonId
                );
                childTargetPerson.boardCoordinateX += -padding;
                updateOffsetAllChildren(
                  childTargetPerson,
                  peopleData,
                  -padding
                );
              });
            }

            // Align the parent node's position to the center, ignore if it is the root node
            if (firstParent.personId !== managerId) {
              const parentGroupDimension = firstParent?.subordinates
                .map((personId) =>
                  peopleData.find((person) => person.personId === personId)
                )
                .filter((person) => person)
                .map((people) => ({ x: people.boardCoordinateX }))
                .reduce(
                  (prev, cur) => {
                    return {
                      minX: prev.minX ? Math.min(prev.minX, cur.x) : cur.x,
                      maxX: prev.maxX ? Math.max(prev.maxX, cur.x) : cur.x,
                    };
                  },
                  { minX: null, maxX: null }
                );
              const centerX = Math.floor(
                (parentGroupDimension.minX + parentGroupDimension.maxX) / 2
              );
              firstParent.boardCoordinateX = centerX;
            }
          }
        }

        leftIndex--;
      } else {
        // Odd number
        const isCollapsedGroup =
          groupDimension[rightIndex].personId !==
            groupDimension[rightIndex + 1].personId &&
          groupDimension[rightIndex].maxX === groupDimension[rightIndex].minX &&
          groupDimension[rightIndex + 1].maxX ===
            groupDimension[rightIndex + 1].minX;

        if (
          !isCollapsedGroup &&
          groupDimension[rightIndex].maxX + gapX >=
            groupDimension[rightIndex + 1].minX
        ) {
          const padding = Math.round(
            groupDimension[rightIndex].maxX -
              groupDimension[rightIndex + 1].minX +
              gapX
          );

          const targetPerson2 = peopleData.find(
            (people) =>
              people.personId === groupDimension[rightIndex + 1].personId
          );
          const firstParent = peopleData.find((person) =>
            person?.subordinates?.includes(groupDimension[rightIndex].personId)
          );
          const secondParent = peopleData.find((person) =>
            person?.subordinates?.includes(
              groupDimension[rightIndex + 1].personId
            )
          );
          const isSameParentGroup =
            firstParent.personId === secondParent.personId;

          if (targetPerson2) {
            groupDimension[rightIndex + 1].maxX += padding;
            groupDimension[rightIndex + 1].minX += padding;

            // Check it has the same parent node, and if they are the same, only update the positions of the next group.
            // If not, update all child avatar positions of the next parent node
            if (isSameParentGroup) {
              const newPadding =
                targetPerson2.boardCoordinateX -
                Math.floor(
                  (groupDimension[rightIndex + 1].minX +
                    groupDimension[rightIndex + 1].maxX) /
                    2
                );
              targetPerson2.boardCoordinateX += -newPadding;
              updateOffsetAllChildren(targetPerson2, peopleData, -newPadding);
            } else {
              secondParent.subordinates.forEach((childrenPersonId) => {
                const childTargetPerson = peopleData.find(
                  (people) => people.personId === childrenPersonId
                );
                if (childTargetPerson) {
                  childTargetPerson.boardCoordinateX += padding;
                  updateOffsetAllChildren(
                    childTargetPerson,
                    peopleData,
                    padding
                  );
                }
              });
            }

            // Align the parent node's position to the center, ignore if it is the root node
            if (firstParent.personId !== managerId) {
              const parentGroupDimension = secondParent?.subordinates
                .map((personId) =>
                  peopleData.find((person) => person.personId === personId)
                )
                .filter((person) => person)
                .map((people) => ({ x: people.boardCoordinateX }))
                .reduce(
                  (prev, cur) => {
                    return {
                      minX: prev.minX ? Math.min(prev.minX, cur.x) : cur.x,
                      maxX: prev.maxX ? Math.max(prev.maxX, cur.x) : cur.x,
                    };
                  },
                  { minX: null, maxX: null }
                );
              const centerX = Math.floor(
                (parentGroupDimension.minX + parentGroupDimension.maxX) / 2
              );
              secondParent.boardCoordinateX = centerX;
            }
          }
        }

        rightIndex++;
      }
    }
  }
};

/**
 * Makes the spacing of manager nodes
 * Compare the positions of the same level and move all nodes in the corresponding direction.
 *
 * @param {*} peopleData
 * @param {*} managerId
 * @param {*} gap         Default gap x,y value.
 * @param {*} customGap   Custom gap x,y value
 */
const updateManagerOverlapPosition = (
  peopleData,
  managerId,
  gap,
  customGap
) => {
  const managerData = peopleData.find(
    (people) => people.personId === managerId
  );
  const customGapPersonIds = customGap.map((data) => data.personId);
  if (!managerData) return;
  let peoplePerLevel = getPeoplePerLevel(managerData, peopleData);

  const keys = Object.keys(peoplePerLevel)
    .map((level) => parseInt(level))
    .sort((a, b) => a - b);

  for (let i = keys.length - 2; i >= 0; i--) {
    let managerDimension = [];
    peoplePerLevel[keys[i]].forEach((x) => {
      managerDimension.push({
        x: x.boardCoordinateX,
        personId: x.personId,
      });
    });

    managerDimension = managerDimension
      .filter((x) => !!x.personId)
      .sort((a, b) => a.x - b.x);

    const hiddenPersonId = customGap
      .filter((x) => x.gap.x === 0)
      .map((x) => x.personId);

    managerDimension = managerDimension.filter((x) => {
      return !hiddenPersonId.includes(x.personId);
    });

    let leftIndex = Math.floor(managerDimension.length / 2) - 1;
    let rightIndex = leftIndex + 1;
    for (let j = 0; j < managerDimension.length - 1; j++) {
      let gapX = gap.x;

      // Update gap if customGap exists.
      let startManager, endManager;
      let startIndex, endIndex;
      if (j % 2 === 0) {
        startIndex = leftIndex;
        endIndex = leftIndex + 1;
      } else {
        startIndex = rightIndex;
        endIndex = rightIndex + 1;
      }
      if (
        customGapPersonIds.includes(managerDimension[startIndex].personId) &&
        customGapPersonIds.includes(managerDimension[endIndex].personId)
      ) {
        startManager = peopleData.find((person) =>
          person?.subordinates?.includes(managerDimension[startIndex].personId)
        );
        endManager = peopleData.find((person) =>
          person?.subordinates?.includes(managerDimension[endIndex].personId)
        );
      }
      if (
        startManager &&
        endManager &&
        startManager.personId === endManager.personId
      ) {
        gapX = customGapPersonIds.find(
          (data) => data.personId === startManager.personId
        );
      }

      // Update position.
      if (j % 2 === 0) {
        // Even number
        if (
          managerDimension[leftIndex + 1].x - managerDimension[leftIndex].x <
          gapX
        ) {
          const padding = Math.round(
            managerDimension[leftIndex].x -
              managerDimension[leftIndex + 1].x +
              gapX
          );

          if (padding > 0) {
            for (let k = leftIndex; 0 <= k; k--) {
              const targetPerson = peopleData.find(
                (people) => people.personId === managerDimension[k].personId
              );

              if (targetPerson) {
                managerDimension[k].x -= padding;
                targetPerson.boardCoordinateX -= padding;
                updateOffsetAllChildren(targetPerson, peopleData, -padding);
              }
            }
          }
        }

        leftIndex--;
      } else {
        // Odd number
        if (
          managerDimension[rightIndex + 1].x - managerDimension[rightIndex].x <
          gapX
        ) {
          const padding = Math.round(
            managerDimension[rightIndex].x -
              managerDimension[rightIndex + 1].x +
              gapX
          );

          if (padding > 0) {
            for (let k = rightIndex + 1; k < managerDimension.length; k++) {
              const targetPerson = peopleData.find(
                (people) => people.personId === managerDimension[k].personId
              );

              if (targetPerson) {
                managerDimension[k].x += padding;
                targetPerson.boardCoordinateX += padding;
                updateOffsetAllChildren(targetPerson, peopleData, padding);
              }
            }
          }
        }

        rightIndex++;
      }
    }
  }
};

/**
 * Change the positions of all children nodes according to the offset
 *
 * @param {*} personData
 * @param {*} peopleData
 * @param {*} offset
 * @returns
 */
const updateOffsetAllChildren = (personData, peopleData, offset) => {
  if (!personData.subordinates || !personData.subordinates.length) return;

  personData.subordinates.forEach((personId) => {
    const targetPerson = peopleData.find(
      (people) => people.personId === personId
    );
    if (targetPerson) {
      targetPerson.boardCoordinateX += offset;
      updateOffsetAllChildren(targetPerson, peopleData, offset);
    }
  });
};

/**
 * Return an object with the y value of the avatar as the key
 *
 * @param {*} person
 * @param {*} peopleData
 * @param {*} [result={}]
 * @returns
 */
const getPeoplePerLevel = (person, peopleData, result = {}) => {
  if (!person) return;
  const targetLevel = Math.floor(person.boardCoordinateY);
  if (!result[targetLevel]) {
    result[targetLevel] = [person];
  } else {
    result[targetLevel].push(person);
  }
  if (person.subordinates) {
    person.subordinates.forEach((personId) => {
      const childPerson = peopleData.find((x) => x.personId === personId);
      if (childPerson) {
        getPeoplePerLevel(childPerson, peopleData, result);
      }
    });
  }

  return result;
};

/**
 * Change peopledata offset to fit gridsize
 *
 * @param {*} peopleData
 * @param {*} gridSize
 */
const updatePeopleOffsetByGrid = (peopleData, gridSize) => {
  peopleData.forEach((person) => {
    person.boardCoordinateX = Math.floor(
      person.boardCoordinateX - (person.boardCoordinateX % gridSize)
    );
    person.boardCoordinateY = Math.floor(
      person.boardCoordinateY - (person.boardCoordinateY % gridSize)
    );
  });
  return peopleData;
};

/**
 * A function that sorts the tree structure of peopleData.
 * Based on gap and gridSize, it automatically sorts all trees present on the current board.
 * It is sorted based on the location of the root node.
 *
 * @param {*} peopleData
 * @param {*} options
 * @returns
 */
const autoTreeAlignment = (peopleData, options) => {
  // initialize options
  options = { ...DEFAULT_AUTO_TREE_CONFIG, ...options };

  let newPeopleData = peopleData;

  // Ignore hidden avatar
  newPeopleData = newPeopleData.filter(
    (person) => !options.hiddenAvatarList.includes(person.personId)
  );

  const direction = options.direction ? options.direction : "TB"; // top to bottom direction
  // DagreGraph layout algorithm for layout
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });
  newPeopleData.forEach((person) => {
    dagreGraph.setNode(person.personId, {
      width: options.gap.x,
      height: options.gap.y,
    });
    person.subordinates.forEach((subordinateId) => {
      dagreGraph.setEdge(person.personId, subordinateId);
    });
  });
  dagre.layout(dagreGraph);
  return newPeopleData.map((person) => {
    const nodeWithPosition = dagreGraph.node(person.personId);
    person.boardCoordinateX = nodeWithPosition.x;
    person.boardCoordinateY = nodeWithPosition.y;
    return person;
  });

  // Get a list of all root nodes existing on the current board
  // const rootPersonIds = getRootPersonIds(peopleData);

  // rootPersonIds.forEach(rootPersonId => {
  //   newPeopleData = autoTreeAlignmentByPersonId(newPeopleData, rootPersonId, options);
  // });

  // return newPeopleData;
};

/**
 * A function that sorts the tree structure of peopleData.
 * Based on gap and gridSize, it automatically sorts nodes are under the rootPersonID.
 * It is sorted based on the location of the root node.
 *
 * @param {*} peopleData
 * @param {*} options
 * @returns
 */
const autoTreeAlignmentByPersonId = (peopleData, rootPersonId, options) => {
  // initialize options
  options = { ...DEFAULT_AUTO_TREE_CONFIG, ...options };

  let clonedPeopleData = _.cloneDeep(peopleData);

  // PHASE 1: Change all avatars to default positions based on the gap.
  updateSubordinatesBasicPosition(
    clonedPeopleData,
    rootPersonId,
    options.gap,
    options.customGap
  );

  // PHASE 2: Change the position of the avatar that is the child node exists, so that it does not overlap.
  updateSubordinatesOverlapPosition(
    clonedPeopleData,
    rootPersonId,
    options.gap
  );

  // PHASE 3: Change the position of avatars that do not have child nodes to overlap.
  updateManagerOverlapPosition(
    clonedPeopleData,
    rootPersonId,
    options.gap,
    options.customGap
  );

  // PHASE 4: Change the offset to fit the grid size
  updatePeopleOffsetByGrid(clonedPeopleData, options.gridSize);

  return clonedPeopleData;
};

/**
 * Create viewport boundary data the same as javascript's getBoundingClientRect
 *
 * @param {*} canvas
 * @returns
 */
const getBoundingCanvasRect = (canvas) => {
  var viewport = canvas.calcViewportBoundaries();

  const dimension = {
    x: viewport.tl.x,
    y: viewport.tl.y,
    width: viewport.br.x - viewport.tl.x,
    height: viewport.br.y - viewport.tl.y,
    left: viewport.tl.x,
    top: viewport.tl.y,
    right: viewport.br.x - viewport.tl.x,
    bottom: viewport.br.y - viewport.tl.y,
  };
  return dimension;
};
const getSalaryByGroup = (groupData, peopleData) => {
  const personSalaryList = peopleData
    .filter((person) => !!person?.employeeData?.salary)
    .reduce((prev, cur) => {
      const salary = cur.employeeData.salary
        .replace(/[a-zA-Z]/g, "")
        .replace("k", "000");
      prev[cur.personId] = numeral(salary).value();
      return prev;
    }, {});

  try {
    const sumGroupSalary = (personId) => {
      const managerSalary = personSalaryList[personId] || 0;
      const subordinates =
        groupData
          .filter((group) => group[0][0] === personId)
          .map((x) => x[1])[0] || [];
      let sum = managerSalary;
      subordinates.forEach((subPersonId) => {
        sum += sumGroupSalary(subPersonId);
      });
      return sum;
    };

    const result = groupData.map((group) => {
      const personId = group[0][0];
      const subordinates = group[1];
      let sum = 0;

      subordinates.forEach((personId) => {
        sum += sumGroupSalary(personId);
      });

      return {
        personId: personId,
        totalSalary: sum,
      };
    });

    return result;
  } catch (error) {
    console.error("group salary occured:", error);
    return [];
  }
};

const getPeopleCountByGroup = (groupData, peopleData) => {
  try {
    const sumSubordinatesCount = (personId) => {
      const subordinates =
        groupData
          .filter((group) => group[0][0] === personId)
          .map((x) => x[1])[0] || [];
      let sum = 1;
      subordinates.forEach((subPersonId) => {
        sum += sumSubordinatesCount(subPersonId);
      });
      return sum;
    };

    const result = groupData.map((group) => {
      const personId = group[0][0];
      const subordinates = group[1];
      let sum = 0;
      subordinates.forEach((personId) => {
        sum += sumSubordinatesCount(personId);
      });

      return {
        personId: personId,
        totalCount: sum,
      };
    });

    return result;
  } catch (error) {
    console.error("group's people counting occured:", error);
    return [];
  }
};

const getAllChildrenIds = (rootPersonId, peopleData) => {
  const targetPerson = peopleData.find(
    (person) => person.personId === rootPersonId
  );

  let result = targetPerson?.subordinates;

  targetPerson?.subordinates?.forEach((x) => {
    var temp = getAllChildrenIds(x, peopleData);
    result = result.concat(temp);
  });

  return result || [];
};

// Initialize nodes position when it first render
const getInitializedPeopleData = (peopleData) => {
  // 1. Generate ignoreCustomGap
  const rootPersonIds = getRootPersonIds(peopleData);
  const secondLevelPersonIds = rootPersonIds
    .map((rootPersonId) =>
      peopleData.find((person) => person.personId === rootPersonId)
    )
    .reduce((prev, cur) => {
      return cur.subordinates ? [...prev, ...cur.subordinates] : prev;
    }, []);
  const ignoreCustomGap = [...rootPersonIds, ...secondLevelPersonIds];

  // 2. Create autoTreeAlignment config to initialize tree position
  const config = {
    ignoreCustomGap: ignoreCustomGap,
  };

  // Initialize people position
  let newPeopleData = autoTreeAlignment(peopleData, config);

  // [Temp] solved the problem that the initial position sometimes had incorrect alignment
  // In the first call, the position is not properly returned, so call this function again. Need to be modified later
  newPeopleData = autoTreeAlignment(newPeopleData, config);

  return newPeopleData;
};

export {
  getLineGroupByPeople,
  getActualDirectionByPath,
  autoTreeAlignment,
  autoTreeAlignmentByPersonId,
  updatePeopleOffsetByGrid,
  getBoundingCanvasRect,
  getSalaryByGroup,
  getPeopleCountByGroup,
  getInitializedPeopleData,
  getRootPersonIds,
  getAllChildrenIds,
};

export default {
  getLineGroupByPeople,
  getActualDirectionByPath,
  autoTreeAlignment,
  autoTreeAlignmentByPersonId,
  updatePeopleOffsetByGrid,
  getBoundingCanvasRect,
  getSalaryByGroup,
  getPeopleCountByGroup,
  getInitializedPeopleData,
  getRootPersonIds,
  getAllChildrenIds,
};
