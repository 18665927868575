<template>
  <v-container>
    <!-- <v-row v-if="user" class="d-flex justify-space-between">
      <Invitations />
    </v-row> -->

    <PerformanceReviewReminder />

    <!-- <v-row v-if="user && userRole !== 'team_view'" class="mt-12">
      <v-col cols="12" md="3">
        <v-card flat class="py-4 pr-2" style="background: none">
          <h2 class="mt-4">
            {{ greetingText() }},
            <span v-if="user.displayName">
              {{ user.displayName.split(" ")[0] }}</span
            >
          </h2>
          <p class="mt-4 body-1">
            You’re looking at Agentnoon, your new tool to grow your company.
            Here’s a quick look at some of the things you can do with Agentnoon.
          </p>
        </v-card>
      </v-col>
      <v-col cols="12" md="8">
        <v-row
          class="flex-nowrap card-shadow py-4 px-6"
          style="overflow-x: scroll; border-radius: 8px"
          ref="action-item-wrapper"
        >
          <v-col
            v-if="userRole !== 'team_view' && userRole !== 'team_edit'"
            cols="12"
            md="4"
            class=""
          >
            <v-btn
              fab
              small
              elevation="0"
              class="af_blue--text"
              color="af_blue lighten-5"
              :to="`/plan/${boardId}`"
              ><v-icon>mdi-account-plus-outline</v-icon></v-btn
            >
            <div class="border-right">
              <h4 class="mt-2">Create hiring plans</h4>
              <p class="mt-4 body-1 grey--text">
                Create a hiring plan for your team. Align with HR and Finance by
                providing the right context.
              </p>
            </div>
          </v-col>

          <v-col v-if="isAdmin" cols="12" md="4">
            <v-btn
              fab
              small
              elevation="0"
              class="af_blue--text"
              color="af_blue lighten-5"
              :to="`/plan/review/${boardId}`"
              ><v-icon>mdi-check-circle-outline</v-icon></v-btn
            >
            <div class="border-right">
              <h4 class="mt-2">Review hiring plan</h4>
              <p class="mt-4 body-1 grey--text">
                Review all hiring plans submitted by the hiring managers.
              </p>
            </div>
          </v-col>

          <v-col cols="12" md="4" v-if="board">
            <CloningDialog
              :boardObj="board"
              :roundBtn="true"
              @created="onNewBoardCreated"
            ></CloningDialog>
            <div class="border-right">
              <h4 class="mt-2">New Scenario</h4>
              <p class="mt-4 body-1 grey--text pr-1">
                Make a copy of the org chart and create a new scenario. Scenario
                is private but you can share it with the team.
              </p>
            </div>
          </v-col>

          <v-col v-if="!isRecruiter" cols="12" md="4">
            <v-btn
              fab
              small
              elevation="0"
              class="af_blue--text"
              color="af_blue lighten-5"
              :to="`/squads/${boardId}`"
              ><v-icon>mdi-account-multiple-check-outline</v-icon></v-btn
            >
            <div class="border-right">
              <h4 class="mt-2">Manage Squads</h4>
              <p class="mt-4 body-1 grey--text pr-1">
                Plan cross functional teams by company's initiatives. The "real"
                org chart.
              </p>
            </div>
          </v-col>

          <v-col
            v-if="userRole !== 'team_view' && userRole !== 'team_edit'"
            cols="12"
            md="4"
          >
            <v-btn
              fab
              small
              elevation="0"
              class="af_blue--text"
              color="af_blue lighten-5"
              :to="`/board/${boardId}?action=openHiringRequest`"
              ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
            >
            <div class="border-right">
              <h4 class="mt-2">Requisition Request</h4>
              <p class="mt-4 body-1 grey--text">
                Create a well-written new hire request and get approved.
              </p>
            </div>
          </v-col>

          <v-col
            v-if="userRole !== 'team_view' && userRole !== 'team_edit'"
            cols="12"
            md="4"
          >
            <v-btn
              fab
              small
              elevation="0"
              class="af_blue--text"
              color="af_blue lighten-5"
              :to="`/requisitions/${boardId}`"
              ><v-icon>mdi-plus-circle-outline</v-icon></v-btn
            >
            <div class="">
              <h4 class="mt-2">Review Requests</h4>
              <p class="mt-4 body-1 grey--text">
                Review all hiring requests and the cost.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="1" class="d-flex align-center">
        <v-btn text class="af_blue--text" @click="scrollToRightMenus"
          ><v-icon>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row> -->
<!-- 

    <v-row v-if="board && isDemoBoard && userRole !== 'team_view'" class="my-6">
      <v-col cols="12" class="d-flex justify-center">
        <h3>{{ board.boardName }}</h3>
        <v-btn
          small
          color="af_blue"
          class="white--text ml-2"
          @click="showConfirmDelete = true"
          :disabled="showConfirmDelete"
          ><v-icon small class="mr-1">mdi-trash-can-outline</v-icon>Clear Demo
          Data</v-btn
        >
      </v-col>
    </v-row>
    <v-row
      v-if="showConfirmDelete"
      no-gutters
      class="mt-6 animate__animated animate__fadeIn"
    >
      <v-col cols="12" class="d-flex justify-center">
        <v-card class="card-shadow pa-6">
          <p class="af_red--text">Clear demo data?</p>
          <v-card-actions class="">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              :disabled="isDeleting"
              @click="showConfirmDelete = false"
              >Cancel</v-btn
            >
            <v-btn
              color="af_red"
              class="mr-2"
              outlined
              @click="deleteSampleOrg"
              :loading="isDeleting"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="userRole !== 'team_view'" class="mt-12">
      <OrgChart />
    </v-row> -->
  </v-container>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";

// import OrgChart from "@/views/OrgChart";
// import CloningDialog from "@/components/CloningDialog";
// import Invitations from "@/components/Invitations";
import PerformanceReviewReminder from "@/components/Calibration/PerformanceReviewReminder";
import { mapGetters } from "vuex";

export default {
  name: "CompanyView",
  components: {
    // OrgChart,
    // CloningDialog,
    // Invitations,
    PerformanceReviewReminder,
  },
  data: () => ({
    showConfirmDelete: false,
    isDeleting: false,
  }),
  computed: {
    ...mapGetters(["role", "user", "myPersonId", "employeesByPersonId"]),
    user() {
      return this.$store.state.user.userObj;
    },
    boardId() {
      return this.$route.params.boardId;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    board() {
      return this._.find(this.$store.state.board.boards, {
        boardId: this.boardId,
      });
    },
    isDemoBoard() {
      return this.board.isDemoBoard;
    },
    scenarios() {
      return this._.chain(this.boards)
        .filter((board) => {
          return !board.masterBranch && board.copiedBoardId === this.boardId;
        })
        .sortBy((board) => {
          return board.createdAt.seconds * -1;
        })
        .value();
    },
    isAdmin() {
      return this.$store.getters.isAdmin(this.boardId);
    },
    isRecruiter() {
      // Check if this user has recruiter access to the board
      if (!this.board) return false;
      if (!this.user) return false;

      const uid = this.user.uid;
      if (this.board.accessLevels) {
        const ret = this._.find(this.board.accessLevels, (accessObj) => {
          return accessObj.uid === uid;
        });

        if (ret.accessLevel === "recruiter") {
          return true;
        }
      }
      return false;
    },
    // Return the role of the user
    userRole() {
      if (!this.user) return null;
      if (!this.board) return null;
      const uid = this.user.uid;
      if (this.board.accessLevels) {
        const ret = this._.find(this.board.accessLevels, (accessObj) => {
          return accessObj.uid === uid;
        });
        return ret.accessLevel;
      }
      return "none";
    },
  },
  watch: {
    boards: function () {
      if (this.boards.length > 0) {
        console.log("isDemo", this.isDemoBoard);
        if (this.isDemoBoard) {
          this.$router.push({ query: { demo: true } });
        } else {
          this.$router.push({ query: { demo: false } });
          this.$router.replace({ "query.demo": null });
        }
      }
    },
  },
  mounted() {
    window.mixpanel.track("view_company_view");

    if (this.boardId === "bd_RIIyBlAv05LrJ1CJjkPCA") {
      window.location =
        "https://app.agentnoon.com/v2/#/board/bd_RIIyBlAv05LrJ1CJjkPCA";
      return;
    }

    if (this.user) {
      this.$store.dispatch("fetchAllBoards", this.user.uid);

      // We want to segment the users by their roles
      const myPersonId = this.myPersonId(this.boardId);

      if (window.heap) {
        window.heap.addUserProperties({ user_role: this.role(this.boardId) });
      }
      if (window.mixpanel) {
        window.mixpanel.people.union({ user_role: this.role(this.boardId) });
        if (this.employeesByPersonId[myPersonId])
          window.mixpanel.people.union({
            title: this.employeesByPersonId[myPersonId].role,
          });
      }
    }
  },
  methods: {
    greetingText() {
      const splitAfternoon = 12; // 24hr time to split the afternoon
      const splitEvening = 17; // 24hr time to split the evening
      const currentHour = parseFloat(new Date().getHours());

      if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return "Good afternoon";
      } else if (currentHour >= splitEvening) {
        // Between 5PM and Midnight
        return "Good evening";
      }
      // Between dawn and noon
      return "Good morning";
    },
    async deleteSampleOrg() {
      this.isDeleting = true;
      var deleteDemo = firebase.functions().httpsCallable("deleteDemo");
      const ret = await deleteDemo({
        boardId: this.boardId,
      });
      console.log(ret);
      window.mixpanel.track("demo_deleted");
      window.location = "/";
    },
    onNewBoardCreated() {
      console.log("new board created");
    },
    scrollToRightMenus() {
      this.$refs["action-item-wrapper"].scrollLeft += 100;
    },
    scrollToRightScenarios() {
      this.$refs["scenarios-wrapper"].scrollLeft += 100;
    },
  },
};
</script>

<style scoped>
.border-right {
  border-right: solid 2px #c8c8ce5c !important;
}
</style>
