<template>
  <v-app-bar
    dense
    light
    flat
    fixed
    color="white"
    style="border-bottom: 1px solid #eaeaea !important"
    class="no-print"
  >
    <v-toolbar-title>
      <v-img src="@/assets/Logo/Logo-Blue.svg" contain max-width="150"></v-img>
    </v-toolbar-title>

    <v-btn
      v-if="user && boardId && isAdmin"
      tile
      elevation="0"
      class="nav-btn"
      :href="`/#/PerformanceReview/${boardId}`"
      :class="[
        currentRouteName === 'Performance' ? 'nav-active nav-btn' : 'nav-btn',
      ]"
    >
      <v-icon dense light>mdi-poll</v-icon>
      <span class="mx-2">Performance Review</span>
    </v-btn>

    <v-spacer></v-spacer>

    <DeleteScenario v-if="user && boardId && isAdmin && !isMasterBranch" />

    <!-- 
    <v-btn
      v-if="user && boardId && isAdmin && isMasterBranch"
      text
      dense
      :href="`#/settings/${boardId}`"
      class="pa-0 ma-0"
    >
      <v-icon dense>mdi-cog-outline</v-icon>
    </v-btn>

    <v-btn text dense @click="openIntercom" class="pa-0 ma-0">
      <v-icon dense>far fa-question-circle</v-icon>
    </v-btn> -->

    <v-menu offset-x class="z-index-999">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
          v-if="userPic"
          v-bind="attrs"
          v-on="on"
          color="indigo"
          size="28"
          style="border-radius: 8px"
        >
          <img :src="userPic" />
        </v-avatar>
        <v-btn icon v-bind="attrs" v-on="on" color="af_blue"
          ><v-icon>mdi-account</v-icon></v-btn
        >
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title>
            <v-btn text small @click="logout"> Logout </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<!-- Look at https://vuetifyjs.com/en/components/navigation-drawers/#mini
if we want the drawer to be expandable -->
<script>
import { mapActions, mapGetters } from "vuex";
import DeleteScenario from "@/components/DeleteScenario";

export default {
  components: { DeleteScenario },

  data() {
    return {
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          routeName: "Dashboard",
        },
        { title: "Board", icon: "mdi-source-fork", routeName: "Board" },
      ],
      mini: true,
    };
  },
  computed: {
    ...mapGetters(["uid", "myPersonId", "people", "user"]),
    boardId() {
      return this.$route.params.boardId;
    },
    currentRouteName() {
      return this.$route.name;
    },
    userPic() {
      if (this.user) return this.user.photoURL;
      return "";
    },
    board() {
      return this._.find(this.$store.state.board.boards, {
        boardId: this.boardId,
      });
    },
    companyId() {
      if (!this.board) return "";
      return this.board.companyId;
    },
    isMasterBranch() {
      if (!this.board) return false;
      return this.board.masterBranch;
    },
    boardOwners() {
      return this.board.owners;
    },
    disableReports() {
      return this.$store.state.settings.disableReports;
    },
    enableCalibration() {
      return this.$store.state.settings.enableCalibration;
    },
    isAdmin() {
      return this.$store.getters.isAdmin(this.boardId);
    },
    isRecruiter() {
      // Check if this user has recruiter access to the board
      if (!this.board) return false;
      if (!this.user) return false;

      const uid = this.user.uid;
      if (this.board.accessLevels) {
        const ret = this._.find(this.board.accessLevels, (accessObj) => {
          return accessObj.uid === uid;
        });

        if (ret.accessLevel === "recruiter") {
          return true;
        }
      }
      return false;
    },
    // Return the role of the user
    userRole() {
      const uid = this.user.uid;
      if (this.board.accessLevels) {
        const ret = this._.find(this.board.accessLevels, (accessObj) => {
          return accessObj.uid === uid;
        });
        return ret.accessLevel;
      }
      return "none";
    },
  },
  watch: {
    currentRouteName: function () {
      this.resetPlan();
    },
    user: function () {
      if (this.user) {
        this.fetchSettings({ boardId: this.boardId });
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions(["resetPlan", "fetchSettings"]),
    openIntercom() {
      if (window.Intercom) window.Intercom("show");
    },
    logout() {
      this.$store.dispatch("logout");
      // Reset all states in Vuex
      this.$store.dispatch("reset");
      if (window.Intercom) window.Intercom("shutdown");
      return this.$router.push({ path: "/" });
    },
    getMainBoardId() {
      if (!this.board) return ""; // Board is not loaded yet
      if (this.board.masterBranch) {
        return this.board.boardId;
      } else {
        return this.board.copiedBoardId;
      }
    },
  },
};
</script>

<style scoped>
.nav-divider {
  color: #eaeaea;
}
.nav-active {
  content: "";
  border-bottom: 3px solid #0163fe;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-radius: 0px 0px 0px 0px;
}

.nav-btn {
  background-color: white !important;
  height: 45px !important;
}
.z-index-999 {
  z-index: 999 !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
