<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" sm="6">
        <v-card class="pa-4 card-shadow">
          <h4>New organization</h4>
          <p class="mt-2 text--red">
            Create a new blank organization. Please use
            <u>integration@agentnoon.com</u>
          </p>

          <v-card-actions v-if="!showCreateNewBoard">
            <v-spacer></v-spacer>
            <v-btn
              @click="showNewBoardCreationCard"
              elevation="0"
              color="af_blue"
              dark
              small
              class="py-4"
            >
              <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>Create new
            </v-btn>
          </v-card-actions>

          <v-card-text
            v-if="showCreateNewBoard"
            outlined
            width="300"
            class="ma-2 pa-4"
          >
            <v-text-field
              v-model="newBoard.companyName"
              label="Company name"
              required
            ></v-text-field>
            <!-- <v-text-field
              v-model="newBoard.boardName"
              label="Org chart name"
              required
            ></v-text-field> -->
            <v-progress-linear
              v-if="isCreating"
              color="indigo lighten-1"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="isCreating"
                text
                color="error"
                @click="showCreateNewBoard = !showCreateNewBoard"
                >Cancel</v-btn
              >
              <v-btn
                :disabled="isCreating"
                color="primary"
                @click="createNewBoard"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <v-card class="pa-4 card-shadow">
          <h4>Import Data From BambooHR</h4>
          <p class="mt-2">Import data from BambooHR</p>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              color="af_blue"
              dark
              small
              class="py-4"
              @click="showImportBambooDialog = true"
              ><v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
              Import data
            </v-btn>
            <BambooHRImportDialog :show="showImportBambooDialog" />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import BambooHRImportDialog from "@/components/BambooHRImportDialog";
const idCreator = require("@/lib/idCreator");
export default {
  name: "CreateNewOrg",
  components: {
    BambooHRImportDialog,
  },

  data: () => ({
    showCreateNewBoard: false,
    showImportBambooDialog: false,
    isCreating: false,
    newBoard: {
      companyName: "",
      companyId: "",
      boardId: "",
      boardName: "Company Name",
      isCreating: false,
    },
  }),
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    showNewBoardCreationCard() {
      this.newBoard.companyName = "";
      this.showCreateNewBoard = !this.showCreateNewBoard;
      this.newBoard.companyId = idCreator.createCompanyId();
      this.newBoard.boardId = idCreator.createBoardId();
    },
    async createNewBoard() {
      this.isCreating = true;
      this.newBoard = {
        companyId: this.newBoard.companyId,
        boardId: this.newBoard.boardId,
        originalBoardId: this.newBoard.boardId, // This is the original board
        boardName: this.newBoard.companyName,
        companyName: this.newBoard.companyName,
        owners: [this.user.uid],
        accessLevels: [{ uid: this.user.uid, accessLevel: "admin" }],
        // timeTarget: this.$moment().format('Q - YYYY'),
        masterBranch: true,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      await firebase
        .firestore()
        .collection("board")
        .doc(this.newBoard.boardId)
        .set(this.newBoard);

      // Add one open role to the new board so it's not empty from the start.
      // await this.$store.dispatch("addOpenRole", {
      //   newPersonId: idCreator.createPersonId(),
      //   placementCoordinates: { x: 300, y: 300 },
      //   boardId: this.newBoard.boardId,
      //   isFutureUpdate: false,
      //   role: "CEO",
      //   status: "Active",
      //   createdBy: this.user.uid,
      //   updatedBy: this.user.uid,
      // });

      await this.$store.dispatch("fetchAllBoards", this.user.uid);

      this.isCreating = false;
      this.showCreateNewBoard = false;

      window.location.href = `/#/c/${this.newBoard.companyId}`;
      window.location.reload();
    },
  },
};
</script>
