<template>
  <v-container>
    <v-row class="d-flex align-center justify-center">
      <v-col cols="12" md="8" lg="6" sm="12">
        <v-row class="d-flex justify-center mt-12">
          <h1 class="mt-8">Let's Get Started</h1>
        </v-row>

        <v-row no-gutters class="d-flex justify-center text-center"
          ><p class="font-weight-light mt-4">
            Signup for free. No credit card required.
          </p></v-row
        >
        <v-card
          v-if="!showEmailPasswordSignup"
          class="pa-2 animate__animated animate__fadeIn"
          flat
        >
          <p class="body-1 text-center mt-6">
            By signing up, you are agreeing to Agentnoon's<br />
            <a href="https://www.agentnoon.com/terms-of-service" target="_blank"
              >Terms of Service</a
            >
            and
            <a href="https://www.agentnoon.com/privacy" target="_blank"
              >Privacy Policy</a
            >
          </p>
          <v-btn
            block
            outlined
            large
            class="mt-2"
            @click="googleSignup"
            style="width: 100px"
          >
            <v-img
              :src="require('../assets/google_icon.png')"
              max-width="20"
              max-height="20"
              aspect-ratio="1"
              class="g-logo mr-3"
              contain
            >
            </v-img>
            Sign up with Google
          </v-btn>

          <!-- <p class="body-1 mt-12 text-center">Alternate Method</p>
          <v-btn
            large
            block
            outlined
            class="mt-4 login-btn"
            @click="showEmailPasswordSignup = true"
            >Sign up with email/password</v-btn
          > -->
        </v-card>

        <!-- Alternative to Google OAuth signup -->
        <v-card
          v-if="showEmailPasswordSignup"
          class="pa-12 mt-4 animate__animated animate__fadeIn"
          flat
        >
          <v-text-field
            label="What's your full name?*"
            v-model="name"
            :rules="nonEmpty"
          ></v-text-field>

          <v-text-field
            label="Company Email*"
            v-model="email"
            :rules="nonEmpty"
          ></v-text-field>
          <v-text-field
            label="Password*"
            type="password"
            v-model="password"
            :rules="nonEmpty"
          ></v-text-field>

          <v-text-field
            label="Promotion Code (Optional)"
            type="text"
            v-model="referralCode"
          ></v-text-field>

          <p class="af_red--text">{{ errorMsg }}</p>
          <v-btn
            class="login-btn"
            large
            outlined
            block
            @click="signupWithEmail"
            :loading="isCreatingAccount"
            >Create Account</v-btn
          >
        </v-card>

        <v-row class="d-flex justify-center text-center mt-4">
          <h3 class="font-weight-light block">
            Already have an account? <a :href="loginUrl">Log in</a>
          </h3>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as Sentry from "@sentry/browser";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "Signup",
  components: {},
  data() {
    return {
      showEmailPasswordSignup: false,
      name: "",
      email: "",
      password: "",
      referralCode: "",
      errorMsg: "",
      nonEmpty: [(value) => !!value || "Required"],
      isCreatingAccount: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
    preferredProvider() {
      return this.$route.query.method;
    },
    loginUrl() {
      const redirect = this.$route.query.redirect;
      if (redirect) return `/#/login?redirect=${redirect}`;
      return `/#/login`;
    },
  },
  watch: {},
  created() {},
  mounted() {
    if (window.localStorage.getItem("agentnoon_referred_by")) {
      this.referralCode = window.localStorage.getItem("agentnoon_referred_by");
    }

    if (this.preferredProvider === "password") {
      this.showEmailPasswordSignup = true;
      // Check if email is already given in the query string
      // If so, populate it
      if (this.$route.query.email) {
        this.email = this.$route.query.email;
      }
    }
  },
  methods: {
    googleSignup() {
      this.$store.dispatch("signInAction");
    },

    signupWithEmail() {
      if (!this.email || !this.name || !this.password) {
        this.errorMsg = "Please fill all fields";
        return;
      }

      if (this.email.includes("@gmail.com")) {
        this.errorMsg = "Please use company email";
        return;
      }

      // Track if the promotion code was used by this user

      if (this.referralCode) {
        // Store the code in in the localStorage. It gets picked up in App.vue when the user is identified for the first time.
        window.localStorage.setItem("agentnoon_referred_by", this.referralCode);
      }

      this.isCreatingAccount = true;
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email.trim(), this.password)
        .then((userCredential) => {
          console.log(userCredential.user.displayName, "signed up");
          const user = firebase.auth().currentUser;
          user
            .updateProfile({
              displayName: this.name,
            })
            .catch((error) => {
              if (Sentry) Sentry.captureException(error);
            });

          window.mixpanel.track("user_signup_email");
          user.sendEmailVerification();
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode, errorMessage);

          if (errorCode === "auth/email-already-in-use") {
            this.errorMsg = "Account already exists";
            this.isCreatingAccount = false;
            return;
          }

          if (errorCode === "auth/invalid-email") {
            this.errorMsg = "The email address is invalid";
            this.isCreatingAccount = false;
            return;
          }

          if (Sentry) Sentry.captureException(error);
        });
    },
  },
};
</script>

<style scoped>
.login-btn {
  border: 1px solid #c8c8ce !important;
  text-transform: capitalize;
}

.signup-btn {
  text-transform: capitalize;
}

.block {
  width: 100%;
  display: block;
}
</style>
