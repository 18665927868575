<template>
  <v-dialog v-model="dialog" width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red" text v-bind="attrs" v-on="on">
        <v-icon color="red">mdi-cancel</v-icon>
      </v-btn>
    </template>

    <v-card class="pa-8">
      <p class="body-1">
        Remove <b>{{ employee.name }}</b> from the
        <b>{{ templateName }}</b> list?
      </p>

      <v-card-actions class="pb-0 mb-0">
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false" :disabled="processing">
          Cancel
        </v-btn>
        <v-btn color="red" text :loading="processing" @click="remove()">
          Remove
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "PerformanceReviewRemovePersonConfirmation",
  props: {
    employee: {
      required: true,
      type: Object,
    },
    templateName: {
      required: true,
      type: String,
    },
    receviers: {
      required: false,
      type: Object,
    },
  },
  data: () => ({
    dialog: false,
    processing: false,
    vps: "",
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions([""]),
    async remove() {
      this.processing = true;
      await firebase
        .firestore()
        .collection("performance_review_receivers")
        .doc(`${this.boardId}_${this.templateId}`)
        .update({
          sent: firebase.firestore.FieldValue.arrayRemove(
            this.employee.personId
          ),
        });
      this.$emit("done");
      this.processing = false;
      this.dialog = false;
    },
  },
};
</script>
