import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0062FF',
        secondary: '#2E7CFA',
        primaryLight: '#50B5FF',
        grey1: '#444444',
        grey2: '#92929D',
        grey3: '#C8C8CE',
        grey4: '#E7E7E7',
        grey5: '#EAEAEA',
        error: '#FF6666',
        af_red: '#FF6666',
        af_yellow: '#f2c94c',
        af_green: '#12B829',
        af_blue: '#0062FF',
      },
      dark: {
        af_red: '#FF6666',
        af_yellow: '#f2c94c',
        af_green: '#12B829',
        af_blue: '#0062FF',
      },
    },
  },
});
