// Default config for the auto tree alignment
export const DEFAULT_AUTO_TREE_CONFIG = {
  /* It is using 320. If x is 300, Some avatars stick together due to the size of the avatar. */
  gap: { x: 320, y: 200 },

  /* Minimum spacing between avatars */
  gridSize: 20,

  /* For finding the target of collapse avatars if there are more than 3 sub nodes at the end of the tree. */
  collapseLength: 3,

  /* Set custom gap with presonId */
  /**
   * [
   *  {
   *    personId: {String},
   *    gap: {Object},
   *  },
   *  ...
   * ]
   */
  customGap: [],

  /* If personId is included here, customGap is ignored and default gap is used */
  ignoreCustomGap: [],

  /* personId list to hide avatars */
  hiddenAvatarList: [],
};

// The custom gap value on the auto tree alignment.
export const COLLAPSE_AVATAR_SIZE = {
  x: 60,
  y: 200,
};
