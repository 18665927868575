import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
// import * as Sentry from '@sentry/browser';
// import 'firebase/functions';
import { createSquadId } from "@/lib/idCreator";

const getDefaultState = () => {
  return {
    squads: [],
  };
};

const state = getDefaultState();

const mutations = {
  addNewSquad(state, squad) {
    state.squads.push(squad);
  },
  updateSquad(state, { squadId, key, value }) {
    console.log("updateSquad");
    let clone = Vue._.cloneDeep(state.squads);
    Vue._.each(clone, (obj) => {
      if (obj.squadId === squadId) {
        obj[key] = value;
      }
    });
    state.squads = clone;
  },
  resetSquads(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addNewSquad(context, { boardId }) {
    const squadId = createSquadId();
    context.commit("addNewSquad", {
      squadId: squadId,
      name: "New Squad",
      initiative: "No Initiative Set",
      boardId,
      dri: "",
      team: [],
    });
    return squadId;
  },
  updateSquad(context, { squadId, key, value }) {
    context.commit("updateSquad", { squadId, key, value });
  },
  async submitSquads(context, { boardId, squadId }) {
    // var batch = firebase.firestore().batch();

    const targetSquad = Vue._.find(context.state.squads, (squad) => {
      return squad.squadId === squadId;
    });

    if (!targetSquad) return;
    const ref = firebase.firestore().collection("squads").doc(squadId);

    const _squad = Vue._.cloneDeep(targetSquad);
    _squad.boardId = boardId;
    if (!_squad.createdAt)
      _squad.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    _squad.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    _squad.updatedBy = context.rootGetters.uid;
    ref.set(_squad);
  },
  async fetchSquads(context, { boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("squads")
      .where("boardId", "==", boardId)
      .get();

    querySnapshot.forEach((doc) => {
      context.commit("addNewSquad", doc.data());
    });
  },
  // onSquadsChange(context, { boardId }) {
  //   return firebase
  //     .firestore()
  //     .collection("squads")
  //     .where("boardId", "==", boardId)
  //     .onSnapshot((querySnapshot) => {
  //       var source = querySnapshot.hasPendingWrites ? "local" : "server";
  //       if (source !== "server") return;
  //       querySnapshot.forEach((doc) => {
  //         const changedSquad = Vue._.find(context.state.squads, (squad) => {
  //           return squad.squadId === doc.data().squadId;
  //         });

  //         console.log(changedSquad);
  //         // }
  //       });
  //     });
  // },
  resetSquads(context) {
    context.commit("resetSquads");
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
