<template>
  <v-container>
    <v-row v-if="showLogins" class="d-flex justify-center mt-12">
      <h1 class="mt-8">Login</h1>
    </v-row>
    <v-row v-if="showLogins" class="d-flex justify-center">
      <h3 class="mt-4 mb-2 font-weight-light">
        Please use your <strong><u>work email</u></strong>
      </h3>
    </v-row>

    <v-row v-if="showLogins" class="d-flex align-center justify-center">
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-row class="">
          <v-col cols="12" class="text-center">
            <v-btn
              v-if="!showEmailPasswordLogin"
              block
              outlined
              large
              class="login-btn mt-4"
              @click="login"
            >
              <v-img
                :src="require('../assets/google_icon.png')"
                max-width="20"
                max-height="20"
                aspect-ratio="1"
                class="g-logo mr-3"
                contain
              >
              </v-img>
              Sign in with Google Workspace SSO
            </v-btn>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12">
            <!-- <v-btn
              v-if="!showEmailPasswordLogin"
              block
              outlined
              large
              class="login-btn mt-4"
              @click="showEmailPasswordLogin = true"
              >Login with email</v-btn
            > -->

            <v-card
              v-if="showEmailPasswordLogin"
              class="px-12 py-8 mt-4 animate__animated animate__fadeIn"
              elevation="1"
            >
              <v-text-field label="Email" v-model="email"></v-text-field>
              <v-text-field
                label="Password"
                type="password"
                v-model="password"
                v-on:keyup.enter="loginWithEmail"
              ></v-text-field>

              <p class="af_red--text">{{ errorMsg }}</p>
              <v-btn
                class="login-btn"
                large
                outlined
                block
                @click="loginWithEmail"
                :loading="isLoggingIn"
                >Login With Email</v-btn
              >

              <v-btn small href="#/password_reset" text block class="mt-2"
                >Forgot Password?</v-btn
              >
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <p v-if="referralCode" class="body-1 mt-6">
            Promotion code <strong>{{ referralCode }}</strong> will be
            automatically applied when you create an account.
          </p>
        </v-row>

        <v-row no-gutters class="d-flex justify-center mt-2 text-center">
          <h3 class="font-weight-light block">
            Don't have an account? <a href="/#/signup">Create an account</a>
          </h3>
        </v-row>
        <!-- <v-row no-gutters class="d-flex justify-center mt-2 text-center"
          ><h3 class="font-weight-light block">
            No credit card required
          </h3></v-row
        > -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "animate.css";
import * as Sentry from "@sentry/browser";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      showLogins: false,
      showEmailPasswordLogin: false,
      hideGoogleLogin: false,
      hideEmailPasswordLoginBtn: false,
      isLoggingIn: false,
      referralCode: "",
      email: "",
      password: "",
      errorMsg: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
    isMainWebsite() {
      // return true;
      return window.location.origin === "https://agentnoon.com";
    },
    isTester() {
      return (
        this.$router.currentRoute.query.tester ||
        window.location.href.includes("tester=1")
      );
    },
    // Authentication provider
    authProvider() {
      return this.$route.query.method;
    },
  },
  watch: {},
  created() {},
  mounted() {
    firebase.auth().onAuthStateChanged(async (userObj) => {
      if (!userObj) {
        // Show login buttons
        this.showLogins = true;
      } else {
        // In the process of logging in. Hide the login buttons
        this.showLogins = false;
      }
    });

    // Check if this user was referred and need to have a referral code applied
    if (window.localStorage.getItem("agentnoon_referred_by")) {
      this.referralCode = window.localStorage.getItem("agentnoon_referred_by");
    }

    // if (this.authProvider === "password") {
    //   this.showEmailPasswordLogin = true;
    //   this.hideGoogleLogin = true;

    //   // Check if email is already given in the query string
    //   // If so, populate it
    //   if (this.$route.query.email) {
    //     this.email = this.$route.query.email;
    //   }
    // }
    // if (this.authProvider === "google.com") {
    //   this.showEmailPasswordLogin = false;
    //   this.hideEmailPasswordLoginBtn = true;
    // }
  },
  methods: {
    login() {
      this.$store.dispatch("signInAction");
    },
    loginWithEmail() {
      this.isLoggingIn = true;

      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          console.log(userCredential.user.displayName, "signed in");

          // console.log(firebase.auth().currentUser.additionalUserInfo);
          // var _user = firebase.auth().currentUser;
          // _user.updateProfile({
          //   displayName: 'Tester Jane',
          // });
        })
        .catch((error) => {
          console.error(error);
          this.errorMsg = error.message;
          this.isLoggingIn = false;
          if (Sentry) Sentry.captureException(error);
        });
    },
  },
};
</script>

<style scoped>
.login-btn {
  border: 1px solid #c8c8ce !important;
  text-transform: capitalize;
}

.signup-btn {
  text-transform: capitalize;
}
</style>
