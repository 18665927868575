<template>
  <v-container>
    <v-row
      class="d-flex justify-center mt-12 animate__animated animate__fadeIn"
    >
      <p class="text-h1 black--text mt-4 mr-2">Welcome to</p>
      <v-img src="@/assets/Logo/Logo-Blue.svg" contain max-width="300"></v-img>
    </v-row>

    <!-- <v-row no-gutters class="d-flex justify-center mt-4">
      <v-img src="@/assets/hero_image.png" contain max-width="800"></v-img>
    </v-row> -->

    <v-row
      no-gutters
      class="d-flex justify-center mt-4 animate__animated animate__fadeIn"
    >
      <p class="text-h2">Make better people decisions</p>
    </v-row>

    <div v-if="isLoading" class="my-12 animate__animated animate__fadeIn">
      <v-row class="d-flex justify-center">
        <v-progress-circular
          :size="300"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-center">
        <h3 class="mt-12">Loading...</h3>
      </v-row>
    </div>

    <div v-if="!isLoading">
      <v-row
        v-if="user"
        class="d-flex justify-center mt-6 animate__animated animate__fadeIn"
      >
        <Invitations />
      </v-row>

      <v-row
        v-if="boards.length === 0"
        class="d-flex justify-center mt-6 animate__animated animate__fadeIn"
      >
        <v-card class="card-shadow pa-6">
          <v-card-text style="width: 371px">{{ demoText }}</v-card-text>
          <v-card-actions class="d-flex justify-center mt-6">
            <v-btn
              color="af_blue"
              class="white--text"
              outlined
              href="https://www.agentnoon.com/book-demo"
              >Contact Us
            </v-btn>
            <!-- <v-btn
              color="af_blue"
              class="white--text"
              @click="createDemo"
              :loading="isCreatingDemo"
            >
              Take me to demo
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-row>

      <v-row
        v-if="companies.length > 0"
        class="mt-6 ml-2 animate__animated animate__fadeIn"
      >
        <p class="text-h2">Please select a company</p></v-row
      >
      <v-row class="mt-6 animate__animated animate__fadeIn">
        <v-col
          v-for="board in mainBoards"
          :key="board.boardId"
          cols="12"
          md="3"
        >
          <v-card class="card-shadow pa-2">
            <v-card-text
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
              >{{ board.boardName }}</v-card-text
            >
            <v-card-subtitle v-if="board.createdAt" class="mt-0 pt-0"
              >Created
              {{ board.createdAt.seconds | moment("from", "now") }}
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn color="error" outlined @click="deleteBoard(company)"
                >Delete</v-btn
              > -->
              <v-btn
                class="white--text"
                color="af_blue"
                :href="`#/company/${board.boardId}`"
                >Select</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import Invitations from "@/components/Invitations";
import firebase from "firebase/app";

export default {
  name: "Init",
  components: {
    Invitations,
  },
  data: () => ({
    carousel: 0,
    isLoading: true,
    isCreatingDemo: false,
    demoText:
      "You are not in any organization account. Please contact sales or request your company's admin for access.",
  }),
  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    boardsFetched() {
      return this.$store.state.board.fetchCount > 0;
    },
    mainBoards() {
      return this.boards.filter((board) => board.masterBranch);
    },
    companies() {
      return this._.chain(this.boards)
        .groupBy("companyId")
        .map((company) => {
          return {
            text: `${company[0].companyName}`,
            createdAt: `${this.$moment(
              company[0]?.createdAt?.toDate()
            ).fromNow()}`,
            companyId: `${company[0].companyId}`,
          };
        })
        .filter((company) => {
          return company.companyId !== "null";
        })
        .value();
    },
    invitations() {
      return this.$store.state.user.invitations;
    },
  },
  watch: {
    boards() {
      if (this.boardsFetched) {
        this.render();
      }
    },
    // invitations: function () {
    //   if (this.invitations.length == 0) {
    //     this.demoText =
    //       "You are not in any organization account. You can try the demo or contact us to create an organization account.";
    //   }
    // },
    user() {
      if (this.user) this.load();
    },
  },
  mounted() {
    console.log("mounted");
    this.load();

    if (this.$route.query.redirect) {
      this.$router.push({
        path: this.$route.query.redirect,
      });
      return;
    }
  },
  methods: {
    openIntercom() {
      if (window.Intercom) window.Intercom("show");
    },
    load() {
      if (this.user) this.$store.dispatch("fetchAllBoards", this.user.uid);
    },
    render() {
      this.isLoading = false;
      // 1. Check if a user is in any of the org
      //    If so, send the user to that org
      // 2. If the user is not in any org, show:
      //    - Pending invitations
      //    - Option to go to a sample org

      if (this.boards.length > 0) {
        // User already is assigned to an org

        // Go to the demo page if it exists
        if (this.boards.length === 1 && this.boards[0].isDemoBoard) {
          return this.$router.push({
            path: `/demo/${this.boards[0].boardId}`,
          });
        } else if (this.boards.length === 1 && !this.boards[0].isDemoBoard) {
          // Technically, both /demo and /company use the same view.
          // Having the state in the URL maybe useful in the future.
          return this.$router.push({
            path: `/company/${this.boards[0].boardId}`,
          });
        }

        // return this.$router.push({ path: "/c" });
      }
    },
    async createDemo() {
      this.isCreatingDemo = true;

      var createDemo = firebase.functions().httpsCallable("createDemo");
      const ret = await createDemo({
        testerEmail: this.user.email,
        testerName: this.user.displayName,
      });

      window.mixpanel.track("demo_created");

      return this.$router.push({ path: `/demo/${ret.data.boardId}` });
    },
    getBoardIdForCompany(companyId) {
      const b = this._.find(this.boards, (board) => {
        return board.masterBranch && board.companyId === companyId;
      });
      if (b) return b.boardId;
    },
    async deleteBoard(company) {
      const companyId = company.companyId;
      const boardId = this.getBoardIdForCompany(companyId);

      this.isLoading = true;

      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("people")
          .where("boardId", "==", boardId)
          .get();
        var batch = firebase.firestore().batch();
        querySnapshot.forEach(async (doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();
      } catch (e) {
        console.log("error in people delete");
        console.error(e.code);
        console.error(e.message);
      }

      try {
        await firebase.firestore().collection("board").doc(boardId).delete();
      } catch (e) {
        console.log("error in board deletion");
        console.error(e);
      }

      this.load();
    },
  },
};
</script>
