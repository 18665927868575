import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import firebase from "firebase/app";
import "firebase/app-check";
import "firebase/analytics";
import "firebase/performance";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";
Vue.config.productionTip = false;

Vue.use(VueLodash, { lodash: lodash });
Vue.use(require("vue-moment"));

// when doing some admin or debugging in product mode.
let MODE = "PRODUCTION";
// if (window.location.hostname.includes("localhost")) {
//   MODE = "DEV";
// }

window.MODE = MODE;

if (window.location.host.includes("app.agentnoon") || MODE === "PRODUCTION") {
  // Production environment
  const firebaseConfig = {
    apiKey: "AIzaSyCbJ-27TQG4TMws5BxSf99N4wpnMbXaihc",
    authDomain: "auth.agentnoon.com",
    databaseURL: "https://orgraph-d57a6.firebaseio.com",
    projectId: "orgraph-d57a6",
    storageBucket: "orgraph-d57a6.appspot.com",
    messagingSenderId: "513813238878",
    appId: "1:513813238878:web:2068fa1d9fdd0e152810cf",
    measurementId: "G-4PM8KZ85HE",
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  console.log("RUNNING PRODUCTION MODE");
} else {
  const firebaseConfig = {
    apiKey: "AIzaSyDq_MtrRO19v5SjaxPzt2D1E0oGtHDR9VA",
    authDomain: "staging.auth.agentnoon.com",
    projectId: "agentnoon-staging",
    storageBucket: "agentnoon-staging.appspot.com",
    messagingSenderId: "706148019191",
    appId: "1:706148019191:web:3a3a0d023b2b352a089cf2",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  console.log("RUNNING DEV MODE");
}
firebase.performance();

const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  "6LffVwAdAAAAAMBD9DEBOEKNphvLXoUsceaYHDMH",
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
);

// Remember to disable this when not needed
if (window.location.hostname.includes("localhost")) {
  console.log("====USING EMULATOR====");
  firebase.functions().useEmulator("localhost", 5001);
}

// Check authentication requirement and route appropriately
router.beforeEach((to, from, next) => {
  window.mixpanel.track(`route_${to.name}`);
  return next();
});

// router.afterEach((to, from) => {
//   if (from.query.demo === "true") {
//     router.push({ query: { demo: true } });
//   }
// });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://97ecb685267545e79ddef4054edd93e6@o970831.ingest.sentry.io/5922362",
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1,
  });

  firebase.analytics();
}
