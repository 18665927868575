import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
// import moment from 'moment';

const getDefaultState = () => {
  return {
    boards: [],
    fetchCount: 0,
  };
};

const state = getDefaultState();

const mutations = {
  setBoards(state, boards) {
    state.boards = boards;
    state.fetchCount++;
  },

  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  getBoard: (state) => (boardId) => {
    return Vue._.find(state.boards, {
      boardId: boardId,
    });
  },
};

const actions = {
  async fetchAllBoards(context, userId) {
    console.log("fetchAllBoards");
    const querySnapshot = await firebase
      .firestore()
      .collection("board")
      .where("owners", "array-contains", userId)
      .where("companyId", "!=", null)
      .get();

    const boards = [];
    querySnapshot.forEach((doc) => {
      var data = Vue._.cloneDeep(doc.data());
      if (
        !data["companyId"] ||
        data["companyId"] === "undefined" ||
        data["companyId"] === ""
      ) {
        data["companyName"] = null;
        data["companyId"] = null;
      }
      // Legacy data update
      // if (!data['timeTarget']) {
      //   data['timeTarget'] = moment().format('Q - YYYY');
      //   doc.ref.update({
      //     timeTarget: moment().format('Q - YYYY'),
      //   });
      // }
      if (!data.accessLevels) {
        data.accessLevels = [];
      }

      const uniqAccessLevels = Vue._.uniqBy(data.accessLevels, "uid");
      if (uniqAccessLevels.length !== data.accessLevels.length) {
        data.accessLevels = uniqAccessLevels;
      }

      boards.push(data);
    });

    context.commit("setBoards", boards);
  },

  async updateAccessLevel(
    context,
    { companyId, masterBranch, myUid, targetUid, newAccessLevel, boardId }
  ) {
    console.log("updateAccessLevel");

    const querySnapshot = await firebase
      .firestore()
      .collection("board")
      .where("owners", "array-contains", myUid)
      .where("companyId", "==", companyId)
      .where("masterBranch", "==", masterBranch)
      .where("boardId", "==", boardId)
      .get();

    var batch = firebase.firestore().batch();

    querySnapshot.forEach((doc) => {
      if (doc.data().owners.includes(targetUid)) {
        let accessLevels = doc.data().accessLevels;
        let owners = doc.data().owners;

        let target;
        if (newAccessLevel === "none") {
          // remove this person from the list
          accessLevels = accessLevels.filter((acl) => acl.uid !== targetUid);
          owners = owners.filter((uid) => uid !== targetUid);
        } else {
          target = Vue._.find(accessLevels, { uid: targetUid });
          target.accessLevel = newAccessLevel;
        }

        batch.update(doc.ref, {
          accessLevels: accessLevels,
          owners: owners,
        });
      }
    });
    await batch.commit();
  },

  async fetchBoardForTime(context, { originalBoardId, userId, timeTarget }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("board")
      .where("owners", "array-contains", userId)
      .where("timeTarget", "==", timeTarget)
      .where("originalBoardId", "==", originalBoardId)
      .get();

    const boards = [];
    querySnapshot.forEach((doc) => {
      boards.push(doc.data());
    });
  },

  async cloneBoard(
    context,
    { srcBoardObj, peopleList, timeTarget, masterBranch, uid }
  ) {
    console.log("cloneBoard");

    const idCreator = require("@/lib/idCreator");
    const newBoardId = idCreator.createBoardId();

    let newBoardObj = {
      companyId: `${srcBoardObj.companyId}`,
      companyName: `${srcBoardObj.companyName}`,
      boardId: newBoardId,
      originalBoardId: srcBoardObj.originalBoardId
        ? srcBoardObj.originalBoardId
        : srcBoardObj.boardId,
      copiedBoardId: srcBoardObj.boardId,
      boardName: `${srcBoardObj.boardName}`,
      timeTarget: timeTarget,
      accessLevels: srcBoardObj.accessLevels ? srcBoardObj.accessLevels : [],
      owners: uid ? [uid] : srcBoardObj.owners,
      masterBranch: masterBranch,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (srcBoardObj.cloneId) newBoardObj.cloneId = srcBoardObj.cloneId;

    await firebase
      .firestore()
      .collection("board")
      .doc(newBoardId)
      .set(newBoardObj);

    // Write all the new users as a batch
    var batch = firebase.firestore().batch();

    var newPeopleList = Vue._.cloneDeep(peopleList);
    Vue._.each(newPeopleList, (person) => {
      person.boardId = newBoardId;
      person.boardAccess = "none";
      person.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      person.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
      let docRef = firebase
        .firestore()
        .collection("people")
        .doc(newBoardId + "_" + person.personId);
      batch.set(docRef, person);
    });

    await batch.commit();
  },

  async makeDemoOrg(context, { email, name }) {
    var createDemo = firebase.functions().httpsCallable("createDemo");
    const ret = await createDemo({
      testerEmail: email,
      testerName: name,
    });

    return ret;
  },

  reset({ commit }) {
    commit("reset");
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {},
};
