import Vue from "vue";
import firebase from "firebase/app";
// import 'firebase/auth';
import "firebase/firestore";
import * as Sentry from "@sentry/browser";
// import { createPersonId } from '@/lib/idCreator';

const state = {
  directory: [],
  afradPersonObjects: [],
};

const mutations = {
  setBambooData(state, employeeList) {
    Vue._.each(employeeList, (employee) => {
      Vue._.map(employee, (v, k) => {
        if (Vue._.isNull(v)) {
          // if the value is null, pre-populate it
          employee[k] = "";
        }
      });
    });
    state.directory = employeeList;
  },
  pushAfradPersonObjects(state, person) {
    state.afradPersonObjects.push(person);
  },
};

const actions = {
  async importBambooHREmployeeList(context, { apiKey, subdomain }) {
    try {
      var importBambooHREmployeeList = firebase
        .functions()
        .httpsCallable("importBambooHREmployeeList");
      const employeeList = await importBambooHREmployeeList({
        apiKey,
        subdomain,
      });
      context.commit("setBambooData", employeeList.data);
    } catch (e) {
      Sentry.captureException(e, { tags: { subdomain } });
      return "error";
    }
  },

  async syncWithBambooHR(context, { boardId }) {
    try {
      var syncBambooHR = firebase.functions().httpsCallable("syncBambooHR");
      const ret = await syncBambooHR({
        boardId,
      });
      return ret;
    } catch (e) {
      Sentry.captureException(e, { tags: { boardId } });
      return "error";
    }
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
