import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import * as Sentry from "@sentry/browser";
import { getInitializedPeopleData } from "@/lib/fabricjs/utils";
// import { create } from 'core-js/core/object';
var numeral = require("numeral");

const getDefaultState = () => {
  return {
    personSelected: null,
    showComments: false,
    people: [],
    peopleIncludingRemoved: [],
    comments: {},
    ownerInfos: [],
  };
};

const state = getDefaultState();

const mutations = {
  setOwnerInfos(state, ownerInfos) {
    state.ownerInfos = ownerInfos;
  },
  setPersonSelected(state, personSelected = null) {
    state.personSelected = personSelected;
  },
  setShowComments(state, show = false) {
    state.showComments = show;
  },
  setPerson(state, personObj) {
    // Remove the existing. If it exists
    Vue._.remove(state.people, (person) => {
      return person.personId === personObj.personId;
    });
    state.people.push(personObj);
  },

  setPeopleData(state, people) {
    Vue._.each(people, (person) => {
      if (!person.avatarImg || person.avatarImg === "") {
        person.avatarImg = `https://ui-avatars.com/api/?name=${person.name}&background=50B5FF&color=fff`;
      }
      person.subordinates = Vue._.uniq(person.subordinates);
    });

    // Ensure there are no duplicates in the subordinates list
    people = Vue._.uniqBy(people, function (e) {
      return e.personId;
    });

    people = Vue._.sortBy(people, "name");

    state.people = validatePeopleData(people);
  },
  addNewPerson(state, personObj) {
    state.people.push(personObj);
  },
  removePerson(state, personId) {
    // Go through everyone and make sure this person is removed from managers and suboridinates list.
    Vue._.each(state.people, (person) => {
      Vue._.remove(person.managers, (managerId) => {
        return managerId === personId;
      });
      Vue._.remove(person.subordinates, (subordinateId) => {
        return subordinateId === personId;
      });
    });

    // Remove this person from the state
    Vue._.remove(state.people, (person) => {
      return person.personId === personId;
    });

    state.people = validatePeopleData(
      state.people.filter((person) => person.personId !== personId)
    );
  },
  updatePerson(state, personData) {
    const personIndex = state.people.findIndex(
      (person) => person.personId === personData.personId
    );
    let newPeopleData = [...state.people];
    newPeopleData[personIndex] = personData;
    newPeopleData[personIndex].isDirty = true;

    state.people = validatePeopleData(newPeopleData);
  },
  updatePeopleData(state, payload) {
    payload.forEach((updateData) => {
      const personIndex = state.people.findIndex(
        (people) => people.personId === updateData.personId
      );
      if (personIndex >= 0) {
        state.people[personIndex] = {
          ...state.people[personIndex],
          ...updateData,
        };
      }
    });
  },
  reset(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
};

const getters = {
  people: (state) => {
    return state.people;
  },
  activeEmployees: (state) => {
    return Vue._.filter(state.people, (person) => {
      return person.name && !person.removed;
    });
  },
  groupPeopleByManager: (state, getters) => {
    return Vue._.groupBy(getters.activeEmployees, (emp) => {
      return emp.managers[0] ? emp.managers[0] : "noManager";
    });
  },
  employeesByPersonId: (state, getters) => {
    console.log("employeesByPersonId");
    return Vue._.keyBy(getters.people, (emp) => {
      return emp.personId;
    });
  },
};

const actions = {
  // Fetch a single person
  async fetchPerson(context, { boardId, personId }) {
    const ret = await firebase
      .firestore()
      .collection("people")
      .doc(`${boardId}_${personId}`)
      .get();

    return ret.data();
  },
  // Fetch all people without filtering removed people
  async fetchAllPeople(context, { boardId }) {
    console.log("fetchAllPeople");
    // Reset the state
    let peopleData = [];

    const querySnapshot = await firebase
      .firestore()
      .collection("people")
      .where("boardId", "==", boardId)
      .get();

    querySnapshot.forEach((doc) => {
      let personData = doc.data();
      delete personData.isDirty;

      // If this person doesn't have the termination date populated,
      // Check if the termination date exists in the imported data.
      // If so, copy it over

      if (
        // need to check dataSource and dataSourcce. As there was a mistake in the Bamboo import module
        personData.employeeData.dataSource === "bamboohr" ||
        personData.employeeData.dataSourcce === "bamboohr"
      ) {
        // Termination data did not copy over properly. Do it here
        if (
          !personData.employeeData.terminationDate &&
          personData.importedData.terminationDate !== "0000-00-00"
        ) {
          personData.employeeData.terminationDate =
            personData.importedData.terminationDate;
        }
      }

      peopleData.push(personData);
    });
    return peopleData;
  },

  async fetchPeople(context, { boardId }) {
    console.log("fetchPeople");
    // Reset the state
    let peopleData = [];

    const querySnapshot = await firebase
      .firestore()
      .collection("people")
      .where("boardId", "==", boardId)
      .get();

    querySnapshot.forEach((doc) => {
      let personData = doc.data();
      delete personData.isDirty;
      peopleData.push(personData);

      resolveAvatarImage(personData);

      // Remove duplicates in the subordinates list
      personData["subordinates"] = Vue._.uniq(personData["subordinates"]);

      // Remove whitespace from email
      if (personData?.email) {
        personData.email = personData.email.toLowerCase().trim();
      }

      // If the person is on payType === salary and payPer is not set to a year,
      // we compute the salary for this person

      if (personData.employeeData.payType) {
        let multiplier = 1;

        let rate = personData.employeeData.payRate.replace(/[a-zA-Z]/g, ""); // Since GBP gets interpretted as gigabyte
        let payRate = numeral(rate).value();
        let payPer = personData.employeeData.payPer;

        if (payPer.toLowerCase() === "month") {
          multiplier = 12;
        } else if (payPer.toLowerCase() === "day") {
          multiplier = 5 * 48;
        } else if (payPer.toLowerCase() === "week") {
          multiplier = 48;
        }

        let salary = payRate * multiplier;

        let currency = personData.employeeData.payRate
          .replace(/[0-9]/g, "")
          .replace(".", "")
          .trim();
        personData.employeeData.salary = salary + " " + currency;
      }

      if (
        // need to check dataSource and dataSourcce. As there was a mistake in the Bamboo import module
        personData.employeeData.dataSource === "bamboohr" ||
        personData.employeeData.dataSourcce === "bamboohr"
      ) {
        // Termination data did not copy over properly. Do it here
        if (
          personData.importedData &&
          !personData.employeeData.terminationDate &&
          personData.importedData.terminationDate !== "0000-00-00"
        ) {
          personData.employeeData.terminationDate =
            personData.importedData.terminationDate;
        }
      }
    });

    context.state.peopleIncludingRemoved = Vue._.cloneDeep(peopleData);

    peopleData = sanityCheckPeopleData(peopleData);

    fixDiscontinuity(peopleData);

    // Collapse all nodes below first level of the root node when first loaded.
    const collapsedPeopleData = getInitializedPeopleData(peopleData);

    context.commit("setPeopleData", collapsedPeopleData);
  },
  async updateAvatarCoordinates(context, payload) {
    console.log("updateAvatarCoordinates");

    payload = payload.map((x) => {
      x.isDirty = false;
      return x;
    });

    context.commit("updatePeopleData", payload);

    // var batch = firebase.firestore().batch();

    // payload.forEach(personData => {
    //   const ref = firebase
    //     .firestore()
    //     .collection('people')
    //     .doc(`${personData.boardId}_${personData.personId}`);

    //   batch.update(ref, {
    //     boardCoordinateX: personData.boardCoordinateX,
    //     boardCoordinateY: personData.boardCoordinateY,
    //     updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    //   });
    // });

    // await batch.commit();
  },

  async persisAvatarCoordinates(
    context,
    { boardId, personId, boardCoordinateX, boardCoordinateY }
  ) {
    console.log("persisAvatarCoordinates");
    let ret = await firebase
      .firestore()
      .collection("people")
      .doc(`${boardId}_${personId}`)
      .update({
        boardCoordinateX: boardCoordinateX,
        boardCoordinateY: boardCoordinateY,
      });
    console.log(ret);
  },

  async updatePersonInfo(context, { personObj, boardId, updatedBy }) {
    if (personObj.isNoManagerNode) return; // Don't do anything. This is a virtual node.

    const personId = personObj.personId;
    personObj.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    personObj.email = personObj.email ? personObj.email.trim() : "";
    personObj.updatedBy = updatedBy;

    // Some residues from rendering
    delete personObj.isDirty;

    await firebase
      .firestore()
      .collection("people")
      .doc(`${boardId}_${personId}`)
      .update(personObj);

    if (updatedBy) {
      // If updatedBy is empty, we don't store the chage
      // This is probably due to chaging coordinate of the avatar
      await firebase
        .firestore()
        .collection("people_history")
        .doc()
        .set(personObj);
    }

    // update state
    context.commit("updatePerson", personObj);
  },

  async propagatePersonInfoChangesToFuture(
    context,
    { board, boards, personObj, updatedBy, updateSubordinates }
  ) {
    console.log("propagatePersonInfoChangesToFuture");
    if (personObj.isNoManagerNode) return; // Don't do anything. This is a virtual node.

    personObj.email = personObj.email?.trim();
    const currentQuarterMomentObj = Vue.moment(board.timeTarget, "Q - YYYY");
    // Figure out the future boards
    const futureClones = Vue._.filter(boards, (b) => {
      let t = Vue.moment(b.timeTarget, "Q - YYYY");
      return (
        currentQuarterMomentObj.isBefore(t) &&
        b.originalBoardId === board.originalBoardId &&
        board.masterBranch === true &&
        b.companyId === board.companyId
      );
    });

    const boardsToBeUpdated = Vue._.map(futureClones, (b) => {
      return b.boardId;
    });

    if (boardsToBeUpdated.length === 0) return;

    const querySnapshot = await firebase
      .firestore()
      .collection("people")
      .where("personId", "==", personObj.personId)
      .where("boardId", "in", boardsToBeUpdated)
      .get();

    let batch = firebase.firestore().batch();
    querySnapshot.forEach(async (doc) => {
      let data = Vue._.cloneDeep(doc.data());
      data["avatarImg"] = personObj["avatarImg"];

      data["email"] = personObj["email"] ? personObj["email"] : "";
      data["boardAccess"] = personObj["boardAccess"]
        ? personObj["boardAccess"]
        : [];
      // data['HolidayDates'] = personObj['HolidayDates'];
      data["employeeData"] = personObj["employeeData"];
      data["employeeStatus"] = personObj["employeeStatus"];
      data["managers"] = personObj["managers"];
      data["name"] = personObj["name"];
      data["role"] = personObj["role"];

      // To avoid accidental overrides
      if (updateSubordinates) {
        data["subordinates"] = personObj["subordinates"];
      }

      data["boardCoordinateX"] = personObj["boardCoordinateX"];
      data["boardCoordinateY"] = personObj["boardCoordinateY"];
      if (updatedBy) {
        data["updatedBy"] = updatedBy;
      }
      data["updatedAt"] = firebase.firestore.FieldValue.serverTimestamp();
      batch.update(doc.ref, data);
    });

    var ret = await batch.commit();
    return ret;
  },

  async propagateSubordinateChangesToFuture(
    context,
    { board, boards, personObj, managerObj, updatedBy }
  ) {
    // console.log('propagateSubordinateChangesToFuture');
    if (personObj.isNoManagerNode) return; // Don't do anything. This is a virtual node.

    const currentQuarterMomentObj = Vue.moment(board.timeTarget, "Q - YYYY");
    // Figure out the future boards
    const futureClones = Vue._.filter(boards, (b) => {
      let t = Vue.moment(b.timeTarget, "Q - YYYY");
      return (
        currentQuarterMomentObj.isBefore(t) &&
        b.originalBoardId === board.originalBoardId &&
        board.masterBranch === true &&
        b.companyId === board.companyId
      );
    });

    const boardsToBeUpdated = Vue._.map(futureClones, (b) => {
      return b.boardId;
    });

    if (boardsToBeUpdated.length === 0) return;

    // Ensure no other avatar has the same subordinate as this person in the board

    firebase
      .firestore()
      .runTransaction(async (transaction) => {
        // Remove all occurances of the given personObj's ID in the org chart
        let removeSubordinateDuplicates = await firebase
          .firestore()
          .collection("people")
          .where("boardId", "in", boardsToBeUpdated)
          .where("personId", "!=", managerObj.personId)
          .where("subordinates", "array-contains", personObj.personId)
          .get();

        removeSubordinateDuplicates.forEach((futureAvatar) => {
          transaction.update(futureAvatar.ref, {
            subordinates: firebase.firestore.FieldValue.arrayRemove(
              personObj.personId
            ),
            updatedBy: updatedBy,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
        });

        // Add the subordinate to the new manager
        Vue._.each(boardsToBeUpdated, async (boardId) => {
          const futureManagerDoc = await firebase
            .firestore()
            .collection("people")
            .doc(`${boardId}_${managerObj.personId}`);
          transaction.update(futureManagerDoc, {
            subordinates: firebase.firestore.FieldValue.arrayUnion(
              personObj.personId
            ),
            updatedBy: updatedBy,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });

          const futurePersonObj = await firebase
            .firestore()
            .collection("people")
            .doc(`${boardId}_${personObj.personId}`);

          transaction.update(futurePersonObj, {
            managers: [managerObj.personId],
          });
        });
      })

      .then(function () {
        window.console.log("propagateSubordinateChangesToFuture completed");
      })
      .catch(function (error) {
        window.console.error("Error writing document: ", error);
        if (Sentry) Sentry.captureException(error);
      });
  },

  addPerson(context, { personObj, boardId, createdBy }) {
    if (!boardId) throw "BoardId is undefined";

    if (personObj.isNoManagerNode) return; // Don't do anything. This is a virtual node.

    personObj.createdBy = createdBy;
    personObj.updatedBy = createdBy;
    personObj.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    personObj.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    try {
      // window.console.log('New person will be added', personObj.name);

      return firebase
        .firestore()
        .collection("people")
        .doc(`${boardId}_${personObj.personId}`)
        .set(personObj);
    } catch (e) {
      window.console.error("Error adding new person: ", e);
      console.error(personObj);
      if (Sentry) Sentry.captureException(e);
    }
  },

  addCompensation(
    context,
    {
      personId,
      boardId,
      payPer,
      payRate,
      payRateEffectiveDate,
      payType,
      createdBy,
    }
  ) {
    if (!boardId) throw "BoardId is undefined";
    // if (personObj.isNoManagerNode) return; // Don't do anything. This is a virtual node.

    try {
      // window.console.log('New person will be added', personObj.name);

      return firebase
        .firestore()
        .collection("compensations")
        .doc(`${boardId}_${personId}`)
        .set({
          personId: personId,
          boardId: boardId,
          payPer: payPer,
          payRate: payRate,
          payRateEffectiveDate: payRateEffectiveDate,
          payType: payType,
          createdBy: createdBy,
          updatedBy: createdBy,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (e) {
      window.console.error("Error adding new compensation: ", e);
      // console.error(personId);
      if (Sentry) Sentry.captureException(e);
    }
  },

  async addOpenRole(
    context,
    {
      newPersonId,
      managerObj,
      placementCoordinates,
      boardId,
      createdBy,
      updatedBy,
      role,
      status,
    }
  ) {
    if (!boardId) throw "BoardId is undefined";
    let newPersonObj = {
      boardId: boardId,
      personId: newPersonId,
      name: "",
      role: role || "",
      email: "",
      avatarImg:
        "https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5",
      officeLocation: "",
      boardCoordinateX: placementCoordinates.x,
      boardCoordinateY: placementCoordinates.y,
      subordinates: [],
      managers: managerObj ? [managerObj.personId] : [],
      employeeStatus: status ? status : "Planned",
      employeeData: {
        holidayDates: [],
        department: "",
        salary: "",
        startDate: "",
      },
      createdBy: createdBy,
      updatedBy: updatedBy,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (managerObj?.employeeData?.department) {
      newPersonObj.employeeData.department = managerObj.employeeData.department;
    }

    // TODO this has to be a trasaction instead of a chain request
    try {
      console.log(newPersonObj);
      await firebase
        .firestore()
        .collection("people")
        .doc(`${boardId}_${newPersonId}`)
        .set(newPersonObj);

      if (managerObj && !managerObj.isNoManagerNode) {
        if (
          !managerObj["subordinates"] ||
          !Array.isArray(managerObj["subordinates"])
        ) {
          managerObj["subordinates"] = [];
        }
        managerObj["subordinates"].push(newPersonId);
        managerObj["subordinates"] = Vue._.uniq(managerObj["subordinates"]);
        await firebase
          .firestore()
          .collection("people")
          .doc(`${boardId}_${managerObj.personId}`)
          .update({
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            subordinates: managerObj["subordinates"],
          });
      }

      await firebase
        .firestore()
        .collection("people_history")
        .doc()
        .set(newPersonObj);

      window.console.log("New Person Added");
      return newPersonObj;
    } catch (e) {
      window.console.error("Error adding new person: ", e);
      if (Sentry) Sentry.captureException(e);
    }
  },

  async addOpenRoleToFuture(
    context,
    {
      newPersonId,
      managerId,
      boardsToBeUpdated,
      placementCoordinates,
      createdBy,
      updatedBy,
    }
  ) {
    try {
      let batch = firebase.firestore().batch();

      // Query all future manager objects
      const querySnapshot = await firebase
        .firestore()
        .collection("people")
        .where("personId", "==", managerId)
        .where("boardId", "in", boardsToBeUpdated)
        .get();

      querySnapshot.forEach(async (doc) => {
        let data = Vue._.cloneDeep(doc.data());

        // First, add the new open role
        let newPersonObj = {
          boardId: data.boardId,
          personId: newPersonId,
          name: "",
          role: "",
          email: "",
          avatarImg:
            "https://firebasestorage.googleapis.com/v0/b/orgraph-d57a6.appspot.com/o/open_role.png?alt=media&token=5a831181-2239-482a-93e2-0e795a4af0e5",
          officeLocation: "",
          boardCoordinateX: placementCoordinates.x,
          boardCoordinateY: placementCoordinates.y,
          subordinates: [],
          managers: [managerId],
          employeeStatus: "Needed",
          employeeData: {
            holidayDates: [],
            department: "",
            salary: "",
            startDate: "",
          },
          customFields: [],
          createdBy: createdBy,
          updatedBy: updatedBy,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        };

        const ref = firebase
          .firestore()
          .collection("people")
          .doc(`${data.boardId}_${newPersonId}`);
        batch.set(ref, newPersonObj);

        // Second, add the new open role to the manager
        data["subordinates"] = Vue._.union(data["subordinates"], [newPersonId]);
        if (updatedBy) {
          data["updatedBy"] = updatedBy;
        }
        data["updatedAt"] = firebase.firestore.FieldValue.serverTimestamp();
        batch.update(doc.ref, data);
      });
      await batch.commit();
    } catch (e) {
      window.console.error("Error adding new person: ", e);
      if (Sentry) Sentry.captureException(e);
    }
  },

  async removePerson(
    context,
    { managerObj, removedPersonId, boardId, updatedBy }
  ) {
    firebase
      .firestore()
      .runTransaction((transaction) => {
        const toBeDeletedRef = firebase
          .firestore()
          .collection("people")
          .doc(`${boardId}_${removedPersonId}`);

        return transaction.get(toBeDeletedRef).then(async (refDoc) => {
          if (!refDoc.exists) {
            throw "Document does not exist";
          }

          // transaction.delete(toBeDeletedRef);
          let doc = refDoc.data();
          doc.updatedBy = updatedBy;
          doc.removed = true;
          doc.employeeStatus = "Removed";

          doc.employeeData.terminationDate = Vue.moment().format("YYYY-MM-DD");
          doc.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          if (updatedBy) {
            doc.updatedBy = updatedBy;
          }

          console.log(managerObj);
          if (managerObj && !managerObj.isNoManagerNode) {
            let managerRef = firebase
              .firestore()
              .collection("people")
              .doc(`${boardId}_${removedPersonId}`);

            let managerRefDoc = await transaction.get(managerRef);
            let managerDoc = managerRefDoc.data();

            var subordinates = managerDoc.subordinates;
            Vue._.remove(subordinates, (subordinate) => {
              return subordinate === removedPersonId;
            });

            await transaction.update(managerRef, {
              subordinates: subordinates,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            });
          }

          await transaction.update(refDoc.ref, doc);

          if (updatedBy) {
            let historyRef = firebase
              .firestore()
              .collection("people_history")
              .doc();
            await transaction.set(historyRef, doc);
          }
        });
      })
      .then(function () {
        window.console.log("Removal completed");

        context.commit("removePerson", removedPersonId);
      })
      .catch(function (error) {
        window.console.error("Error writing document: ", error);
        if (Sentry) Sentry.captureException(error);
      });
  },

  reset({ commit }) {
    commit("reset");
  },

  resetTeamModule({ commit }) {
    commit("setPeopleData", []);
  },

  async fetchUserInfos(context, userIds) {
    const query = Vue._.map(userIds, (uid) => {
      return { uid: uid };
    });

    // Breakdown the user list by 100
    // If it's bigger than 100, the getUsers call in the cloud function
    // will break

    var getUsers = firebase.functions().httpsCallable("getUsers");
    const promises = [];

    const userIdChunks = Vue._.chunk(query, 100);

    Vue._.each(userIdChunks, (ids) => {
      const promise = new Promise((resolve, reject) => {
        getUsers({ userIds: ids })
          .then((ret) => {
            resolve(ret);
          })
          .catch((e) => {
            reject(e);
          });
      });
      promises.push(promise);
    });

    return Promise.all(promises).then((values) => {
      let ret = Vue._.map(values, (userInfos) => {
        return userInfos.data;
      });
      ret = Vue._.flatten(ret);
      context.commit("setOwnerInfos", ret);
      return ret;
    });

    // const ret = await getUsers({ userIds: query });

    // context.commit("setOwnerInfos", ret.data);
    // return ret.data;
  },

  async sendInvite(
    state,
    {
      boardName,
      boardId,
      invitedBy,
      inviterEmail,
      sendTo,
      companyId,
      accessLevel,
    }
  ) {
    const origin = `${window.location.origin}/#`;

    // Remove existing invites
    const querySnapshot = await firebase
      .firestore()
      .collection("invitations")
      .where("companyId", "==", companyId)
      .where("boardId", "==", boardId)
      .where("recipient", "==", sendTo)
      .where("inviterEmail", "==", inviterEmail)
      .get();
    // Mark the existing invitation as a duplicate
    // This is not a good solution as it does not cover the case where
    // multiple people have sent an invitation to a same person
    querySnapshot.forEach((snapshot) => {
      snapshot.ref.update({
        ignore: true,
      });
    });

    var sendInvite = firebase.functions().httpsCallable("sendInvite");
    // TODO catch errors

    await sendInvite({ origin, boardName, boardId, invitedBy, sendTo });

    const invitationAccepted = false;
    const recipient = sendTo;
    const createdAt = firebase.firestore.FieldValue.serverTimestamp();

    await firebase.firestore().collection("invitations").doc().set({
      boardName,
      boardId,
      invitedBy,
      inviterEmail,
      recipient,
      companyId,
      accessLevel,
      invitationAccepted,
      createdAt,
    });

    window.mixpanel.track("invite_others");
  },

  async reflectPermissionChanges(
    state,
    { companyId, targetPersoUid, accessLevel }
  ) {
    const querySnapshot = await firebase
      .firestore()
      .collection("board")
      .where("companyId", "==", companyId)
      .get();

    try {
      var batch = firebase.firestore().batch();
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.owners.push(targetPersoUid);

        data.owners = Vue._.uniq(data.owners);
        if (!data.accessLevels) {
          data.accessLevels = [];
        }
        data.accessLevels.push({
          uid: targetPersoUid,
          accessLevel: accessLevel,
        });
        batch.update(doc.ref, data);
      });

      await batch.commit();
    } catch (e) {
      console.error(e);
    }
  },
};

/**
 * Validate data before updating people data to Vuex.
 * If there is any weird data, it warns through console.warn and changes the data to avoid the console error in the utils.
 *
 * @param {*} peopleData
 * @returns peopleData
 */
const validatePeopleData = (peopleData) => {
  const personIdList = peopleData.map((person) => person.personId);

  peopleData = peopleData.map((person) => {
    // Check valid subordinates data
    if (
      person.subordinates.length &&
      person.subordinates.some((personId) => !personIdList.includes(personId))
    ) {
      console.warn(
        `Found an invalid subordinate personId. [${
          person.personId
        } => ${person.subordinates.find(
          (personId) => !personIdList.includes(personId)
        )}]`
      );
      person.subordinates = person.subordinates.filter((personId) =>
        personIdList.includes(personId)
      );
    }

    /* Add the another condition here */

    return person;
  });

  return peopleData;
};

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {},
};

const sanityCheckPeopleData = (peopleData) => {
  // Check to make sure they are not a manager of themselves or empty string
  Vue._.each(peopleData, (person) => {
    Vue._.remove(person.subordinates, (s) => {
      return s === person.personId || s === "";
    });
  });

  // Check if all the subordinate & manager infos are correct.
  let filteredPeople = peopleData.filter((person) => person.removed !== true);

  // No subordinates should have two managers
  var allSubordinates = Vue._.map(filteredPeople, "subordinates");
  allSubordinates = Vue._.flattenDeep(allSubordinates);

  // Only one manager is allowed. Check for duplicates in the subordinates list
  const counts = Vue._.countBy(allSubordinates);
  // List of the subordinates that need to be fixed
  var toBeFixed = Vue._.map(counts, (v, k) => {
    if (v > 1) {
      return k;
    }
  });
  toBeFixed = Vue._.compact(toBeFixed);

  console.log("toBeFixed", toBeFixed.length);

  peopleData = peopleData.map((person) => {
    if (Vue._.xor([toBeFixed, person.subordinates]).length !== 0) {
      // remove the person from the subordinates list and the toBeFixed list
      Vue._.remove(person.subordinates, person.personId);
      Vue._.remove(toBeFixed, person.personId);
    }
    return person;
  });

  filteredPeople = peopleData.filter((person) => person.removed !== true);
  allSubordinates = Vue._.map(filteredPeople, "subordinates");
  allSubordinates = Vue._.flattenDeep(allSubordinates);

  const hasTwoManagersPersonIdsArray = Vue._.uniq(
    Vue._.filter(allSubordinates, (v, i, a) => a.indexOf(v) !== i)
  );
  Vue._.each(hasTwoManagersPersonIdsArray, (subordinatePersonId) => {
    const managerObj = Vue._.find(peopleData, (person) => {
      return person.subordinates.includes(subordinatePersonId);
    });
    // console.log('[managerObj', managerObj);
    if (!Vue._.isArray(managerObj.subordinates)) {
      managerObj.subordinates = [];
    }

    Vue._.remove(managerObj.subordinates, (_id) => {
      return _id === subordinatePersonId;
    });
  });

  const peopleDict = Vue._.keyBy(peopleData, "personId");
  Vue._.each(state.people, (person) => {
    var newSubordinateList = [];
    Vue._.each(person.subordinates, (_id) => {
      if (peopleDict[_id]) {
        newSubordinateList.push(_id);
        peopleDict[_id].managers = [person.personId];
      } else {
        // This should never happen
        console.warn("unstable subordinates relationship, removing", _id);
      }
    });
    person.subordinates = newSubordinateList;

    if (!person.managers) person.managers = [];
    // We cannot have more one manager for now.
    if (person.managers.length > 1) {
      person.managers = person.managers[0];
    }
  });

  // Remove from the peopleData of `Removed` users
  const removedPersonIds = peopleData
    .filter((person) => person.employeeStatus === "Removed")
    .map((person) => person.personId);

  peopleData = peopleData
    .filter((person) => person.employeeStatus !== "Removed")
    .map((person) => {
      if (!Vue._.isArray(person?.subordinates)) {
        person.subordinates = [];
      }
      if (
        person?.subordinates?.some((childPersonId) =>
          removedPersonIds.includes(childPersonId)
        )
      ) {
        person.subordinates = person.subordinates.filter(
          (childPersonId) => !removedPersonIds.includes(childPersonId)
        );
      }
      return person;
    });

  return peopleData;
};

const fixDiscontinuity = (peopleData) => {
  // Find the nodes without a manager.
  // Move them to a special node for those without a manager

  const filteredPeople = peopleData.filter((person) => {
    return person.removed !== true || person.employeeStatus === "Removed";
  });
  const peopleDict = Vue._.keyBy(filteredPeople, "personId");

  var subordinateList = Vue._.map(filteredPeople, (person) => {
    return person.subordinates;
  });
  subordinateList = Vue._.flatten(subordinateList);
  subordinateList = Vue._.uniq(subordinateList);

  const personIdList = Vue._.map(filteredPeople, "personId");
  let noManagerList = Vue._.xor(subordinateList, personIdList);

  let ceoId = Vue._.find(noManagerList, (pID) => {
    return peopleDict[pID]
      ? peopleDict[pID].role.toLowerCase() === "ceo"
      : null;
  });

  if (!ceoId) return;

  Vue._.remove(noManagerList, (pID) => {
    return pID === ceoId;
  });

  // Remove personIds that do not exist
  const _noManagerList = [];
  Vue._.each(noManagerList, (personId) => {
    if (peopleDict[personId]) {
      _noManagerList.push(personId);
    }
  });
  noManagerList = _noManagerList;
  console.log("These people do not have a manager", _noManagerList);

  if (noManagerList.length === 0) return;

  // Find the special NO MANAGER node.
  // If it does not exist, create a new one
  let noManagerNode = Vue._.find(peopleData, (person) => {
    return person.isNoManagerNode;
  });

  const boardId = peopleData[0].boardId;

  if (noManagerNode) {
    if (!noManagerNode.subordinates) {
      noManagerNode.subordinates = [];
    }

    noManagerNode.subordinates =
      noManagerNode.subordinates.concat(noManagerList);

    firebase
      .firestore()
      .collection("people")
      .doc(`${boardId}_${noManagerNode.personId}`)
      .update({
        subordinates: noManagerNode.subordinates,
      });
  } else {
    // Create a new no manager node
    const idCreator = require("@/lib/idCreator");

    noManagerNode = {
      boardId: boardId,
      personId: idCreator.createPersonId(),
      avatarImg:
        "https://ui-avatars.com/api/?name=_&background=FF1F1F&color=fff",
      boardCoordinateX: 0,
      boardCoordinateY: 0,
      subordinates: noManagerList,
      managers: [ceoId],
      employeeStatus: "",
      isNoManagerNode: true,
      name: "",
      role: "Manager Needed",
      employeeData: {
        department: "No Department",
        salary: 0,
      },
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    peopleData.push(noManagerNode);

    peopleDict[ceoId].subordinates.push(noManagerNode.personId);

    // firebase
    //   .firestore()
    //   .collection('people')
    //   .doc(`${peopleData[0].boardId}_${noManagerNode.personId}`)
    //   .set(noManagerNode);

    // // Update the CEO
    // firebase
    //   .firestore()
    //   .collection('people')
    //   .doc(`${boardId}_${ceoId}`)
    //   .update({
    //     subordinates: peopleDict[ceoId].subordinates,
    //   });
  }
};

const resolveAvatarImage = async (personData) => {
  // Replace the default image with an initial image if the name exists
  if (
    (!personData.avatarImg ||
      personData.avatarImg.indexOf("open_role.png") > 0 ||
      personData.avatarImg.indexOf("photo_person_150x150.png") > 0) && // Bamboo default image
    personData.name
  ) {
    personData.avatarImg = `https://ui-avatars.com/api/?name=${personData.name}&background=50B5FF&color=fff`;
  } else {
    // Avatar URI already exists, if this is not in our storage, move it and resize. Unless it's a ui-avatars placeholder.
    if (
      personData.avatarImg.indexOf("ui-avatars") === -1 &&
      personData.avatarImg.indexOf("orgraph-d57a6.appspot.com") === -1
    ) {
      if (personData.afradAvatarImgCopy) {
        console.log("complete the image migration");
        // We have downloaded the image to our server.
        // Update the existing profile photo to the one in the server
        const storage = firebase.storage();
        const path = await storage
          .ref(personData.afradAvatarImgCopy)
          .getDownloadURL();
        // TODO: Why do we do this here and not in the Firebase function?
        // Can't validate it now since our BambooHR trial is done.
        delete personData.afradAvatarImgCopy;
        await firebase
          .firestore()
          .collection("people")
          .doc(`${personData.boardId}_${personData.personId}`)
          .update({
            avatarImg: path,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      } else {
        if (personData.avatarImg) {
          console.log("start the image migration", personData.avatarImg);
          var storeImage = firebase.functions().httpsCallable("storeImage");
          storeImage({
            personId: personData.personId,
            imageURI: personData.avatarImg,
          }).catch((err) => {
            console.error(err);
          });
        }
      }
    }
  }

  if (personData.isNoManagerNode) {
    personData.avatarImg = `https://ui-avatars.com/api/?name=_&background=FF1F1F&color=fff`;
  }
};
