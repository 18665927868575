import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
import * as Sentry from "@sentry/browser";
import "firebase/functions";

const getDefaultState = () => {
  return {
    requests: [],
    queriedRequisition: null,
  };
};

const state = getDefaultState();

const mutations = {
  setRequests(state, requests) {
    state.requests = Vue._.sortBy(requests, (request) => {
      if (request["priority"] === "high") {
        return 1;
      }
      if (request["priority"] === "medium") {
        return 2;
      }
      if (request["priority"] === "low") {
        return 4;
      }
      return 5;
    });
  },
  setRequest(state, request) {
    state.queriedRequisition = request;
  },
};

const actions = {
  async persistRequisitionForm(context, data) {
    data.createdAt = firebase.firestore.FieldValue.serverTimestamp();
    data.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

    try {
      await firebase
        .firestore()
        .collection("requisitions")
        .doc(data.requestId)
        .set(data);
    } catch (e) {
      console.error(e);
      throw "Something went wrong saving";
    }
  },
  async sendEmailForApproval(context, data) {
    try {
      var notifyNewRequisitionRequest = firebase
        .functions()
        .httpsCallable("notifyRequisitionRequest");
      await notifyNewRequisitionRequest(data);
    } catch (e) {
      console.error(e);
      Sentry.captureMessage(e);
      throw "Something went wrong sending";
    }
  },
  async fetchRequisitionRequests(context, data) {
    const querySnapshot = await firebase
      .firestore()
      .collection("requisitions")
      .where("boardId", "==", data.boardId)
      .get();

    const requests = [];
    querySnapshot.forEach((doc) => {
      let request = doc.data();
      requests.push(request);
    });
    context.commit("setRequests", requests);
  },

  async fetchRequisitionRequestForPersonId(context, { personId, boardId }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("requisitions")
      .where("boardId", "==", boardId)
      .where("newPersonId", "==", personId)
      .limit(1)
      .get();

    if (querySnapshot.docs.length > 0)
      context.commit("setRequest", querySnapshot.docs[0].data());
  },

  async fetchRequestByUid(context, { boardId, uid }) {
    const querySnapshot = await firebase
      .firestore()
      .collection("requisitions")
      .where("boardId", "==", boardId)
      .where("createdBy", "==", uid)
      .get();

    const requests = [];
    querySnapshot.forEach((doc) => {
      let request = doc.data();
      requests.push(request);
    });
    context.commit("setRequests", requests);
  },

  async fetchRequest(context, data) {
    const requestId = data.requestId;
    const doc = await firebase
      .firestore()
      .collection("requisitions")
      .doc(requestId)
      .get();

    const requests = [];

    let request = doc.data();
    requests.push(request);

    context.commit("setRequests", requests);
  },

  async fetchRequestsByPlanId(context, { planId, jobGroupNumber, boardId }) {
    try {
      if (!planId || !jobGroupNumber || !boardId) {
        console.error(planId, jobGroupNumber, boardId);
        return;
      }
      const querySnapshot = await firebase
        .firestore()
        .collection("requisitions")
        .where("planId", "==", planId)
        .where("jobGroupNumber", "==", jobGroupNumber)
        .where("boardId", "==", boardId)
        .get();

      const requestIds = [];
      querySnapshot.forEach((doc) => {
        requestIds.push({
          requestId: doc.data().requestId,
          jobCode: doc.data().jobCode,
          planId: planId,
          jobGroupNumber,
        });
      });
      return requestIds;
    } catch (e) {
      console.error(e);
    }
  },

  async approveRequest(context, { requestId, reviewerPersonId }) {
    if (!reviewerPersonId) throw new Error("reviewerPersonId cannot be null");
    await firebase
      .firestore()
      .collection("requisitions")
      .doc(requestId)
      .update({
        approvedList:
          firebase.firestore.FieldValue.arrayUnion(reviewerPersonId),
        pendingApprovalList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        rejectedList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: context.rootGetters.uid,
      });
  },

  // Change the status to pending
  async setRequestToPending(context, { requestId, reviewerPersonId }) {
    if (!reviewerPersonId) throw new Error("reviewerPersonId cannot be null");
    await firebase
      .firestore()
      .collection("requisitions")
      .doc(requestId)
      .update({
        approvedList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        pendingApprovalList:
          firebase.firestore.FieldValue.arrayUnion(reviewerPersonId),
        rejectedList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: context.rootGetters.uid,
      });
  },
  async rejectRequest(context, { requestId, reviewerPersonId }) {
    if (!reviewerPersonId) throw new Error("reviewerPersonId cannot be null");
    await firebase
      .firestore()
      .collection("requisitions")
      .doc(requestId)
      .update({
        approvedList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        pendingApprovalList:
          firebase.firestore.FieldValue.arrayRemove(reviewerPersonId),
        rejectedList:
          firebase.firestore.FieldValue.arrayUnion(reviewerPersonId),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: context.rootGetters.uid,
      });
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
