<template>
  <div>
    <v-row class="mt-6">
      <v-col cols="12" md="12"
        ><h1>Select people that will receive the review form</h1></v-col
      >
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" md="4">
        <!-- <v-text-field
          v-model="searchQuery"
          dense
          label="Search by manager's name, or department"
          class="my-6 ml-2"
        /> -->

        <v-text-field
          v-model="tagQuery"
          dense
          label="Search by tags (Case sensitive)"
          class="my-6 ml-2"
        />

        <v-btn
          class="mx-2"
          small
          outlined
          color="af_green"
          @click="selectedFiltered($event)"
          >Select Filtered Employees</v-btn
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="12" class="d-flex justify-end">
        <v-btn
          class="mx-2"
          small
          outlined
          color="af_green"
          @click="selectEveryone($event)"
          >Select Everyone</v-btn
        >
        <v-btn class="mx-2" small outlined @click="unselectEveryone($event)"
          >Unselect Everyone</v-btn
        >

        <PerformanceReviewSendConfirmation
          :selectedEmployees="selectedEmployees"
          :sentList="sentList"
          :boardId="boardId"
          :templateId="templateId"
          @sent="checkEmailsSent()"
        />

        <!-- <v-btn class="mx-2" small disabled>CSV Export</v-btn> -->
      </v-col></v-row
    >

    <v-row
      v-for="managerPersonId in managers"
      :key="managerPersonId"
      class="mb-4"
    >
      <v-col cols="12">
        <v-card class="card-shadow mx-2 px-4">
          <v-row no-gutters>
            <v-col cols="12" md="7">
              <div
                v-if="employeesByPersonId[managerPersonId]"
                class="py-4 d-flex align-center"
              >
                <v-chip
                  dense
                  class="body-2 white--text"
                  :color="
                    getDepartmentColor(
                      employeesByPersonId[managerPersonId].employeeData
                        .department
                    )
                  "
                >
                  {{
                    employeesByPersonId[managerPersonId].employeeData.department
                  }}
                </v-chip>
                <p class="mt-4 ml-4">
                  {{
                    `Led by ${employeesByPersonId[managerPersonId].name}, ${employeesByPersonId[managerPersonId].role}`
                  }}
                </p>
              </div>
              <div v-else>
                <h2 class="my-2">
                  <v-icon class="mr-2" color="af_red">mdi-account-alert</v-icon
                  ><span class="pt-3 af_red--text"
                    >People without a manager</span
                  >
                </h2>
              </div>
            </v-col>

            <v-col cols="12" md="5" class="d-flex align-center justify-end">
              <h3>
                {{
                  getNumberOfSelectedPeopleInTeam(
                    groupPeopleByManager[managerPersonId]
                  )
                }}
                /
                {{ Object.keys(groupPeopleByManager[managerPersonId]).length }}
                selected
              </h3>

              <v-btn
                outlined
                @click="selectTeam(managerPersonId)"
                class="ml-4 mb-0 mr-4"
                small
                >Select everyone in the team</v-btn
              >

              <v-btn outlined @click="toggleShowMore(managerPersonId)" small
                ><v-icon>mdi-unfold-more-horizontal</v-icon></v-btn
              >
            </v-col></v-row
          >

          <v-simple-table class="mt-0 pt-0">
            <template v-slot:default>
              <thead>
                <th>Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Department</th>
                <th>Tags</th>
                <th>Sent</th>
              </thead>
              <tbody>
                <tr
                  v-for="person in groupPeopleByManager[managerPersonId]"
                  :key="person.personId"
                  :hidden="unmatchingTags(person)"
                >
                  <td style="width: 250px" class="text-truncate">
                    <v-checkbox
                      v-model="selectedEmployees"
                      class="ma-0 pa-0"
                      dense
                      small
                      hide-details
                      color="af_blue"
                      :value="person.personId"
                      :label="employeesByPersonId[person.personId].name"
                      :disabled="isSent(person.personId)"
                    />
                  </td>
                  <td class="text-center">
                    <p class="body-2 mt-4">
                      {{ employeesByPersonId[person.personId].role }}
                    </p>
                  </td>
                  <td class="text-center">
                    <p class="body-2 mt-4">
                      {{ employeesByPersonId[person.personId].email }}
                    </p>
                  </td>
                  <td class="text-center" style="width: 200px">
                    <p class="body-2">
                      {{
                        employeesByPersonId[person.personId].employeeData
                          .department
                      }}
                    </p>
                  </td>
                  <td class="text-right">
                    {{ employeesByPersonId[person.personId].tags }}
                  </td>
                  <td class="text-center">
                    <v-icon v-if="isSent(person.personId)" color="af_green"
                      >mdi-check</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { OutlineColors } from "@/lib/Colors";
import PerformanceReviewSendConfirmation from "@/components/Calibration/PerformanceReviewSendConfirmation";

export default {
  name: "PerformanceReviewTable",
  components: {
    PerformanceReviewSendConfirmation,
  },
  props: {
    templateName: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    // Use this array to sort the order of display
    roleSortOrder: [
      "noManager",
      "ceo",
      "cto",
      "coo",
      "cpo",
      "chro",
      "cfo",
      "chief",
      "vp",
      "director",
      "senior",
      "sr",
    ],
    searchQuery: "",
    tagQuery: "",
    departments: [], // array of uniq department values
    selectedEmployees: [], // array of personIds that will receive performance review
    showTeamList: [], // Array of manager's personId that indicates if the team table should be expanded or collapsed
    sentList: [], // List of personId that the current review is sent to.
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    managers() {
      // Return sorted and filtered managers
      // to be listed on the table
      const ret = this._.chain(this.groupPeopleByManager)
        .keys()
        .filter((personId) => {
          if (!this.employeesByPersonId[personId]) return true;
          if (!this.searchQuery || this.searchQuery.length === 0) return true;

          const targets = [
            this.employeesByPersonId[personId].employeeData.department,
            this.employeesByPersonId[personId].name,
          ];

          // Allow the suboridnates to be searched
          targets.push(
            this._.map(
              this.employeesByPersonId[personId].subordinates,
              (pid) => {
                return this.employeesByPersonId[pid].name;
              }
            ).join(", ")
          );

          return this._.some(targets, (t) => {
            if (!t) return true;
            return t.toLowerCase().includes(this.searchQuery.toLowerCase());
          });
        })

        .sortBy((personId) => {
          if (personId === "noManager") return 0;
          const role = this.employeesByPersonId[personId]
            ? this.employeesByPersonId[personId].role.toLowerCase()
            : "";

          let index = this._.findIndex(this.roleSortOrder, (token) => {
            return role.split(" ")[0].includes(token);
          });
          if (index === -1) return 9999;
          return index;
        })
        .value();

      return ret;
    },
  },
  watch: {
    searchQuery: function () {},
    groupPeopleByManager: function () {
      this.departments = this._.chain(this.people)
        .map((p) => {
          return p.employeeData.department.split("-")[0].trim();
        })
        .uniq()
        .sortBy((t) => {
          // This ensures that the color code stays (kinda...)
          return t;
        })
        .value();
    },
  },
  mounted() {
    this.fetchPeople({ boardId: this.boardId });
    this.checkEmailsSent();
  },
  methods: {
    ...mapActions(["fetchPeople", "fetchPerformanceReviewReceivers"]),
    getDepartmentColor(department) {
      return OutlineColors[
        this.departments.indexOf(department.split("-")[0].trim())
      ];
    },
    unmatchingTags(person) {
      if (!this.tagQuery) return false;
      if (!person.tags) return false;

      const tags = this.tagQuery.split(",").map((t) => t.trim());
      const intersection = this._.intersection(tags, person?.tags);
      return intersection.length !== tags.length;

      // .filter(personId =>{
      //   if (this.tagQuery) {
      //
      //

      //     return intersection.length === tags.length
      //     // return !.includes()
      //   }
      //   return true
      // })
      // "Yes", "Non-Ops"
    },
    selectTeam(managerPersonId) {
      // Select everyone in the team
      const people = this.groupPeopleByManager[managerPersonId];
      this._.each(people, (p) => {
        if (this.selectedEmployees.indexOf(p.personId) === -1) {
          this.selectedEmployees.push(p.personId);
        }
      });
    },
    selectedFiltered() {
      const filteredEmployees = this._.filter(this.activeEmployees, (e) => {
        return !this.unmatchingTags(e);
      });
      this._.each(filteredEmployees, (e) => {
        if (this.selectedEmployees.indexOf(e.personId) === -1) {
          this.selectedEmployees.push(e.personId);
        }
      });
    },
    selectEveryone() {
      this._.each(this.activeEmployees, (p) => {
        if (this.selectedEmployees.indexOf(p.personId) === -1) {
          this.selectedEmployees.push(p.personId);
        }
      });
    },
    unselectEveryone() {
      this.selectedEmployees.splice(0);
      // this._.each(this.activeEmployees, (p) => {
      //   this.selectedEmployees.splice(
      //     this.selectedEmployees.indexOf(p.personId),
      //     1
      //   );
      // });
    },
    getNumberOfSelectedPeopleInTeam(managerObj) {
      const team = this._.map(managerObj, (person) => {
        return person.personId;
      });
      const overlap = this._.intersection(this.selectedEmployees, team);
      return overlap.length;
    },
    toggleShowMore(managerPersonId) {
      if (this.showTeamList.includes(managerPersonId)) {
        this.showTeamList.splice(this.showTeamList.indexOf(managerPersonId), 1);
      } else {
        this.showTeamList.push(managerPersonId);
      }
    },
    isSent(personId) {
      // Check if the review was already sent to the person
      return this.sentList.includes(personId);
    },
    async checkEmailsSent() {
      // Emails are sent. Fetch the receivers list again
      // So we can show the sent indicators
      // Fetch saved performance review receivers
      const receivers = await this.fetchPerformanceReviewReceivers({
        boardId: this.boardId,
        templateId: this.templateId,
      });
      if (receivers && receivers.receivers) {
        const personIds = this._.map(receivers.receivers, "personId");
        this.selectedEmployees.splice(0); // Clear the contents first
        this.selectedEmployees.push(...personIds);
      }

      if (receivers && receivers.sent) {
        this.sentList.splice(0);
        this.sentList.push(...receivers.sent);
      }
    },
  },
};
</script>
