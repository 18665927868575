<template>
  <v-container>
    <v-row v-if="!isMainWebsite" class="d-flex justify-center mt-4">
      <v-col cols="12" lg="5" md="6" sm="12" class="text-center">
        <div v-if="isLoading">
          <v-progress-circular
            :size="70"
            :width="7"
            color="af_blue"
            indeterminate
            class="mt-12 mb-12"
          ></v-progress-circular>
          <h3>Loading...</h3>
        </div>

        <!-- <v-card
          v-if="!isLoading"
          flat
          class="pa-6 animate__animated animate__fadeIn"
        >
          <p class="body-1">What's your work email?</p>
          <v-text-field
            v-model="email"
            type="email"
            placeholder="example@company.com"
            :rules="emailRules"
            
          ></v-text-field>
          <v-btn
            color="af_blue"
            class="white--text"
            :loading="isLoading"
            @click="toLogin"
            >Next</v-btn
          >
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import firebase from "firebase/app";
// import "firebase/auth";
import "animate.css";

export default {
  name: "Home",
  data: () => ({
    isLoading: true,
    email: "",
    emailRules: [
      (value) => !!value || "E-mail is Required",
      (value) =>
        (value && !value.includes("@gmail.com")) ||
        "Please use your work email",
    ],
  }),
  computed: {
    isMainWebsite() {
      return window.location.origin === "https://agentnoon.com";
    },
    redirect() {
      return this.$route.query.redirect;
    },
  },
  watch: {},
  mounted() {
    return this.$router.push({
      path: "/signup",
      query: { redirect: this.redirect },
    });

    // firebase.auth().onAuthStateChanged(async (userObj) => {
    //   this.isLoading = false;
    //   if (userObj) {
    //     // User logged in

    //     // Go to verification page if the email is not verified yet
    //     if (!userObj.emailVerified) {
    //       return this.$router.push({ path: "/verify" });
    //     }

    //     return this.$router.push({ path: "/start" });
    //   }
    //   // else {
    //   //   // User not logged in and not in the signup page
    //   //   if (this.$route.name !== "Signup" && this.$route.name !== "Login")
    //   //     return this.$router.push({ path: "/login" });
    //   // }
    // });
  },
  methods: {
    async toLogin() {
      // if (this.email && !this.email.includes("@gmail.com")) {
      //   this.isLoading = true;
      //   var checkAccountStatus = firebase
      //     .functions()
      //     .httpsCallable("checkAccountStatus");
      //   const ret = await checkAccountStatus({
      //     email: this.email,
      //   });
      //   this.isLoading = false;
      //   // const providerId = ret.data.status;
      //   let redirect = "";
      //   if (this.$route.query.redirect) {
      //     redirect = this.$route.query.redirect;
      //   }
      //   return this.$router.push({ path: "/signup", query: { redirect } });
      // if (providerId === "password") {
      //   // This user previously signed up with email/password authentication
      //   return this.$router.push({
      //     path: "/login",
      //     query: { method: providerId, email: this.email, redirect },
      //   });
      // } else if (providerId === "google.com") {
      //   // This user previously signed up with Google OAuth
      //   return this.$router.push({
      //     path: "/login",
      //     query: { method: providerId, redirect },
      //   });
      // } else if (providerId === "none") {
      //   // This user does not exist yet.
      //   // Direct the user to signup page
      //   // For ProxyClick, they wanted email/password signup
      //   // if (this.email.includes("@proxyclick.com")) {
      //   //   return this.$router.push({
      //   //     path: "/signup",
      //   //     query: { method: "password", email: this.email, redirect },
      //   //   });
      //   // }
      //   return this.$router.push({ path: "/signup", query: { redirect } });
      // }
      // }
    },
  },
};
</script>
