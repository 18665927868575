<template>
  <div>
    <v-card class="mx-auto my-12 pa-4" max-width="1200">
      <v-progress-linear
        v-if="isFetching"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <div v-if="!isFetching">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th>Title</th>
                <th class="text-left">Manager</th>
                <th class="text-left">Department</th>
                <th class="text-left">Submission</th>
                <!-- <th class="text-left">Final Rating</th> -->
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(review, index) in reviews"
                :key="review.revieweePersonId + review.templateId"
              >
                <td>
                  {{ index + 1 }}.
                  {{ getName(review.revieweePersonId, review.revieweeName) }}
                </td>
                <td>
                  {{ getTitle(review.revieweePersonId) }}
                </td>
                <td>
                  {{ getManager(review.revieweePersonId) }}
                </td>
                <td>{{ getDepartment(review.revieweePersonId) }}</td>
                <td>{{ review.templateName }}</td>
                <!-- <td>
                  {{ getFinalRating(review) }}
                </td> -->
                <td v-if="review.templateId">
                  <v-btn
                    small
                    :to="`/PerformanceReviewReadOnly/${boardId}/${review.templateId}/${review.revieweePersonId}`"
                    target="_blank"
                    >View</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PerformanceReviewHRBPAccess",
  props: {},
  data: () => ({
    reviews: [],
    isFetching: true,
    confirmed: false,
    templatesByTemplateId: {},
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "isAdmin",
      "role",
      "activeEmployees",
      "user",
      "people",
      "role",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    boards() {
      return this.$store.state.board.boards;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    myEmail() {
      if (this.user) {
        return this.user.email;
      }
      return null;
    },
    departmentCode() {
      return this.$route.params.departmentCode;
    },
    myName() {
      if (!this.myEmail) return null;
      // We use this to see if I should be able to approve this request
      if (this.getPersonObjByEmail(this.myEmail)) {
        return this.getPersonObjByEmail(this.myEmail).name;
      }
      return null;
    },
    myPersonId() {
      if (!this.myEmail) return null;
      // We use this to see if I should be able to approve this request
      if (this.getPersonObjByEmail(this.myEmail)) {
        return this.getPersonObjByEmail(this.myEmail).personId;
      }
      return null;
    },
    myAccessLevel() {
      return this.role(this.boardId);
    },
  },
  watch: {
    boards() {
      if (this.boards.length > 0) {
        this.init();
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions([
      "fetchAllBoards",
      "fetchPeople",
      "fetchReviewsForPersonIds",
      "fetchPerformanceReviewTemplate",
      "fetchPerformanceReviews",
    ]),
    async init() {
      this.isFetching = true;

      await this.fetchPeople({ boardId: this.boardId });

      const settings = await this.fetchPerformanceReviews({
        boardId: this.boardId,
      });

      this.templatesByTemplateId = _.keyBy(settings, "templateId");

      // Check if I have the right access
      if (!["admin", "hr"].includes(this.myAccessLevel)) {
        this.isFetching = false;
        return;
      }

      let reviewees = this._.filter(this.people, (person) => {
        const visible = this._.find(person.customFields, (field) => {
          // const value = this._.values(field)[0];
          const key = this._.keys(field)[0];
          return key === "hrbpEmail" || key === "HrbpEmail";
        });
        const hrbpEmail = this._.values(visible)[0];
        if (hrbpEmail === this.myEmail) return true;
      });

      reviewees = this._.map(reviewees, (reviewee) => {
        return reviewee.personId;
      });
      reviewees = this._.flatten(reviewees);

      const targetPersonIds = this._.uniq(reviewees);

      const reviews = await this.fetchReviewsForPersonIds({
        boardId: this.boardId,
        targetPersonIds,
      });

      const dictReviews = this._.groupBy(reviews, "revieweePersonId");

      this.reviews = this._.map(reviews, (review) => {
        return {
          revieweeName: review.revieweeName,
          revieweePersonId: review.revieweePersonId,
          templateName: this.templatesByTemplateId[review.templateId].name,
          templateId: review.templateId,
        };
      });

      // this.reviews = this._.sortBy(this.reviews, (review) => {
      //   return review.templateId;
      // });

      this.reviews = this._.sortBy(this.reviews, (review) => {
        return this.getName(review.revieweePersonId, review.revieweeName);
      });

      this.isFetching = false;
    },
    getPersonObjByEmail(email) {
      return this._.find(this.people, (person) => {
        return person.email === email;
      });
    },
    getDepartment(personId) {
      return this.employeesByPersonId[personId].employeeData.department;
    },
    getName(personId, revieweeName) {
      return revieweeName
        ? revieweeName
        : this.employeesByPersonId[personId].name;
    },
    getFinalRating(review) {
      return review.CEORating ? review.CEORating : review.VPRating;
    },
    getManager(revieweePersonId) {
      const managerPersonId =
        this.employeesByPersonId[revieweePersonId].managers[0];
      return this.employeesByPersonId[managerPersonId]
        ? this.employeesByPersonId[managerPersonId].name
        : "";
    },
    getTitle(revieweePersonId) {
      return this.employeesByPersonId[revieweePersonId]?.role;
    },
  },
};
</script>
