<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="red lighten-2"
        dark
        v-bind="attrs"
        v-on="on"
        elevation="0"
        class="mx-2"
        data-intercom-target="delete scenario"
      >
        Delete this scenario
      </v-btn>
    </template>

    <v-card class="px-4 pt-8 pb-6">
      <v-card-text>
        Delete this scenario? You cannot reverse this action.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="isDeleting" outlined text @click="dialog = false"
          >Cancel
        </v-btn>
        <v-btn
          :loading="isDeleting"
          color="af_red"
          outlined
          text
          :disabled="board.masterBranch"
          @click="deleteBoard"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/firestore";

export default {
  name: "DeleteScenario",
  data: () => ({
    dialog: false,
    isDeleting: false,
  }),
  computed: {
    ...mapGetters(["uid", "myPersonId", "people", "user", "getBoard"]),
    boardId() {
      return this.$route.params.boardId;
    },
    board() {
      return this.getBoard(this.boardId);
    },
  },
  watch: {},
  mounted() {},
  methods: {
    async deleteBoard() {
      // Safeguard.
      // It's important to not delete the wrong data :)
      //   - Do not allow delete if boardId is not set
      //   - Do not allow delete if this is the master branch
      // There is an additional safeguard in Firestore rules
      // to remove the single point of failiure.
      if (!this.boardId || this.board.masterBranch) return;
      this.isDeleting = true;
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection("people")
          .where("boardId", "==", this.boardId)
          .get();
        var batch = firebase.firestore().batch();
        querySnapshot.forEach(async (doc) => {
          batch.delete(doc.ref);
        });
        await batch.commit();
      } catch (e) {
        console.log("error in people delete");
        console.error(e.code);
        console.error(e.message);
      }

      try {
        await firebase
          .firestore()
          .collection("board")
          .doc(this.boardId)
          .delete();
      } catch (e) {
        console.log("error in board deletion");
        console.error(e);
      }

      window.location = "/";
      // this.isDeleting = false;
    },
  },
};
</script>
