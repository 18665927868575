<template>
  <v-container class="d-flex justify-center">
    <v-card height="230" class="px-12 pt-6" elevation="1">
      <v-btn small @click="resetPassword" text block class="mt-2"
        >Forgot Password?</v-btn
      >

      <v-text-field
        label="Please enter your email"
        v-model="email"
      ></v-text-field>
      <v-btn
        small
        @click="resetPassword"
        color="af_blue"
        block
        class="mt-2 white--text"
        :loading="isSending"
        >Send password reset link to my email</v-btn
      >

      <h3 v-if="sent" class="mt-4">Reset link sent!</h3>
    </v-card>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "animate.css";
import * as Sentry from "@sentry/browser";

export default {
  name: "PasswordReset",
  components: {},
  data() {
    return {
      email: "",
      sent: false,
      isSending: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    resetPassword() {
      this.isSending = true;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.isSending = false;
          this.sent = true;
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode, errorMessage);
          if (Sentry) Sentry.captureException(error);
        });
    },
  },
};
</script>

<style scoped></style>
