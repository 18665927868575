<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab>Template</v-tab>
      <v-tab>People</v-tab>
      <v-tab>Manager's Review</v-tab>
      <v-tab>Report</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <PerformanceReviewTemplate />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <PerformanceReviewTable />
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <PerformanceReviewManagerReviewTable />
      </v-tab-item>

      <v-tab-item>
        <PerformanceReviewManagerReviewReport />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import PerformanceReviewTable from "@/components/Calibration/PerformanceReviewTable";
import PerformanceReviewTemplate from "@/components/Calibration/PerformanceReviewTemplate";
import PerformanceReviewManagerReviewTable from "@/components/Calibration/PerformanceReviewManagerReviewTable";
import PerformanceReviewManagerReviewReport from "@/components/Calibration/PerformanceReviewManagerReviewReport";
export default {
  name: "PerformanceReviewCreationView",
  components: {
    PerformanceReviewTable,
    PerformanceReviewTemplate,
    PerformanceReviewManagerReviewTable,
    PerformanceReviewManagerReviewReport,
  },
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "people",
      "user",
      "getBoard",
      "myEmail",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    board() {
      return this.getBoard(this.boardId);
    },
  },
  data: () => ({
    tab: "",
  }),
  watch: {},
  mounted() {},
  methods: {
    ...mapActions([]),
  },
};
</script>

<style scoped></style>
