import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import * as Sentry from '@sentry/browser';

import { createProjectId, createInitiativeId, createProjectGroupId } from '@/lib/idCreator';

const getDefaultState = () => {
  return {
    projectId: null,
    boardId: null,
    initiatives: [],
    projectGroups: [],
    projects: [],
    onDragPerson: '',
  };
};

const state = getDefaultState();

const mutations = {
  setOnDragContext(state, personId) {
    state.onDragPerson = personId;
  },

  addNewInitiative(state, newInitiative) {
    if (!('collapsed' in newInitiative)) {
      newInitiative.collapsed = true;
    }
    state.initiatives.push(newInitiative);
  },
  addNewProjectGroup(state, newProjectGroup) {
    if (!('collapsed' in newProjectGroup)) {
      newProjectGroup.collapsed = true;
    }
    state.projectGroups.push(newProjectGroup);
  },

  addNewProject(state, newProject) {
    state.projects.push(newProject);
  },

  deleteInitiative(state, initiativeId) {
    Vue._.remove(state.initiatives, initiative => {
      return initiative.initiativeId === initiativeId;
    });
  },
};

const actions = {
  async createNewInitiative(context, { boardId }) {
    const newId = createInitiativeId();
    const newInitiative = {
      boardId: boardId,
      initiativeId: newId,
      title: 'New Initiative',
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await firebase
      .firestore()
      .collection('initiatives')
      .doc(newId)
      .set(newInitiative)
      .catch(error => {
        console.error('Error adding new initiative: ', error);
        if (Sentry) Sentry.captureException(error);
      });

    context.commit('addNewInitiative', newInitiative);
    return newId;
  },

  async updateInitiative(context, initiative) {
    initiative.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    await firebase
      .firestore()
      .collection('initiatives')
      .doc(initiative.initiativeId)
      .update(initiative)
      .catch(error => {
        console.error('Error updating initiative: ', error);
        if (Sentry) Sentry.captureException(error);
      });
  },

  async fetchAllInitiatives(context, { boardId }) {
    // Reset the initiative array first
    context.state.initiatives = getDefaultState().initiatives;
    const querySnapshot = await firebase
      .firestore()
      .collection('initiatives')
      .where('boardId', '==', boardId)
      .get();

    querySnapshot.forEach(doc => {
      if (doc.exists) {
        context.commit('addNewInitiative', doc.data());
      } else {
        console.error('Error fetch initiative: No data exists.');
      }
    });
  },

  async deleteInitiative(context, { initiativeId }) {
    await firebase
      .firestore()
      .collection('initiatives')
      .doc(initiativeId)
      .delete();
    // .update({
    //   deleted: true,
    //   updatedBy: userId,
    //   updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    // });

    // context.commit('deleteInitiative', initiativeId);
  },

  async createNewProjectGroup(context, { boardId, initiativeId }) {
    const newProjectGroupId = createProjectGroupId();
    const newProjectGroup = {
      boardId: boardId,
      projectGroupId: newProjectGroupId,
      initiativeId: initiativeId,
      DRI: '',
      projects: [],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await firebase
      .firestore()
      .collection('project_groups')
      .doc(newProjectGroupId)
      .set(newProjectGroup)
      .catch(error => {
        console.error('Error adding new project group: ', error);
        if (Sentry) Sentry.captureException(error);
      });

    context.commit('addNewProjectGroup', newProjectGroup);
    return newProjectGroupId;
  },

  async fetchAllProjectGroups(context, { boardId }) {
    // Reset the projectGroups array first
    context.state.projectGroups = getDefaultState().projectGroups;
    const querySnapshot = await firebase
      .firestore()
      .collection('project_groups')
      .where('boardId', '==', boardId)
      .get();

    querySnapshot.forEach(doc => {
      if (doc.exists) {
        context.commit('addNewProjectGroup', doc.data());
      } else {
        console.error('Error fetch initiative: No data exists.');
      }
    });
  },

  async updateProjectGroup(context, projectGroup) {
    projectGroup.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    await firebase
      .firestore()
      .collection('project_groups')
      .doc(projectGroup.projectGroupId)
      .update(projectGroup)
      .catch(error => {
        console.error('Error updating project group: ', error);
        if (Sentry) Sentry.captureException(error);
      });
  },

  async createNewProject(context, { boardId, initiativeId, projectGroupId }) {
    const newProjectId = createProjectId();
    const newProject = {
      boardId: boardId,
      projectGroupId: projectGroupId,
      initiativeId: initiativeId,
      projectId: newProjectId,
      projectName: 'New Project',
      people: [],
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await firebase
      .firestore()
      .collection('projects')
      .doc(newProjectId)
      .set(newProject)
      .catch(error => {
        console.error('Error adding new project: ', error);
        if (Sentry) Sentry.captureException(error);
      });

    context.commit('addNewProject', newProject);
  },

  async fetchAllProjects(context, { boardId }) {
    // Reset the projects array first
    context.state.projects = getDefaultState().projects;
    const querySnapshot = await firebase
      .firestore()
      .collection('projects')
      .where('boardId', '==', boardId)
      .get();

    querySnapshot.forEach(doc => {
      if (doc.exists) {
        context.commit('addNewProject', doc.data());
      } else {
        console.error('Error fetch initiative: No data exists.');
      }
    });
  },

  async updateProject(context, project) {
    project.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
    await firebase
      .firestore()
      .collection('projects')
      .doc(project.projectId)
      .update(project)
      .catch(error => {
        console.error('Error updating project: ', error);
        if (Sentry) Sentry.captureException(error);
      });
  },
};

export default {
  state,
  mutations,
  getters: {},
  actions,
  modules: {},
};
