import Vue from "vue";
import firebase from "firebase/app";
import "firebase/firestore";
// import * as Sentry from '@sentry/browser';
// import 'firebase/functions';

const getDefaultState = () => {
  return {
    roles: [],
    plans: [],
  };
};

const state = getDefaultState();

const mutations = {
  addRoleToPlan(state, role) {
    state.roles.push(role);
  },
  updatePlan(state, { personId, key, value }) {
    let clone = Vue._.cloneDeep(state.roles);
    Vue._.each(clone, (obj) => {
      if (obj.personId === personId) {
        obj[key] = value;
      }
    });
    state.roles = clone;
  },
  removeRoleInPlan(state, jobCode) {
    const index = state.roles.map((role) => role.plan.jobCode).indexOf(jobCode);
    state.roles.splice(index, 1);
  },
  resetPlan(state) {
    Object.assign(state, getDefaultState());
  },
};

const actions = {
  addRoleToPlan(context, role) {
    context.commit("addRoleToPlan", role);
  },
  updatePlan(context, { personId, key, value }) {
    context.commit("updatePlan", { personId, key, value });
  },
  deleteRole(context, { jobCode }) {
    context.commit("removeRoleInPlan", jobCode);
  },
  async fetchJobNumber(context, boardId) {
    const increment = firebase.firestore.FieldValue.increment(1);
    const doc = await firebase
      .firestore()
      .collection("settings")
      .doc(boardId)
      .get();

    // In case the setting data does not exist
    doc.ref.set(
      { jobGroupNumber: increment, boardId: boardId },
      { merge: true }
    );
    // In case the setting data does not exist
    let jobGroupNumber = 0;
    if (doc.data()) jobGroupNumber = doc.data().jobGroupNumber;

    return jobGroupNumber;
  },
  async submitPlan(
    context,
    {
      planId,
      boardId,
      jobGroupNumber,
      createdBy,
      createdByPersonId,
      isUpdating,
    }
  ) {
    const newPlanDoc = {
      boardId,
      planId,
      updatedByPersonId: createdByPersonId,
      ownerPersonId: createdByPersonId,
      jobGroupNumber,
      roles: context.state.roles,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if (!isUpdating) {
      newPlanDoc.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      newPlanDoc.createdBy = createdBy;
      newPlanDoc.createdByPersonId = createdByPersonId;
    }

    await firebase
      .firestore()
      .collection("hiring_plans")
      .doc(`${boardId}_${jobGroupNumber}`)
      .set(newPlanDoc, { merge: true });
  },

  async updatePlanName(context, { jobGroup, updatedByPersonId, planName }) {
    return await firebase
      .firestore()
      .collection("hiring_plans")
      .doc(`${jobGroup.boardId}_${jobGroup.jobGroupNumber}`)
      .update({
        planName: planName,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedByPersonId: updatedByPersonId,
      });
  },

  async updatePlanInDB(context, { jobGroup, updatedByPersonId }) {
    return await firebase
      .firestore()
      .collection("hiring_plans")
      .doc(`${jobGroup.boardId}_${jobGroup.jobGroupNumber}`)
      .update({
        roles: jobGroup.roles,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedByPersonId: updatedByPersonId,
      });
  },

  async changePlanOwner(
    context,
    { jobGroup, updatedByPersonId, ownerPersonId }
  ) {
    return await firebase
      .firestore()
      .collection("hiring_plans")
      .doc(`${jobGroup.boardId}_${jobGroup.jobGroupNumber}`)
      .update({
        roles: context.state.roles,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedByPersonId: updatedByPersonId,
        ownerPersonId: ownerPersonId,
      });
  },

  async fetchPlans(context, { boardId }) {
    const querySnapshots = await firebase
      .firestore()
      .collection("hiring_plans")
      .where("boardId", "==", boardId)
      .get();

    querySnapshots.forEach((snapshot) => {
      context.state.plans.push(snapshot.data());
    });
  },

  async fetchPlan(context, { boardId, jobGroupNumber }) {
    const doc = await firebase
      .firestore()
      .collection("hiring_plans")
      .doc(`${boardId}_${jobGroupNumber}`)
      .get();

    context.state.plans.push(doc.data());
    Vue._.each(doc.data().roles, (role) => {
      context.commit("addRoleToPlan", role);
    });
  },

  async fetchPlanHistory(context, { boardId, jobGroupNumber }) {
    const querySnapshots = await firebase
      .firestore()
      .collection("hiring_plans_history")
      .where("boardId", "==", boardId)
      .where("jobGroupNumber", "==", jobGroupNumber)
      .get();

    const history = [];
    querySnapshots.forEach((snapshot) => {
      history.push(snapshot.data());
    });
    return history;
  },

  async fetchMyPlan(context, { boardId, personId }) {
    const querySnapshots = await firebase
      .firestore()
      .collection("hiring_plans")
      .where("boardId", "==", boardId)
      .where("ownerPersonId", "==", personId)
      .limit(1)
      .get();

    if (querySnapshots.docs.length > 0) {
      context.state.plans.push(querySnapshots.docs[0].data());
      return querySnapshots.docs[0].data();
    }
    return null;
  },
  resetPlan(context) {
    context.commit("resetPlan");
  },

  async sendHiringPlanEmailNotification(
    context,
    { sendTo, sender, replyTo, message, link }
  ) {
    var sendHiringPlan = firebase.functions().httpsCallable("sendHiringPlan");
    let _link = link;
    if (!link) {
      _link = `${window.location.origin}/#`;
    }

    await sendHiringPlan({ sendTo, sender, replyTo, message, link: _link });
  },
};

export default {
  state,
  mutations,
  actions,
  modules: {},
};
