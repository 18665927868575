<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        small
        outlined
        color="af_blue"
        :disabled="selectedEmployees.length === 0"
        v-bind="attrs"
        v-on="on"
      >
        Open to {{ selectedEmployees.length - sentList.length }} people
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        Open to
        {{ selectedEmployees.length - sentList.length }} people?
      </v-card-title>

      <v-card-text class="body-1">
        This form will open to the selected employee. You will be able to
        monitor the response rate.
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="dialog = false" :disabled="isSending">
          Cancel
        </v-btn>
        <v-btn color="primary" text :loading="isSending" @click="send()">
          Open
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "PerformanceReviewSendConfirmation",
  props: {
    selectedEmployees: {
      type: Array,
      required: true,
    },
    boardId: {
      type: String,
      required: true,
    },
    templateId: {
      type: String,
      required: true,
    },
    sentList: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    isSending: false,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions(["setPerformanceReviewReceivers"]),
    async send() {
      this.isSending = true;
      console.log("sending");
      try {
        console.log("setPerformanceReviewReceivers");

        console.log(this.getReceiverData());
        await this.setPerformanceReviewReceivers({
          templateId: this.templateId,
          boardId: this.boardId,
          user: this.user,
          receivers: this.getReceiverData(),
        });
        console.log("performanceReview-send");

        var sendPerformanceReviewEmail = firebase
          .functions()
          .httpsCallable("performanceReview-send");
        const ret = await sendPerformanceReviewEmail({
          boardId: this.boardId,
          templateId: this.templateId,
        });
        console.log(ret);
      } catch (e) {
        console.error(e);
      }

      this._.delay(() => {
        this.isSending = false;
        this.dialog = false;
        this.$emit("sent");
      }, 3000);
    },
    // Return the list of data in the following format
    // [{personId, name, email}]
    getReceiverData() {
      return this._.chain(this.selectedEmployees)
        .map((personId) => {
          const data = this.employeesByPersonId[personId];
          if (!data) {
            console.error("No data for personId", personId);
            return;
          }
          return {
            name: data.name,
            email: data.email,
            personId: data.personId,
          };
        })
        .compact()
        .value();
    },
  },
};
</script>
