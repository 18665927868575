import { nanoid } from "nanoid";

const createBoardId = () => {
  return "bd_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createCompanyId = () => {
  return "company_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createPersonId = () => {
  return "p_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createRequisitionRequestId = () => {
  return "hr_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createSquadId = () => {
  return "sq_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createPlanId = () => {
  return "pl_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createPerformanceReviewTemplateId = () => {
  return "prt_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

const createPerformanceReviewTemplateOkrId = () => {
  return "prto_" + nanoid().replace(/-/g, "DK").replace(/_/g, "AN");
};

export {
  createBoardId,
  createCompanyId,
  createPersonId,
  createRequisitionRequestId,
  createSquadId,
  createPlanId,
  createPerformanceReviewTemplateId,
  createPerformanceReviewTemplateOkrId,
};
