<template>
  <div v-if="invitations && invitations.length > 0">
    <v-card
      v-for="inv in invitations"
      v-bind:key="inv.id"
      class="px-12 py-2 my-12 card-shadow"
    >
      <v-card-text
        >{{ inv.invitedBy }} invited you to {{ inv.boardName }}</v-card-text
      >

      <v-card-actions class="d-flex justify-center">
        <v-btn
          v-if="!inv.invitationAccepted"
          color="af_blue"
          class="white--text"
          :disabled="isLoading"
          @click="acceptInvitation(inv.companyId, inv.id)"
          >{{ acceptBtnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Invitations",

  props: {},

  data: () => ({
    isLoading: false,
    acceptBtnText: "Accept",
  }),

  computed: {
    user() {
      return this.$store.state.user.userObj;
    },
    invitations() {
      return this.$store.state.user.invitations;
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.$store.dispatch("fetchPendingInvitations", { email: this.user.email });
  },

  methods: {
    async acceptInvitation(companyId, invitationObjectId) {
      this.isLoading = true;
      this.acceptBtnText = "Accepting...";
      await this.$store.dispatch("acceptInvitation", {
        companyId,
        invitationObjectId,
      });
      await this.$store.dispatch("fetchPendingInvitations", {
        email: this.user.email,
      });
      await this.$store.dispatch("fetchAllBoards", this.user.uid);

      this.invitations.invitationAccepted = true;
    },
  },
};
</script>

<style scoped></style>
