<template>
  <div class="pa-12">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="af_blue"
    ></v-progress-linear>

    <v-row>
      <v-col cols="12" md="12">
        <h3 class="mt-4">{{ templateName }}</h3>
      </v-col>
    </v-row>

    <v-row class="mt-8 mb-4">
      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">Employee Responses</p>
          <h1>{{ employeeResponseRate }}%</h1>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">Manager Ratings</p>
          <h1>{{ managerRatingRate }}%</h1>
        </v-card>
      </v-col>

      <v-col cols="12" md="4">
        <v-card class="card-shadow pa-4 text-center">
          <p class="body-1">VP Rating</p>
          <h1>{{ VPRatingRate }}%</h1>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="card-shadow pa-4">
          <h3 class="mb-6">Employee Response Rate By Manager</h3>

          <v-row
            no-gutters
            v-for="response in responsesByManager"
            :key="response.managerPersonId"
          >
            <v-col cols="6">
              <p class="body-1 pa-0 ma-0 my-1">
                <span v-if="employeesByPersonId[response.managerPersonId]"
                  >{{ employeesByPersonId[response.managerPersonId].name }} ({{
                    response.completedCount
                  }}/{{ response.totalCount }})</span
                >
              </p>
            </v-col>
            <v-col cols="6"
              ><v-progress-linear
                :value="response.completion * 100"
                :color="getColorForCompletion(response.completion)"
                height="20"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear></v-col
            >
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="card-shadow pa-4">
          <h3 class="mb-6">Employee Response Rate By Department</h3>

          <v-row
            no-gutters
            v-for="response in responsesByDepartment"
            :key="response.managerPersonId"
          >
            <v-col cols="6">
              <p class="body-1 pa-0 ma-0 my-1">
                {{ response.department }} ({{ response.completedCount }}/{{
                  response.totalCount
                }})
              </p>
            </v-col>
            <v-col cols="6"
              ><v-progress-linear
                :value="response.completion * 100"
                :color="getColorForCompletion(response.completion)"
                height="20"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear></v-col
            >
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { OutlineColors } from "@/lib/Colors";

export default {
  name: "PerformanceReviewManagerReviewTable",
  components: {},
  data: () => ({
    templateName: "",
    filterInput: "",
    searchQuery: "",
    departments: [], // array of uniq department values
    selectedEmployees: [], // array of personIds that will receive performance review
    showTeamList: [], // Array of manager's personId that indicates if the team table should be expanded or collapsed
    sent: [], // Array of personIds that indicates who received the performance review

    receivers: null, // The full recevier doc from Firestore
    answers: [],
    answersByPersonId: {},

    // For employees that should have a different evaluation manager compared to default.
    changedManagers: {},
    changedManagerFetched: false,
    csvExporting: false,
    isLoading: true,
  }),
  computed: {
    ...mapGetters([
      "uid",
      "myPersonId",
      "activeEmployees",
      "user",
      "people",
      "groupPeopleByManager",
      "employeesByPersonId",
    ]),
    boardId() {
      return this.$route.params.boardId;
    },
    templateId() {
      return this.$route.params.templateId;
    },
    orderedSentList() {
      return this._.chain(this.sent)
        .sortBy((personId) => {
          return this.employeesByPersonId[personId].employeeData.department;
        })
        .sortBy((personId) => {
          return this.employeesByPersonId[personId].managers[0];
        })
        .filter((personId) => {
          // This is hard coded for Weee
          // We should make this dynamic
          if (
            ![
              "prt_0qp0BdnHjjndqvbgDKRc13",
              "prt_CvQ4eiPwzXO6ktrNr47aS",
            ].includes(this.templateId)
          ) {
            return true;
          }

          const startDate =
            this.employeesByPersonId[personId].employeeData.startDate;
          if (startDate) {
            const mStartDate = this.$moment(startDate, "MM/DD/YYYY");
            const comparedDate = this.$moment("10/01/2021", "MM/DD/YYYY");
            if (!mStartDate.isBefore(comparedDate)) {
              return false;
            }
          }

          return true;
        })
        .value();
    },
    employeeResponseRate() {
      if (!this.sent.length) return 0;
      return parseInt((this.answers.length / this.sent.length) * 100);
    },
    managerRatingRate() {
      if (!this.sent.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].finalRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    VPRatingRate() {
      if (!this.sent.length) return 0;
      const rated = this._.filter(this.answersByPersonId, (answers) => {
        return answers[0].VPRating;
      });

      return parseInt((rated.length / this.sent.length) * 100);
    },
    managers() {
      // List of people that have a team
      return this._.chain(this.activeEmployees)
        .filter((person) => {
          return (
            person.role &&
            person.name &&
            person.email &&
            person.subordinates &&
            person.subordinates.length > 0
          );
        })
        .map((person) => {
          return { personId: person.personId, name: person.name };
        })
        .value();
    },
    // Sent record and answers objects merged.
    mergedData() {
      const answerDict = this._.keyBy(this.answers, "revieweePersonId");
      // List of the people the form has been sent to.
      return this._.map(this.orderedSentList, (pid) => {
        // Find the manager info
        let managerPersonId = this.employeesByPersonId[pid].managers[0];

        if (this.changedManagers[pid]) {
          // The evaluating manager has changed. Use the changed manager instead of the reporting manager
          managerPersonId = this.changedManagers[pid].personId;
        }

        let ret = {
          revieweePersonId: pid,
          managerPersonId: managerPersonId,
          ...answerDict[pid],
        };
        return ret;
      });
    },
    responsesByManager() {
      const byManager = this._.groupBy(this.mergedData, "managerPersonId");

      return this._.chain(byManager)
        .map((mergedData, managerPersonId) => {
          const completedCount = this._.filter(
            mergedData,
            "responsibilities"
          ).length;
          const totalCount = mergedData.length;
          const completion = completedCount / totalCount;
          return { managerPersonId, totalCount, completedCount, completion };
        })
        .sortBy("completion")
        .value();
    },
    responsesByDepartment() {
      // console.log(this.mergedData);
      const byDepartment = this._.groupBy(this.mergedData, (merged) => {
        const department =
          this.employeesByPersonId[merged.revieweePersonId].employeeData
            .department;

        if (department.split("-").length < 2) {
          // In case this employee's department format does not follow a particular format
          return department;
        }
        return department.split("-")[1].trim();
      });

      return this._.chain(byDepartment)
        .map((mergedData, department) => {
          const completedCount = this._.filter(
            mergedData,
            "responsibilities"
          ).length;
          const totalCount = mergedData.length;
          const completion = completedCount / totalCount;
          return { department, totalCount, completedCount, completion };
        })
        .sortBy("completion")
        .value();
    },
  },
  watch: {
    searchQuery: function () {},
    groupPeopleByManager: function () {
      this.departments = this._.chain(this.people)
        .map((p) => {
          return p.employeeData.department.split("-")[0].trim();
        })
        .uniq()
        .sortBy((t) => {
          // This ensures that the color code stays (kinda...)
          return t;
        })
        .value();
    },
  },

  async mounted() {
    this.csvExporting = true;
    await this.fetchPeople({ boardId: this.boardId });

    this.receviers = await this.fetchPerformanceReviewReceivers({
      boardId: this.boardId,
      templateId: this.templateId,
    });
    this.sent = this.receviers.sent;

    await this.fetchAnswers();

    // Fetch the name of the template
    const ret = await this.fetchPerformanceReviewTemplate({
      boardId: this.boardId,
      templateId: this.templateId,
    });

    this.templateName = ret.name;

    const changedManagersObj = await this.readChangedManagers({
      boardId: this.boardId,
      templateId: this.templateId,
    });

    if (changedManagersObj) {
      // Convert the Firebase data into changedManager Object
      this._.each(changedManagersObj.changedManagerArray, (obj) => {
        const personId = obj.targetPersonId;

        this.changedManagers[personId] = {
          personId: obj.managerPersonId,
          name: obj.managerName,
        };
        // console.log(this.$refs[`${personId}_evaluating_manager`]);
        // this.$refs[`${personId}_evaluating_manager`][0].value =
        //   this.changedManagers[personId];
      });
    }
    this.changedManagerFetched = true;
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "fetchPeople",
      "fetchPerformanceReviewReceivers",
      "fetchAllPerformanceReviewAnswers",
      "fetchPerformanceReviewTemplate",
      "saveChangedManagers",
      "readChangedManagers",
    ]),
    getDepartmentColor(department) {
      return OutlineColors[
        this.departments.indexOf(department.split("-")[0].trim())
      ];
    },
    filter() {
      this.searchQuery = this.filterInput;
    },

    queryManager(person, queryText) {
      const personId = person.personId;

      if (
        !this.employeesByPersonId[personId] ||
        !this.employeesByPersonId[personId].name
      )
        return false;
      return (
        this.employeesByPersonId[personId].name
          .toLowerCase()
          .includes(queryText) ||
        this.employeesByPersonId[personId].role
          .toLowerCase()
          .includes(queryText)
      );
    },
    getName(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.name : "";
    },

    getRole(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.role : "";
    },
    getDepartment(personId) {
      const person = this.employeesByPersonId[personId];
      return person ? person.employeeData.department : "No Department";
    },

    async fetchAnswers() {
      this.answers = await this.fetchAllPerformanceReviewAnswers({
        boardId: this.boardId,
        templateId: this.templateId,
      });

      this.answersByPersonId = this._.groupBy(this.answers, (ans) => {
        return ans.revieweePersonId;
      });
      // console.log(this.answersByPersonId);
    },
    getColorForCompletion(completion) {
      if (completion <= 0.5) {
        return "red";
      }
      if (completion === 1.0) {
        return "green";
      }
      return "blue";
    },
  },
};
</script>
<style scoped>
tr {
  height: 50px;
}
</style>
